<template>
  <section class="parallaxer-section">
    <slot name="layers"></slot>
  </section>
</template>

<script>
export default {
  data() {
    return {}
  },
}
</script>

<style lang="scss">
.parallaxer-section {
  width: 100vw;
  max-width: 100%;

  .layer {
    height: 100vh;
    width: 100vw;
    max-width: 100%;
    background-position: center;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: calc(100% + 30px);

    // Alle untereinander auf Mobilgeräten
    @media (max-width: 900px) {
      background-attachment: scroll;
      height: calc(100vw * 0.64);
      background-size: cover;
    }

    // iPads haben kein background attachment fixed
    @media (hover: none) {
      @supports (-webkit-touch-callout: none) {
        background-attachment: scroll;
        height: calc(100vw * 0.64);
        background-size: cover;
      }
    }

    // große Bildschirme quer
    @media (max-aspect-ratio: 16 / 9) and (min-width: 901px) {
      background-size: calc(177vh + 30px);
    }
  }
}
</style>
