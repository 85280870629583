<template>
  <div>
    <case-header :backgroundvid="headerBackgroundVideoUrl" :headerImgUrl="headerImgUrl">
      <h1 slot="h1-title" id="top-headline">Stadtspital Zürich</h1>
      <h3 slot="h3-title">Stadtspital Zürich</h3>
      <div slot="details">
        <p>
          <strong>?</strong>Die beiden Zürcher Stadtspitäler Waid und Triemli bilden seit der Zusammenführung Anfang 2019 ein grosses Zentrumsspital. Mit
          grossem Spektrum an Fachgebieten und modernen Arbeitsbedingungen inklusive eigener Ausbildungseinrichtung hat das Stadtspital Zürich jede Menge zu
          bieten. Und je mehr Menschen davon erfahren, desto mehr Menschen ist geholfen. Andererseits agieren auch Spitäler in Märkten und sind grosser
          Konkurrenz ausgesetzt. Deshalb gilt es, offen für neue Wege zu bleiben und die Zukunft nachhaltig zu gestalten. Bei der Gewinnung von zufriedenen
          Patientinnen und Patienten sowie als Arbeitgeber.
        </p>
      </div>
    </case-header>

    <div v-show="!caseIsLoading" id="case-content">
      <video-columns :uid="'video-columns-stadt-spital'" :videos="VideoColumnsVideos"></video-columns>

      <grund-gedanke>
        <h3 slot="title">Image-Kampagne</h3>
        <p slot="text">
          <strong>?</strong>Der Auftrag in diesem Pitch-Verfahren war es, eine Charme-Offensive zu erarbeiten. Das Stadtspital sollte als Zentrumsspital mit
          zwei Standorten präsentiert werden, das für alle Menschen – vor allem in Zürich – rund um die Uhr verfügbar ist. <br /><br />
          <strong>!</strong>Unser Anspruch war es, keine klassische 08/15-Kampagne umzusetzen, sondern in die Tiefe zu gehen und etwas zu bewegen. Entstanden
          ist die Kampagne «Mis Züri. Mis Spital.» Ehrlich und authentisch liessen wir die Patientinnen und Patienten der beiden Spitäler zu Wort kommen.
          Analog, Digital und in den Social Kanälen wurde mit den neun Patientinnen und Patienten gearbeitet. Wer mehr über ihre Geschichte erfahren wollte,
          bekam mit einem Klick die berührenden Filme zu sehen. <br /><br />
          <strong>=</strong>Die Kampagne ist durch ihre Eigenständigkeit aufgefallen. Die anspruchsvollen Zielsetzungen des Kunden wurden mehr als übertroffen,
          wie die Marktforschung bewies. Und wir haben gezeigt, dass auch Spital-Werbung nicht wie Werbung sonst, sondern anders, gut und so ehrlich sein kann.
        </p>
      </grund-gedanke>

      <parallax-glider
        :img1="require('./../assets/case-studies/stadtspital/parallax-glider/' + imgRes + '01.jpg')"
        :img2="require('./../assets/case-studies/stadtspital/parallax-glider/02.svg')"
      ></parallax-glider>

      <section class="slider-section slider-section-1">
        <carousel :perPage="1" :navigationEnabled="true" :loop="true">
          <slide>
            <img :src="require('./../assets/case-studies/stadtspital/slider/' + imgRes + '01.jpg')" />
          </slide>
          <slide>
            <img :src="require('./../assets/case-studies/stadtspital/slider/' + imgRes + '02.jpg')" />
          </slide>
          <slide>
            <img :src="require('./../assets/case-studies/stadtspital/slider/' + imgRes + '03.jpg')" />
          </slide>
          <slide>
            <img :src="require('./../assets/case-studies/stadtspital/slider/' + imgRes + '04.jpg')" />
          </slide>
          <slide>
            <img :src="require('./../assets/case-studies/stadtspital/slider/' + imgRes + '05.jpg')" />
          </slide>
          <slide>
            <video playsinline muted autoplay loop>
              <source :src="require('./../assets/case-studies/stadtspital/slider/06.mp4')" type="video/mp4" />
            </video>
          </slide>
          <slide>
            <video playsinline muted autoplay loop>
              <source :src="require('./../assets/case-studies/stadtspital/slider/07.mp4')" type="video/mp4" />
            </video>
          </slide>
        </carousel>
      </section>

      <grund-gedanke>
        <h3 slot="title">Employer Branding - Pflege</h3>
        <p slot="text">
          <strong>?</strong>Aufgrund des hohen Bedarfs an Fachkräften im Gesundheitssektor galt es, eine Employer-Branding-Kampagne zu gestalten, die sich
          nahtlos in den neuen Stadtspital-Duktus eingliedert und das Stadtspital auf authentische Weise als starker Arbeitgeber am Markt präsentiert.
          <br /><br />
          <strong>!</strong>Aus «Mis Züri. Mis Spital» wurde so «Mis Spital. Min Job.»<br />Um potenziellen Bewerberinnen und Bewerbern einen authentischen
          Einblick in den Spitalalltag zu gewähren, konnten wir Comedian Dr. Fabian Unteregger für uns gewinnen. Als ausgebildeter Mediziner ist er vom Fach und
          stattete den Mitarbeiterinnen und Mitarbeitern auf humorvolle Art und Weise einen Besuch ab, bei dem er sich herumführen liess. Dank ihm konnten wir
          Humor und Sympathie mit Transparenz und Authentizität verbinden und den Spitalalltag erlebbar machen. <br /><br />
          <strong>=</strong>Wo dringend Arbeitskräfte benötigt werden, hilft jede Art von Aufmerksamkeit. Wir sind stolz, unser Stadtspital tatkräftig
          unterstützt und zur Steigerung des Bewerberzahlen verholfen zu haben.
        </p>
      </grund-gedanke>

      <section class="slider-section slider-section-1">
        <carousel
          :paginationEnabled="false"
          navigationPrevLabel="<span class='arrow'><span>"
          navigationNextLabel="<span class='arrow'><span>"
          class="small-navigation"
          :perPage="1"
          :navigationEnabled="true"
          :loop="true"
        >
          <slide>
            <video-player
              :videourl="require('./../assets/case-studies/stadtspital/videos/fabian_unteregger/001.mp4')"
              :poster="require('./../assets/case-studies/stadtspital/videos/fabian_unteregger/001.jpg')"
            >
            </video-player>
          </slide>
          <slide>
            <video-player
              :videourl="require('./../assets/case-studies/stadtspital/videos/fabian_unteregger/002.mp4')"
              :poster="require('./../assets/case-studies/stadtspital/videos/fabian_unteregger/002.jpg')"
            >
            </video-player>
          </slide>
          <slide>
            <video-player
              :videourl="require('./../assets/case-studies/stadtspital/videos/fabian_unteregger/003.mp4')"
              :poster="require('./../assets/case-studies/stadtspital/videos/fabian_unteregger/003.jpg')"
            >
            </video-player>
          </slide>
        </carousel>
      </section>

      <section class="slider-section slider-section-1">
        <carousel
          :paginationEnabled="false"
          navigationPrevLabel="<span class='arrow'><span>"
          navigationNextLabel="<span class='arrow'><span>"
          class="small-navigation"
          :perPageCustom="[
            [0, 1],
            [800, 2],
            [1100, 3],
          ]"
          :navigationEnabled="true"
          :loop="true"
        >
          <slide>
            <img src="~assets/case-studies/stadtspital/plakate/001.jpg" />
          </slide>
          <slide>
            <img src="~assets/case-studies/stadtspital/plakate/002.jpg" />
          </slide>
          <slide>
            <img src="~assets/case-studies/stadtspital/plakate/003.jpg" />
          </slide>
          <slide>
            <img src="~assets/case-studies/stadtspital/plakate/004.jpg" />
          </slide>
        </carousel>
      </section>

      <section class="stadtspital-iphones black-icons">
        <video-on-scroll :videoSrc="require('./../assets/case-studies/stadtspital/videos/iphones.mp4')" />
      </section>

      <grund-gedanke>
        <h3 slot="title">Employer Branding – Ärzteschaft</h3>
        <p slot="text">
          <strong>?</strong>Fachkräftemangel gibt es nicht nur bei der Pflege. Auch Ärztinnen und Ärzte sind davon betroffen. Für unser Stadtspital galt es,
          eine Employer-Branding-Kampagne zu gestalten, die den Berufsalltag näherbringt und dieselbe Authentizität, wie die bisherigen Kampagnen darlegt.
          <br /><br />
          <strong>!</strong>Gesagt, getan. Aus «Mis Spital. Min Job» wurde «Mis Spital. Mini Ussichte.». Damit warben wir mehrdeutig um die Gunst von
          qualifizierten Ärztinnen und Ärzten. Denn die beiden Standorte Triemli und Waid bieten nicht nur dank ihrer erhöhten Lage tolle Aussichten; sie bieten
          den Mitarbeitenden auch beste Aussichten für ihr Berufsleben im Stadtspital Zürich.
          <br />
          Die Authentizität haben wir durch die begleitenden Massnahmen erreicht. So luden wir den Mediziner und Comedian Fabian Unteregger zum Gespräch mit
          Ärztinnen und Ärzten aus sechs unterschiedlichen Fachrichtungen ein, der sich das jeweilige Arbeitsumfeld zeigen liess. Durch die realitätsgetreuen
          Inszenierungen gaben wir interessierten Fachkräften einen authentischen Einblick in den Spitalalltag und präsentierten Wissenswertes über
          Arbeitsbedingungen und Arbeitsatmosphäre des Stadtspitals. Und zwar nicht von Schauspielerinnen und Schauspielern, sondern aus erster – und ehrlicher
          – Hand: von den Mitarbeitenden selbst. Die Zielgruppe erreichten wir anhand eines modernen, zeitgemässen Media-Ansatzes, der eine effiziente und
          wirksame Ansprache ermöglicht. Die Kampagne war mit Out-of-Home-Plakaten im Umfeld von Spitälern sowie einer Social-Media-Kampagne online zu sehen.
          <br /><br />
          <strong>=</strong>Die digitalen Werbemittel haben wir in bewährter Funnel-Manier ausgespielt und gesteuert. Damit erreichten wir die Zielgruppen mit
          den ihnen zugedachten Botschaften punktgenau. Wir freuen uns über die positiven Rückmeldungen und das gestiegene Interesse am Stadtspital Zürich sowie
          den zahlreich eingegangenen Bewerbungen.
        </p>
      </grund-gedanke>

      <video-columns :uid="'video-columns-stadt-spital-employer'" :videos="VideoColumnsVideosEmployer"></video-columns>

      <section class="slider-section slider-section-2">
        <carousel :perPage="1" :navigationEnabled="true" :loop="true">
          <slide v-for="slideIndex in 4" :key="slideIndex">
            <picture>
              <source media="(min-width: 800px)" :srcset="require(`./../assets/case-studies/stadtspital/slider-2/0${slideIndex}.jpg`)" />
              <source :srcset="require(`./../assets/case-studies/stadtspital/slider-2/low-0${slideIndex}.jpg`)" />
              <img :src="require(`./../assets/case-studies/stadtspital/slider-2/0${slideIndex}.jpg`)" />
            </picture>
          </slide>
        </carousel>
      </section>

      <section class="slider-section slider-section-2">
        <carousel :perPage="1" :navigationEnabled="true" :loop="true">
          <slide v-for="slideIndex in 4" :key="slideIndex">
            <picture>
              <source media="(min-width: 800px)" :srcset="require(`./../assets/case-studies/stadtspital/slider-3/0${slideIndex}.jpg`)" />
              <source :srcset="require(`./../assets/case-studies/stadtspital/slider-3/low-0${slideIndex}.jpg`)" />
              <img :src="require(`./../assets/case-studies/stadtspital/slider-3/0${slideIndex}.jpg`)" />
            </picture>
          </slide>
        </carousel>
      </section>

      <counter-footer :counters="footerCounters"></counter-footer>

      <grund-gedanke>
        <h3 slot="title">Employer <br />Branding – <br />Ausbildung</h3>
        <p slot="text">
          <strong>?</strong>Der Fachkräftemangel verschont auch den Gesundheitssektor nicht. Doch das Stadtspital Zürich ist kein untätiger Arbeitgeber, 
          sondern einer, der aktiv dagegen vorgeht. Nachdem das Stadtspital mit der Kampagne «Taten statt Worte» bereits bewiesen hatte, dass man auch 
          kurzfristig für erhöhte Bewerbungsquoten sorgen kann, verfolgte die neue Kampagne einen anderen Ansatz. Voller Fokus auf Ausbildungsplätze. 
          Denn nur, wenn der Nachwuchs gefördert wird, kann der Fachkräftemangel nachhaltig eingedämmt werden. Die Kampagne dazu sollte auffällig sein, 
          junge Menschen ansprechen und die Vorteile der Ausbildung im Stadtspital kommunizieren. <br /><br />
          <strong>!</strong>Also machten wir uns an die Arbeit und erkundeten das Ausbildungsangebot des Stadtspitals. Was uns dabei besonders auffiel, waren 
          die variablen Bausteine der Ausbildung. Diese ermöglichen es den Auszubildenden, ihre Ausbildung im Stadtspital stückweit mitzugestalten und nach 
          ihren eigenen Wünschen zu individualisieren. Unsere Botschaft zahlt auf diesen Fakt ein: «Mal dir deine Zukunft selber aus.» Für die Key Visuals 
          organisierten wir ein professionelles Shooting. Die Bilder von den Auszubildenden haben wir dann mit bunten Illustrationen versehen und die 
          Auszubildenden so in eine Zukunft eintauchen lassen, die sich selbst ausgemalt haben. Die Kampagne wurde vor allem digital und auf Social Media 
          ausgespielt. <br /><br />
          <strong>=</strong>Die Kampagne sorgte bereits kurz nach dem Start für viel Aufmerksamkeit und kam beim potenziellen Nachwuchs sehr gut an. Die Anzahl 
          an Bewerbungen konnte stetig gesteigert werden und der erste Schritt dieser langfristigen Massnahme gegen Fachkräftemangel war ein voller Erfolg!
        </p>
      </grund-gedanke>

      <section class="slider-section slider-section-1">
        <carousel
          :paginationEnabled="false"
          navigationPrevLabel="<span class='arrow'><span>"
          navigationNextLabel="<span class='arrow'><span>"
          class="small-navigation"
          :perPage="1"
          :navigationEnabled="true"
          :loop="true"
        >
          <slide v-for="slideIndex in 5" :key="slideIndex">
            <img :src="require(`./../assets/case-studies/stadtspital/plakate/Stadtspital_MiniUsbildig_Case_2500x1406_${slideIndex}.jpg`)" />
          </slide>
        </carousel>
      </section>  

      <section class="slider-section slider-section-1">
        <carousel
          :paginationEnabled="false"
          navigationPrevLabel="<span class='arrow'><span>"
          navigationNextLabel="<span class='arrow'><span>"
          class="small-navigation"
          :perPage="1"
          :navigationEnabled="true"
          :loop="true"
        >
          <slide>
            <video-player
              :videourl="require('./../assets/case-studies/stadtspital/videos/01_Stadtspital_Medienmitteilung_Screen_Web.mp4')"
              :poster="require('./../assets/case-studies/stadtspital/videos/01_Stadtspital_Medienmitteilung_Screen_Web.jpg')"
            >
            </video-player>
          </slide>
          <slide>
            <video-player
              :videourl="require('./../assets/case-studies/stadtspital/videos/01_Stadtspital_Medienmitteilung_Tiktok_Web.mp4')"
              :poster="require('./../assets/case-studies/stadtspital/videos/01_Stadtspital_Medienmitteilung_Tiktok_Web.jpg')"
            >
            </video-player>
          </slide>
        </carousel>
      </section>  

      <case-footer></case-footer>
    </div>
  </div>
</template>

<script>
import CaseHeader from '../components/Case-Header.vue'
import CaseFooter from '../components/Case-Footer.vue'
import Grundgedanke from '../components/Grundgedanke.vue'
import CounterFooter from '../components/Counter-Footer.vue'
import VideoPlayer from '../components/Video-Player.vue'
import CaseLoadStatusMixin from '../mixins/case-load-status'
import ParallaxGlider from '../components/Parallax-Glider-Two.vue'
import VideoOnScroll from '../components/VideoOnScroll.vue'
import VideoColumns from '../components/Video-Columns.vue'

export default {
  name: 'stadtspital',
  components: {
    'case-header': CaseHeader,
    'case-footer': CaseFooter,
    'grund-gedanke': Grundgedanke,
    'video-player': VideoPlayer,
    'counter-footer': CounterFooter,
    'parallax-glider': ParallaxGlider,
    'video-on-scroll': VideoOnScroll,
    'video-columns': VideoColumns,
  },
  mixins: [CaseLoadStatusMixin],
  data() {
    return {
      headerBackgroundVideoUrl: require('./../assets/case-studies/stadtspital/header.mp4'),
      headerImgUrl: require('./../assets/case-studies/stadtspital/main.jpg'),
      footerCounters: [
        {
          score: 0,
          max: 11,
          title: 'Die ungestützte Bekanntheit der Marke wurde von 1 auf 11 % gesteigert.',
        },
        {
          score: 0,
          max: 42,
          title: 'Die Bekanntheit, dass sich die beiden Spitäler zusammen geschlossen haben, steigerte sich von 26 auf 42 %.',
        },
        {
          score: 0,
          max: 74,
          title: 'Viewability der Online-Kampagne betrug 74 %.  Schweizweit liegt der Durchschnitt für gewöhnlich bei 58 %.',
        },
      ],
      VideoColumnsVideos: [
        {
          full: require('./../assets/case-studies/stadtspital/videos/video-columns/full-01.mp4'),
          fullPoster: require('./../assets/case-studies/stadtspital/videos/video-columns/full-poster-01.jpg'),
          prev: require('./../assets/case-studies/stadtspital/videos/video-columns/prev-01.mp4'),
          prevPoster: require('./../assets/case-studies/stadtspital/videos/video-columns/prev-poster-01.jpg'),
        },
        {
          full: require('./../assets/case-studies/stadtspital/videos/video-columns/full-02.mp4'),
          fullPoster: require('./../assets/case-studies/stadtspital/videos/video-columns/full-poster-02.jpg'),
          prev: require('./../assets/case-studies/stadtspital/videos/video-columns/prev-02.mp4'),
          prevPoster: require('./../assets/case-studies/stadtspital/videos/video-columns/prev-poster-02.jpg'),
        },
        {
          full: require('./../assets/case-studies/stadtspital/videos/video-columns/full-03.mp4'),
          fullPoster: require('./../assets/case-studies/stadtspital/videos/video-columns/full-poster-03.jpg'),
          prev: require('./../assets/case-studies/stadtspital/videos/video-columns/prev-03.mp4'),
          prevPoster: require('./../assets/case-studies/stadtspital/videos/video-columns/prev-poster-03.jpg'),
        },
        {
          full: require('./../assets/case-studies/stadtspital/videos/video-columns/full-04.mp4'),
          fullPoster: require('./../assets/case-studies/stadtspital/videos/video-columns/full-poster-04.jpg'),
          prev: require('./../assets/case-studies/stadtspital/videos/video-columns/prev-04.mp4'),
          prevPoster: require('./../assets/case-studies/stadtspital/videos/video-columns/prev-poster-04.jpg'),
        },
        {
          full: require('./../assets/case-studies/stadtspital/videos/video-columns/full-05.mp4'),
          fullPoster: require('./../assets/case-studies/stadtspital/videos/video-columns/full-poster-05.jpg'),
          prev: require('./../assets/case-studies/stadtspital/videos/video-columns/prev-05.mp4'),
          prevPoster: require('./../assets/case-studies/stadtspital/videos/video-columns/prev-poster-05.jpg'),
        },
        {
          full: require('./../assets/case-studies/stadtspital/videos/video-columns/full-06.mp4'),
          fullPoster: require('./../assets/case-studies/stadtspital/videos/video-columns/full-poster-06.jpg'),
          prev: require('./../assets/case-studies/stadtspital/videos/video-columns/prev-06.mp4'),
          prevPoster: require('./../assets/case-studies/stadtspital/videos/video-columns/prev-poster-06.jpg'),
        },
        {
          full: require('./../assets/case-studies/stadtspital/videos/video-columns/full-07.mp4'),
          fullPoster: require('./../assets/case-studies/stadtspital/videos/video-columns/full-poster-07.jpg'),
          prev: require('./../assets/case-studies/stadtspital/videos/video-columns/prev-07.mp4'),
          prevPoster: require('./../assets/case-studies/stadtspital/videos/video-columns/prev-poster-07.jpg'),
        },
        {
          full: require('./../assets/case-studies/stadtspital/videos/video-columns/full-08.mp4'),
          fullPoster: require('./../assets/case-studies/stadtspital/videos/video-columns/full-poster-08.jpg'),
          prev: require('./../assets/case-studies/stadtspital/videos/video-columns/prev-08.mp4'),
          prevPoster: require('./../assets/case-studies/stadtspital/videos/video-columns/prev-poster-08.jpg'),
        },
        {
          full: require('./../assets/case-studies/stadtspital/videos/video-columns/full-09.mp4'),
          fullPoster: require('./../assets/case-studies/stadtspital/videos/video-columns/full-poster-09.jpg'),
          prev: require('./../assets/case-studies/stadtspital/videos/video-columns/prev-09.mp4'),
          prevPoster: require('./../assets/case-studies/stadtspital/videos/video-columns/prev-poster-09.jpg'),
        },
      ],
      VideoColumnsVideosEmployer: [
        {
          full: require('./../assets/case-studies/stadtspital/videos/video-columns-2/full-01.mp4'),
          fullPoster: require('./../assets/case-studies/stadtspital/videos/video-columns-2/full-poster-01.jpg'),
          prev: require('./../assets/case-studies/stadtspital/videos/video-columns-2/prev-01.mp4'),
          prevPoster: require('./../assets/case-studies/stadtspital/videos/video-columns-2/prev-poster-01.jpg'),
        },
        {
          full: require('./../assets/case-studies/stadtspital/videos/video-columns-2/full-02.mp4'),
          fullPoster: require('./../assets/case-studies/stadtspital/videos/video-columns-2/full-poster-02.jpg'),
          prev: require('./../assets/case-studies/stadtspital/videos/video-columns-2/prev-02.mp4'),
          prevPoster: require('./../assets/case-studies/stadtspital/videos/video-columns-2/prev-poster-02.jpg'),
        },
        {
          full: require('./../assets/case-studies/stadtspital/videos/video-columns-2/full-03.mp4'),
          fullPoster: require('./../assets/case-studies/stadtspital/videos/video-columns-2/full-poster-03.jpg'),
          prev: require('./../assets/case-studies/stadtspital/videos/video-columns-2/prev-03.mp4'),
          prevPoster: require('./../assets/case-studies/stadtspital/videos/video-columns-2/prev-poster-03.jpg'),
        },
        {
          full: require('./../assets/case-studies/stadtspital/videos/video-columns-2/full-04.mp4'),
          fullPoster: require('./../assets/case-studies/stadtspital/videos/video-columns-2/full-poster-04.jpg'),
          prev: require('./../assets/case-studies/stadtspital/videos/video-columns-2/prev-04.mp4'),
          prevPoster: require('./../assets/case-studies/stadtspital/videos/video-columns-2/prev-poster-04.jpg'),
        },
        {
          full: require('./../assets/case-studies/stadtspital/videos/video-columns-2/full-05.mp4'),
          fullPoster: require('./../assets/case-studies/stadtspital/videos/video-columns-2/full-poster-05.jpg'),
          prev: require('./../assets/case-studies/stadtspital/videos/video-columns-2/prev-05.mp4'),
          prevPoster: require('./../assets/case-studies/stadtspital/videos/video-columns-2/prev-poster-05.jpg'),
        },
        {
          full: require('./../assets/case-studies/stadtspital/videos/video-columns-2/full-06.mp4'),
          fullPoster: require('./../assets/case-studies/stadtspital/videos/video-columns-2/full-poster-06.jpg'),
          prev: require('./../assets/case-studies/stadtspital/videos/video-columns-2/prev-06.mp4'),
          prevPoster: require('./../assets/case-studies/stadtspital/videos/video-columns-2/prev-poster-06.jpg'),
        },
      ],
    }
  },
  computed: {
    isU577() {
      return this.$store.getters.isU577
    },
    isU900() {
      return this.$store.getters.isU900
    },
    isTouch() {
      return this.$store.getters.isTouch
    },
    imgRes() {
      return window.innerWidth <= 577 ? 'low-' : ''
    },
  },
  mounted() {
    this.initilizeLoad(() => {
      this.$emit('initilizeLoaded')
    })
  },
  beforeDestroy() {
    this.$store.commit('removeScrollMagicScenes', 'stadtspital')
  },
}
</script>

<style lang="scss">
@import '~assets/scss/vars';

.stadtspital-iphones {
  background-color: #e4e3e5;
  padding-top: 90px;
  padding-bottom: 90px;

  .video-on-scroll {
    width: 100%;
    margin: 0 auto;
  }
}
</style>
