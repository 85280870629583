<template>
  <div>
    <case-header :backgroundvid="headerBackgroundVideoUrl" :headerImgUrl="headerImgUrl">
      <h1 slot="h1-title" id="top-headline">Schutz & Rettung</h1>
      <h3 slot="h3-title" class="schutzundrettung-h3">Schutz & Rettung</h3>
      <div slot="details">
        <p>
          <strong>?</strong>Schutz & Rettung Zürich – als Dienstabteilung des Sicherheitsdepartements der Stadt Zürich – vereinigt Feuerwehr, Rettungsdienst,
          Zivilschutz, Einsatzleitzentralen sowie die Feuerpolizei unter ihrem Dach. Mit rund 700 Mitarbeitenden ist sie die schweizweit grösste
          Rettungsorganisation.
          <br />
          Weil auch in der Sicherheitsbranche Personalmangel besteht, wurden wir beauftragt, eine Employer-Branding-Kampagne für die grösste
          Rettungsorganisation der Schweiz zu realisieren. Das besondere daran: Die Tatsache, dass Schutz & Rettung Zürich die grösste und wohl auch die
          stärkste Rettungsorganisation der Schweiz ist, duften wir – als Zürcher – in der Kommunikation nicht erwähnen.
        </p>
        <div class="tags">
          <p>Creative Campaigning | Filmplakat | Anzeigen | Display Ads | Social Ads | E-Panel</p>
        </div>
      </div>
    </case-header>

    <div v-show="!caseIsLoading" id="case-content">
      <grund-gedanke>
        <h3 slot="title">Schutz & Rettung</h3>
        <p slot="text">
          <strong>?</strong>Kein Tag ist bei Schutz & Rettung wie der andere und Langeweile sucht man dort vergebens. Bei diesen Jobs handelt es sich
          folgedessen um keine Jobs von der Stange. Und genau damit haben wir gearbeitet. Zentrales Element der Kampagne war die Rutschstange, die 1836 in einer
          Feuerwehrwache in Chicago erfunden wurde und auch heute noch in jeder Wache der Stadt Zürich im Einsatz ist.
          <br />
          Bei den Protagonisten an der Stange handelt es sich um Mitarbeitende der Schutz & Rettung aus allen Bereichen. Genauso wie zivile Personen, die
          weniger Erfahrung darin haben und entsprechend vorsichtiger rutschen. Wie tragfähig die Kampagne ist, beweist die individuelle Umsetzung für die
          Rekrutierung von «Quereinsteigern» oder «Karriere-Aufsteigern».
          <br /><br />
          <strong>=</strong>Der leichtherzige Humor und die bodenständige Umsetzung der Kampagne kam bei der Zielgruppe sehr gut an, was zu steigenden
          Bewerberzahlen führte. Und nebenbei auch gleich noch Gutes fürs allgemeine Branding intern und extern tat.
        </p>
      </grund-gedanke>

      <section class="slider-section slider-section-1">
        <carousel
          :paginationEnabled="false"
          navigationPrevLabel="<span class='arrow'><span>"
          navigationNextLabel="<span class='arrow'><span>"
          class="small-navigation"
          :perPage="1"
          :navigationEnabled="true"
          :loop="true"
        >
          <slide>
            <video-player
              :videourl="require('./../assets/case-studies/schutzundrettung/videos/stange/002.mp4')"
              :poster="require('./../assets/case-studies/schutzundrettung/videos/stange/002.jpg')"
            >
            </video-player>
          </slide>
          <slide>
            <video-player
              :videourl="require('./../assets/case-studies/schutzundrettung/videos/stange/001.mp4')"
              :poster="require('./../assets/case-studies/schutzundrettung/videos/stange/001.jpg')"
            >
            </video-player>
          </slide>
          <slide>
            <video-player
              :videourl="require('./../assets/case-studies/schutzundrettung/videos/stange/003.mp4')"
              :poster="require('./../assets/case-studies/schutzundrettung/videos/stange/003.jpg')"
            >
            </video-player>
          </slide>
        </carousel>
      </section>

      <img :src="require('./../assets/case-studies/schutzundrettung/plakat_2.jpg')" />

      <img :src="require('./../assets/case-studies/schutzundrettung/plakat_1.jpg')" />

      <video-player
        :videourl="require('./../assets/case-studies/schutzundrettung/videos/panel/001.mp4')"
        :poster="require('./../assets/case-studies/schutzundrettung/videos/panel/001.jpg')"
      >
      </video-player>

      <video-player
        :videourl="require('./../assets/case-studies/schutzundrettung/videos/tele-zueri.mp4')"
        :poster="require('./../assets/case-studies/schutzundrettung/videos/tele-zueri-poster.jpg')"
      >
      </video-player>

      <counter-footer :counters="footerCounters"></counter-footer>

      <case-footer></case-footer>
    </div>
  </div>
</template>

<script>
import CaseHeader from '../components/Case-Header.vue'
import CaseFooter from '../components/Case-Footer.vue'
import Grundgedanke from '../components/Grundgedanke.vue'
import CounterFooter from '../components/Counter-Footer.vue'
import VideoPlayer from '../components/Video-Player.vue'
import CaseLoadStatusMixin from '../mixins/case-load-status'

export default {
  name: 'schutzundrettung',
  components: {
    'case-header': CaseHeader,
    'case-footer': CaseFooter,
    'grund-gedanke': Grundgedanke,
    'video-player': VideoPlayer,
    'counter-footer': CounterFooter,
  },
  mixins: [CaseLoadStatusMixin],
  imagesToLoad: [require('./../assets/case-studies/schutzundrettung/teaser.jpg')],
  data() {
    return {
      headerImgUrl: require('./../assets/case-studies/schutzundrettung/main.jpg'),
      headerBackgroundVideoUrl: require('./../assets/case-studies/schutzundrettung/teaser.jpg'),
      isOnlyImageHeader: true,
      footerCounters: [
        {
          score: 0,
          max: 11,
          title: '11 Meter hoch ist die längste Rutschstange bei SRZ.',
        },
        {
          score: 0,
          max: 1,
          title: 'Die Kampagne ist einmalig und ist damit fix für das Employer Branding von SRZ reserviert.',
        },
        {
          score: 0,
          max: 800,
          title: '800% mehr Blindbewerbungen in den ersten vier Kampagnenwochen.',
        },
      ],
    }
  },
  methods: {
    domDecoder(str) {
      let parser = new DOMParser()
      let dom = parser.parseFromString('<!doctype html><body>' + str, 'text/html')
      return dom.body.textContent
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.initilizeLoad(() => {})
    })
  },
  beforeDestroy() {
    this.$store.commit('removeScrollMagicScenes', 'schutzundrettung')
  },
}
</script>

<style lang="scss">
.schutzundrettung-h3 {
  min-width: 130px;
}
</style>
