<template>
  <div id="rent-a-rentner">
    <case-header :backgroundvid="headerBackgroundVideoUrl" :headerImgUrl="headerImgUrl">
      <h1 slot="h1-title" id="top-headline">Rent a Rentner</h1>
      <h3 slot="h3-title">Rent a Rentner</h3>
      <div slot="details">
        <p>
          <strong>?</strong>Auch in der Schweiz nimmt der Anteil der Rentnerinnen und Rentner stetig zu. Bereits in wenigen Jahren wird deren Anteil in der
          Schweiz auf über 25 % steigen. Das wäre dann ein Viertel der gesamten Bevölkerung. Eine weitere Tatsache: Rentnerinnen und Rentner sind heute
          weitgehend gesünder, vitaler als noch vor wenigen Jahren und somit faktisch noch aktiver in ihrem Leben. Die wollen noch was erleben! Da bringt es die
          Werbung einer Schweizer Grossbank doch ganz gut auf den Punkt: 60 ist das neue 40. <br /><br />
          Die Anzahl Rentner nimmt also zu. Die Ansprüche und das Verhalten dieser Zielgruppe haben sich grundlegend verändert. Wir müssen den Rentnerinnen und
          Rentnern folglich Unterstützung bieten für ihr Leben im (Un-)Ruhestand. Denn mit nix tun und allein sein, wird man bekanntlich nicht alt.
        </p>
        <div class="tags">
          <p>
            Marketing Strategy | Communication Strategy | B2C Campaign | Digital & Social Campaigning | Website | Billboards | Video| Digital Ads | Below the
            Line | Guerilla Marketing | Social Media | Display | Corporate Design | Logo | Naming | Claiming | Visual Imagery
          </p>
        </div>
      </div>
    </case-header>

    <div v-show="!caseIsLoading" id="case-content">
      <parallax-glider :imgurl="require('./../assets/case-studies/rentarentner/' + imgRes + 'Grosses_Bild.jpg')"></parallax-glider>

      <grund-gedanke>
        <h3 slot="title">Rent a Rentner</h3>
        <p slot="text">
          <strong>!</strong>Wir lancierten im 2009 die weltweit erste Online-Arbeitsvermittlungsplattform für Rentnerinnen und Rentner. Auf dieser Plattform –
          <a href="https://www.rentarentner.ch" target="_blank">www.rentarentner.ch</a> - können sich Senioren registrieren und Arbeiten anbieten. Buchen können
          sie alle in der Schweiz, egal ob Grossunternehmen, KMUs, Familien, Vereine oder Alleinstehende. Und weil im Alter auch das Alleinsein ein Thema ist,
          haben wir im 2016 die Dating- und Freizeitplattform <a href="https://www.datearentner.ch" target="_blank">www.datearentner.ch</a> umgesetzt und
          lanciert.<br /><br />Stehen zu bleiben und auf den Lorbeeren auszuruhen, ist für ein Startup-Unternehmen fatal. Wir haben folglich ständig
          weitergearbeitet und die Plattformen weiter entwickelt. Auf die Umsetzung eines Pricings vor fünf Jahren folgte jetzt der neueste Meilenstein:
          zusammen mit einem vollständig überarbeiteten Web-Portal haben wir den weltweit ersten RentnerFinder lanciert. Eine App, mit der man per Smartphone
          ganz einfach und bequem einen Rentner in der Umgebung suchen, merken oder buchen kann. UBER war gestern, RentnerFinder ist heute. Erhältlich
          <a href="https://itunes.apple.com/gb/app/rentnerfinder/id1375011115?mt=8&ign-mpt=uo%3D2)" target="_blank">hier</a>. <br /><br />
          <strong>=</strong>
          Die Geschäftsidee hat eingeschlagen, voll eingeschlagen. Medien aus der ganzen Welt haben über unsere Idee berichtet. Eingeschlagen hat auch die Art
          der Kommunikation, diese ehrliche Tonalität und den Mut, anders aufzutreten. Wir behaupten, dass gerade auch diese Kommunikation dazu beigetragen hat,
          dass man über uns in dieser Form berichtet. In der Deutschschweiz kennt bereits jeder Dritte Rent a Rentner. Im 2011 (Sonderpreis) und 2014 (Kat.
          Mittel-/Kleinunternehmen) bekam Rent a Rentner die begehrten Marketing-Oscars, die jährlich von Swissmarketing für herausragende Marketingleistungen
          vergeben werden. Im Juni 2019 gewann Rent a Rentner in Japan (Tokio) den SilverEco-and-Ageing-Well-Award. Der Preis zeichnet weltweit bahnbrechende
          Leistungen im «Silver und Golden Ager» aus. In diesem Wettbewerb hat sich Rent a Rentner gegen 44 Organisationen weltweit durchgesetzt und kann sich
          fortan «Weltmeister» nennen.
        </p>
      </grund-gedanke>

      <section class="laptop-section laptop-section-a black-icons">
        <video v-if="!isMobile" id="laptopvideo" class="video" autoplay muted loop>
          <source src="~assets/case-studies/rentarentner/laptop_rentarentner.mp4" type="video/mp4" />
        </video>
        <img v-if="isMobile" class="laptop-mobile" src="~assets/case-studies/rentarentner/rentner-laptop.jpg" />
      </section>

      <section class="slider-section slider-section-1">
        <carousel :perPage="1" :navigationEnabled="true" :loop="true">
          <slide>
            <img :src="require('./../assets/case-studies/rentarentner/slider1/' + imgRes + '01.jpg')" />
          </slide>
          <slide>
            <img :src="require('./../assets/case-studies/rentarentner/slider1/' + imgRes + '02.jpg')" />
          </slide>
          <slide>
            <img :src="require('./../assets/case-studies/rentarentner/slider1/' + imgRes + '03.jpg')" />
          </slide>
          <slide>
            <img :src="require('./../assets/case-studies/rentarentner/slider1/' + imgRes + '04.jpg')" />
          </slide>
          <slide>
            <img :src="require('./../assets/case-studies/rentarentner/slider1/' + imgRes + '05.jpg')" />
          </slide>
          <slide v-if="!isMobile">
            <img :src="require('./../assets/case-studies/rentarentner/slider1/' + imgRes + '06.jpg')" />
          </slide>
          <slide v-if="!isMobile">
            <img :src="require('./../assets/case-studies/rentarentner/slider1/' + imgRes + '07.jpg')" />
          </slide>
          <slide v-if="!isMobile">
            <img :src="require('./../assets/case-studies/rentarentner/slider1/' + imgRes + '08.jpg')" />
          </slide>
          <slide v-if="!isMobile">
            <img :src="require('./../assets/case-studies/rentarentner/slider1/' + imgRes + '09.jpg')" />
          </slide>
          <slide v-if="!isMobile">
            <img :src="require('./../assets/case-studies/rentarentner/slider1/' + imgRes + '10.jpg')" />
          </slide>
        </carousel>
      </section>

      <section class="handy-animation-wrapper animations-section-1">
        <div id="handyblau"></div>
        <div class="handy-animation">
          <img src="~assets/case-studies/rentarentner/iphone.png" />
          <video id="video-handy" class="video" autoplay loop muted playsinline>
            <source src="~assets/case-studies/rentarentner/RentnerFinder.mp4" type="video/mp4" />
          </video>
        </div>
      </section>

      <parallax-glider :imgurl="require('./../assets/case-studies/rentarentner/' + imgRes + 'Grosses_Bild2.jpg')"></parallax-glider>

      <section class="slider-section slider-section-1">
        <carousel :perPage="1" :navigationEnabled="true" :loop="true">
          <slide>
            <img :src="require('./../assets/case-studies/rentarentner/slider2/' + imgRes + '01.jpg')" />
          </slide>
          <slide>
            <img :src="require('./../assets/case-studies/rentarentner/slider2/' + imgRes + '02.jpg')" />
          </slide>
          <slide>
            <img :src="require('./../assets/case-studies/rentarentner/slider2/' + imgRes + '03.jpg')" />
          </slide>
        </carousel>
      </section>

      <section class="animations-section-2" id="video-autoplay-section-1">
        <img v-if="isMobile" src="~assets/case-studies/rentarentner/plakate.jpg" />
        <video v-if="!isMobile" id="video1" class="video" muted>
          <source src="~assets/case-studies/rentarentner/plakate.mp4" type="video/mp4" />
        </video>
      </section>

      <!--
        <grund-gedanke>
          <h3 slot="title">Date a Rentner</h3>
          <p slot="text"><strong>!</strong>
          Zweitens stellte uns das Doppelleu-Sortiment vor eine neue Herausforderung: Ein Bier wie ein guter Wein. Wir mussten also treffend in Szene setzen, dass dieses Bier tatsächlich eine Alternative zu edlem Rebensaft sein kann. Weinwerbung für Bier, ein schönes Abendessen im Kerzenschein, ein Tête a Tête, warum nicht?</p>
        </grund-gedanke>
      -->

      <section class="slider-section slider-section-1">
        <carousel :perPage="1" :navigationEnabled="true" :loop="true">
          <slide>
            <img :src="require('./../assets/case-studies/rentarentner/slider3/' + imgRes + '01.jpg')" />
          </slide>
          <slide>
            <img :src="require('./../assets/case-studies/rentarentner/slider3/' + imgRes + '02.jpg')" />
          </slide>
          <slide>
            <img :src="require('./../assets/case-studies/rentarentner/slider3/' + imgRes + '03.jpg')" />
          </slide>
        </carousel>
      </section>

      <section class="drei-videos">
        <div id="drei-full-layer" :class="{ spaltenView: !isTouch && !isU900 }">
          <video
            class="video"
            id="video-full-1"
            plays-inline
            @click="handleFullVideoClick"
            :controls="isTouch"
            :poster="require('./../assets/case-studies/rentarentner/videos/poster-1.jpg')"
          >
            <source src="~assets/case-studies/rentarentner/videos/full/1.mp4" type="video/mp4" />
          </video>
          <video
            class="video"
            id="video-full-2"
            plays-inline
            @click="handleFullVideoClick"
            :controls="isTouch"
            :poster="require('./../assets/case-studies/rentarentner/videos/poster-2.jpg')"
          >
            <source src="~assets/case-studies/rentarentner/videos/full/2.mp4" type="video/mp4" />
          </video>
          <video
            class="video"
            id="video-full-3"
            plays-inline
            @click="handleFullVideoClick"
            :controls="isTouch"
            :poster="require('./../assets/case-studies/rentarentner/videos/poster-3.jpg')"
          >
            <source src="~assets/case-studies/rentarentner/videos/full/3.mp4" type="video/mp4" />
          </video>
        </div>
        <div v-if="!isTouch" id="drei-prev-layer">
          <video class="video" plays-inline data-videoId="1" loop muted @mouseenter="playPrevVideo" @mouseleave="pausePrevVideo" @click="handlePrevVideoClick">
            <source src="~assets/case-studies/rentarentner/videos/1.mp4" type="video/mp4" />
          </video>
          <video class="video" plays-inline data-videoId="2" loop muted @mouseenter="playPrevVideo" @mouseleave="pausePrevVideo" @click="handlePrevVideoClick">
            <source src="~assets/case-studies/rentarentner/videos/2.mp4" type="video/mp4" />
          </video>
          <video class="video" plays-inline data-videoId="3" loop muted @mouseenter="playPrevVideo" @mouseleave="pausePrevVideo" @click="handlePrevVideoClick">
            <source src="~assets/case-studies/rentarentner/videos/3.mp4" type="video/mp4" />
          </video>
        </div>
      </section>

      <section class="animations-section-3 black-icons" id="video-autoplay-section-2">
        <video v-if="!isMobile" id="video2" class="video" muted>
          <source src="~assets/case-studies/rentarentner/presse.mp4" type="video/mp4" />
        </video>
        <img v-if="isMobile" src="~assets/case-studies/rentarentner/presse.jpg" />
      </section>

      <section class="einzelbild black-icons">
        <img src="~assets/case-studies/rentarentner/trophy.jpg" />
      </section>

      <counter-footer :counters="footerCounters"></counter-footer>

      <case-footer></case-footer>
    </div>
  </div>
</template>

<script>
import CaseHeader from '../components/Case-Header.vue'
import CaseFooter from '../components/Case-Footer.vue'
import Grundgedanke from '../components/Grundgedanke.vue'
import CounterFooter from '../components/Counter-Footer.vue'
import CaseLoadStatusMixin from '../mixins/case-load-status'
import ParallaxGlider from '../components/Parallax-Glider.vue'

export default {
  name: 'rentarentner',
  components: {
    'case-header': CaseHeader,
    'case-footer': CaseFooter,
    'grund-gedanke': Grundgedanke,
    'parallax-glider': ParallaxGlider,
    'counter-footer': CounterFooter,
  },
  mixins: [CaseLoadStatusMixin],
  data() {
    return {
      headerBackgroundVideoUrl: require('./../assets/case-studies/rentarentner/header.mp4'),
      headerImgUrl: require('./../assets/case-studies/rentarentner/main.jpg'),
      footerCounters: [
        {
          score: 0,
          max: 28,
          title: '28 % Bekanntheitsgrad in der Deutschschweiz.',
        },
        {
          score: 0,
          max: 1,
          title: 'Marktleader. Und der Erste.',
        },
        {
          score: 0,
          max: 3,
          title: '2 Marketing-Oscars gewonnen. <br> 1 SilverEco and Ageing Well International Awards',
        },
      ],
      video1IsPlayed: false,
      video2IsPlayed: false,
    }
  },
  computed: {
    imgRes() {
      var isPortrait = window.innerWidth < window.innerHeight
      return (window.innerWidth <= 577 && isPortrait) || (window.innerWidth > 577 && window.innerWidth <= 900 && !isPortrait) ? 'low-' : ''
    },
    isU577() {
      return this.$store.getters.isU577
    },
    isU900() {
      return this.$store.getters.isU900
    },
    isTouch() {
      return this.$store.getters.isTouch
    },
    isMobile() {
      var isPortrait = window.innerWidth < window.innerHeight
      return (window.innerWidth <= 577 && isPortrait) || (window.innerWidth > 577 && window.innerWidth <= 900 && !isPortrait)
    },
  },
  methods: {
    playPrevVideo(e) {
      var video = e.target
      video.play()
    },
    pausePrevVideo(e) {
      var video = e.target
      video.pause()
    },
    handlePrevVideoClick(e) {
      var video = e.target
      this.videoIsPlaying = video.dataset.videoid
      var fullVideo = document.getElementById('video-full-' + video.dataset.videoid)
      TweenMax.to(video, 0.5, { width: '40vw', height: '112.5vw', y: '-25%' })
      TweenMax.to('#drei-prev-layer', 0.5, { pointerEvents: 'none', alpha: 0 })
      TweenMax.to(fullVideo, 0.5, { pointerEvents: 'auto', alpha: 1 })
      fullVideo.play()
    },
    handleFullVideoClick(e) {
      var fullVideo = e.target
      TweenMax.to(fullVideo, 0.5, { pointerEvents: 'none', alpha: 0 })
      TweenMax.to('#drei-prev-layer video:nth-child(' + this.videoIsPlaying + ')', 0.5, { width: '33.34vw', height: '56.25vw', y: '0%' })
      TweenMax.to('#drei-prev-layer', 0.5, { pointerEvents: 'auto', alpha: 1 })
      fullVideo.pause()
      fullVideo.currentTime = 0
    },

    // Animations
    initCoulourFadeAnimation() {
      var tl = new this.TimelineLite().fromTo('#handyblau', 1, { alpha: 0 }, { alpha: 1 }, 0)
      var scene = new this.ScrollMagic.Scene({
        triggerElement: '.animations-section-1',
        triggerHook: 0.3,
      }).setTween(tl)
      this.$store.commit('addScrollMagicScene', scene)
    },
    startMovies() {
      var vid = document.getElementById('video1')
      var scene = new this.ScrollMagic.Scene({
        triggerElement: '#video-autoplay-section-1',
        triggerHook: 0.6,
      }).on('enter', () => {
        if (!this.video1IsPlayed) {
          this.video1IsPlayed = true
          vid.play()
        }
      })
      this.$store.commit('addScrollMagicScene', scene)

      var vid2 = document.getElementById('video2')
      var scene2 = new this.ScrollMagic.Scene({
        triggerElement: '#video-autoplay-section-2',
        triggerHook: 0.2,
      }).on('enter', () => {
        if (!this.video2IsPlayed) {
          this.video2IsPlayed = true
          vid2.play()
        }
      })
      this.$store.commit('addScrollMagicScene', scene2)
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.initilizeLoad(() => {
        this.initCoulourFadeAnimation()
        if (!this.isMobile) {
          this.startMovies()
        }
      })
    })
  },
  beforeDestroy() {
    this.$store.commit('removeScrollMagicScenes', 'rentarentner')
  },
}
</script>

<style lang="scss" scoped>
.second-container {
  .content-container {
    > div {
      h3 {
        white-space: nowrap;

        @media (max-width: 900px) and (orientation: landscape) {
          white-space: normal;
          text-align: left;
        }
      }
    }
  }
}

@media (min-width: 900px) {
  #rent-a-rentner {
    .parallax-glider {
      height: 150vh !important;
      max-height: 120vw !important;
    }
  }
}

.drei-videos {
  background: #000;
  position: relative;

  #drei-prev-layer {
    height: 56.25vw;
    overflow: hidden;
    display: flex;
    justify-content: center;
    width: 140vw;
    transform: translateX(-20vw);

    video {
      width: 33.34vw;
      height: 56.25vw;
      cursor: url('~assets/theme_icons/play.png') 20 20, auto;
    }
  }

  #drei-full-layer {
    &.spaltenView {
      position: absolute;
      overflow: hidden;
      height: 56.25vw;
      width: 100%;

      video {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        cursor: url('~assets/theme_icons/close.png') 20 20, auto;
        pointer-events: none;
        opacity: 0;
      }
    }
  }
}

.handy-animation-wrapper {
  display: flex;
  justify-content: center;
  position: relative;
  background-color: #00dc96;

  .handy-animation {
    padding-right: 13px;

    // border-radius: 50px;
    overflow: hidden;
    margin-top: 10vh;
    margin-bottom: 10vh;
    position: relative;

    img {
      position: relative;
      z-index: 2;
      width: 280px;
      pointer-events: none;
    }

    video {
      width: 244px;
      position: absolute;
      top: 15px;
      left: 18px;
    }
  }

  #handyblau {
    background: #01cdf9;
    background: linear-gradient(-45deg, #01cdf9 0%, #00dc96 100%);
    background: linear-gradient(-45deg, #01cdf9 0%, #00dc96 100%);
    background: linear-gradient(135deg, #01cdf9 0%, #00dc96 100%);
    /* stylelint-disable-next-line function-no-unknown */
    filter: progid:dximagetransform.microsoft.gradient(startColorstr='#01cdf9', endColorstr='#00dc96', GradientType=1);
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
}

.einzelbild {
  img {
    max-height: 500px;
    padding: 100px 0;
    margin: 0 auto;
    display: block;

    @media (max-width: 576px) {
      padding: 30px;
      width: calc(100% - 60px);
    }
  }
}
</style>
