<template>
  <section id="top" class="header-section">
    <img class="header-img-mobile" :src="headerImgUrl" />
    <div class="overlay"></div>
    <div class="content">
      <div class="first-container header-container">
        <slot name="h1-title"></slot>
        <div class="details-button-container">
          <p class="details-button" :class="{ active: !isLoading }" @click="showDetails">
            {{ detailsButtonText }}
          </p>
          <div class="details-button-after"></div>
        </div>
      </div>
      <div class="second-container header-container">
        <div class="content-container">
          <slot name="h3-title"></slot>
          <slot name="details"></slot>
        </div>
      </div>
    </div>
    <div class="scroll-down-on-case-arrow" @click="scrollToCaseContent" @mouseenter="animateWorksArrowOut">
      <div class="arrow-extention"></div>
      <img class="details-arrow-down" src="~assets/theme_icons/arrow-down-white.svg" />
    </div>
  </section>
</template>

<script>
import CaseHeaderMixin from '../mixins/case-header'

export default {
  mixins: [CaseHeaderMixin],
  computed: {
    isU577() {
      return this.$store.getters.isU577
    },
  },
  props: {
    headerImgUrl: {
      type: String,
      default: '',
    },
    autoplay: {
      default: true,
      type: Boolean,
    },
  },
  methods: {
    changeAboutHeader() {
      var tl = new this.TimelineLite()
        .call(
          () => {
            if (!this.isU577) {
              this.showVideo = true
            }
            this.loadingTL.clear()
            this.detailsButtonText = 'Details'
            this.isLoading = false
          },
          this,
          [],
          0
        )
        .set('.details-button-after', { alpha: 1, width: 10 }, 0)
        .set('#top.header-section .overlay', { alpha: 0.5 }, 0)
        .set('.scroll-down-on-case-arrow', { alpha: 1, y: 0 }, 0)
        .call(
          () => {
            this.$store.dispatch('allowScroll')
          },
          this,
          [],
          2000
        )
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.changeAboutHeader()
    })
  },
}
</script>

<style lang="scss" scoped>
@import '~assets/scss/vars';
@import '~assets/scss/case-header';

.header-img-mobile {
  object-fit: cover;
}

.details-button {
  opacity: 1 !important;
}

.details-button-after {
  left: calc(50% - 17.5px) !important;
}

.scroll-down-on-case-arrow {
  pointer-events: auto !important;
}
</style>
