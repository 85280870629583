<template>
  <div class="VueCarousel-slide">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'slide',
  data() {
    return {
      width: null,
    }
  },
  mounted() {
    this.$parent.$on('pageChange', (index) => {
      // das Event vom Carousel bis zum child des Slides durchreichen, damit z.B Videos gestoppt werden können.
      this.$emit('carouselPageChange', index)
    })
  },
}
</script>

<style>
.VueCarousel-slide {
  flex-grow: 0;
  flex-shrink: 0;
  /* stylelint-disable-next-line declaration-block-no-redundant-longhand-properties */
  flex-basis: inherit;
  user-select: none;
}
</style>
