<template>
  <section id="top" class="header-section header-section-teeexpress">
    <section class="animation-section-e" ref="blutplakat"></section>

    <div class="overlay"></div>
    <div class="content">
      <div class="first-container header-container">
        <slot name="h1-title"></slot>
        <div class="details-button-container">
          <p class="details-button" :class="{ active: !isLoading }" @click="showDetailsAndAnimate">
            {{ detailsButtonText }}
          </p>
          <div class="details-button-after"></div>
        </div>
      </div>
      <div class="second-container header-container">
        <div class="content-container">
          <div>
            <slot name="h3-title"></slot>
          </div>
          <slot name="details"></slot>
        </div>
      </div>
    </div>
    <div class="scroll-down-on-case-arrow" @click="scrollToCaseContent" @mouseenter="animateWorksArrowOut">
      <div class="arrow-extention"></div>
      <img class="details-arrow-down" src="~assets/theme_icons/arrow-down-white.svg" />
    </div>
  </section>
</template>

<script>
import CaseHeaderMixin from '../mixins/case-header'
import AnimationJson from './../assets/aninmations/animation--blood-viiv.json'
export default {
  mixins: [CaseHeaderMixin],
  data() {
    return {
      headerEventListener: false,
      blutAnimationIsPlayed: false,
    }
  },
  computed: {
    isU577() {
      return this.$store.getters.isU577
    },
    isU900() {
      return this.$store.getters.isU900
    },
    isTouch() {
      return this.$store.getters.isTouch
    },
  },
  methods: {
    startBlutPlakatMovie() {
      this.animationBlutplakat = this.bodymovin.loadAnimation({
        container: this.$refs['blutplakat'],
        renderer: 'canvas',
        loop: false,
        autoplay: false,
        animationData: AnimationJson,
      })
    },

    showDetailsAndAnimate() {
      this.showDetails()
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.startBlutPlakatMovie()
      this.animationBlutplakat.setSpeed(2)
      this.$parent.$on('hideLoadingInHeader', () => {
        setTimeout(() => {
          this.animationBlutplakat.play()
        }, 1000)
      })
    })
  },
  beforeDestroy() {
    //this.scene.destroy();
  },
}
</script>

<style lang="scss">
@import '~assets/scss/vars';
@import '~assets/scss/case-header';

.animation-section-e {
  height: 56vw;
  background-image: url('~assets/case-studies/viivhealthcare/plakat-bg.png');
  background-size: cover;
  background-position: center;

  @media (max-aspect-ratio: 16 / 9) {
    height: 100%;
    width: 178vh;
    right: 0;
    position: absolute;
  }
}
</style>
