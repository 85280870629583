<template>
  <section id="top" class="header-section header-sidrosan">
    <div class="animation-tee-container" :class="{ isTouch: isTouch }"></div>
    <div class="overlay"></div>
    <div class="content">
      <div class="first-container header-container">
        <slot name="h1-title"></slot>
        <div class="details-button-container">
          <p class="details-button" :class="{ active: !isLoading }" @click="showDetails">
            {{ detailsButtonText }}
          </p>
          <div class="details-button-after"></div>
        </div>
      </div>
      <div class="second-container header-container">
        <div class="content-container">
          <div>
            <slot name="h3-title"></slot>
          </div>
          <slot name="details"></slot>
        </div>
      </div>
    </div>
    <div class="scroll-down-on-case-arrow" @click="scrollToCaseContent" @mouseenter="animateWorksArrowOut">
      <div class="arrow-extention"></div>
      <img class="details-arrow-down" src="~assets/theme_icons/arrow-down-white.svg" />
    </div>
  </section>
</template>

<script>
import CaseHeaderMixin from '../mixins/case-header'

export default {
  mixins: [CaseHeaderMixin],
  data() {
    return {
      totalSteps: 38,
      scene: false,
    }
  },
  computed: {
    isU577() {
      return this.$store.getters.isU577
    },
    isU900() {
      return this.$store.getters.isU900
    },
    isTouch() {
      return this.$store.getters.isTouch
    },
  },
  methods: {
    initHeaderAnimation() {
      var tl = new this.TimelineLite().to('.animation-tee-container', this.totalSteps / 25, {
        backgroundPosition: '100%',
        ease: SteppedEase.config(this.totalSteps),
      })

      this.scene = new this.ScrollMagic.Scene({
        triggerElement: '#top',
        triggerHook: 0,
        duration: 1000,
      })
        .setPin('#top')
        .on('start', () => {
          this.$store.dispatch('allowScroll')
          window.scrollTo(0, 0)
        })
        .setTween(tl)

      this.$store.commit('addScrollMagicScene', this.scene)
    },
  },
  mounted() {
    this.$parent.$on('initilizeLoadSidrosan', () => {
      this.initHeaderAnimation()
    })
  },
  beforeDestroy() {
    this.scene.destroy()
  },
}
</script>

<style lang="scss">
@import '~assets/scss/vars';
@import '~assets/scss/case-header';

.header-sidrosan {
  position: relative;
  width: 100vw !important;
  margin: 0 !important;
}

.animation-tee-container {
  background-image: url('~assets/case-studies/sidrosan/animation-tee.jpg');
  background-size: cover;
  background-position: 0 0;
  width: 100vw;
  height: 56.25vw;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media (max-aspect-ratio: 16 / 9) {
    max-width: none;
    max-height: 100%;
    height: 100%;
    width: calc((100vh / 9) * 16);
  }

  @media (max-width: 900px) {
    background-image: url('~assets/case-studies/sidrosan/main.jpg');
    height: 100%;
    width: 100%;
  }

  &.isTouch {
    background-image: url('~assets/case-studies/sidrosan/main.jpg');
    height: 100%;
    width: 100%;
  }
}
</style>
