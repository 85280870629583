<template>
  <section ref="counterFooterElement" class="counter-footer black-icons">
    <div class="content-container">
      <div v-for="counter in counters">
        <p class="counter">{{ counter.score }}</p>
        <p class="name" v-html="counter.title"></p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    counters: {
      type: Array,
      default: function () {
        return [
          {
            score: 0,
            max: 50,
            title: 'Titel 1',
          },
        ]
      },
    },
  },
  methods: {
    counterAnimationScene() {
      for (let counter of this.counters) {
        var tl = new this.TimelineLite()
        tl.to(counter, 1, { score: counter.max, roundProps: 'score' })
        var scene = new this.ScrollMagic.Scene({
          triggerElement: this.$refs.counterFooterElement,
          triggerHook: 0.7,
          reverse: false,
        }).setTween(tl)
        this.$store.commit('addScrollMagicScene', scene)
      }
    },
    getVorzeichen(number) {
      return number > 0 ? '+' : ''
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$parent.$on('caseIsLoaded', () => {
        this.counterAnimationScene()
      })
    })
  },
}
</script>

<style lang="scss">
@import '~assets/scss/vars';

.counter-footer {
  background: $green;
  padding: 60px 0;

  .content-container {
    display: flex;
    flex-direction: row;

    .name {
      white-space: pre-line;
    }

    @media (max-width: 577px) {
      flex-direction: column;
    }

    > div {
      width: 100%;
      text-align: center;
      padding: 0 30px;

      .counter {
        font-size: 60px;
        font-weight: bold;
        margin: 0 0 30px;

        @media (max-width: 577px) {
          font-size: 40px;
          margin-bottom: 0;
        }
      }

      @media (max-width: 577px) {
        width: calc(100% - 160px);
        padding: 30px 80px;
      }

      @media (max-width: 900px) and (min-width: 578px) {
        .counter {
          font-size: 40px;
        }
      }
    }
  }
}
</style>
