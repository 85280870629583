<template>
  <div>
    <case-header :backgroundvid="headerBackgroundVideoUrl" :headerImgUrl="headerImgUrl">
      <h1 slot="h1-title" id="top-headline">Sobu</h1>
      <h3 slot="h3-title">Sobu</h3>
      <div slot="details">
        <p>
          <strong>?</strong>Kann man mit einem unverschämt bescheidenen Budget eine geile Geschäftsidee bekannt machen? sobu ist eine Online-Plattform, die
          damals noch kein Schwein kannte. Dabei ist das Konzept genial einfach: Einkaufen. Empfehlen. Geld verdienen. Will doch jeder! Eigentlich ja, aber wenn
          es niemand weiss?
        </p>
        <div class="tags">
          <p>B2C Campaign | Digital & Social Campagining | TV Commercial | Billboards | Radio | Microsite | Social Media</p>
        </div>
      </div>
    </case-header>

    <div v-show="!caseIsLoading" id="case-content">
      <video-player
        :videourl="require('./../assets/case-studies/sobu/videos/case/case.mp4')"
        :poster="require('./../assets/case-studies/sobu/videos/case/poster-case.jpg')"
      >
      </video-player>

      <grund-gedanke>
        <h3 slot="title">Sobu</h3>
        <p slot="text">
          <strong>!</strong>Empfehlungen von Freunden sind heute neben Bewertungen das Ding, an dem sich Andere orientieren. Das nutzen wir für unsere Kampagne
          schamlos aus. Eine Jury aus Promis suchten unter vielen Bewerbern 5 real existierende Botschafter aus, die über sobu cooles Zeug eingekauft haben. Sie
          mussten dann in der Folge in einem Battle gegeneinander antreten. Gewonnen hat der Botschafter oder die Botschafterin, die durch Empfehlungen am
          meisten Kohle gescheffelt hat. Die Kampagne verschmolz Online mit Offline wie keine andere zuvor. <br /><br />
          <strong>=</strong>Die sportliche Zielsetzung des Kunden haben wir in wenigen Monaten reichlich übertroffen. 56% der Zielgruppe erinnert sich an die
          Kampagne und 36% kennen die Botschaft. Der Bekanntheitsgrad ist von 0,3 Prozent auf 20 Prozent angewachsen in nur einem Jahr. Dies trotz eines doch
          sehr bescheidenen Kommunikationsbudgets. Sobu ist heute ein eigenständiges Unternehmen, losgelöst von der Schweizerischen Post. Da haben sie, wie auch
          wir so Einiges richtig gemacht.
        </p>
      </grund-gedanke>

      <section class="fuenf-videos">
        <div id="funf-full-layer" :class="{ spaltenView: !isTouch && !isU900 }">
          <video
            class="video"
            id="video-full-1"
            plays-inline
            @click="handleFullVideoClick"
            :controls="isTouch"
            :poster="require('./../assets/case-studies/sobu/poster-1.jpg')"
          >
            <source src="~assets/case-studies/sobu/videos/full/1.mp4" type="video/mp4" />
          </video>
          <video
            class="video"
            id="video-full-2"
            plays-inline
            @click="handleFullVideoClick"
            :controls="isTouch"
            :poster="require('./../assets/case-studies/sobu/poster-2.jpg')"
          >
            <source src="~assets/case-studies/sobu/videos/full/2.mp4" type="video/mp4" />
          </video>
          <video
            class="video"
            id="video-full-3"
            plays-inline
            @click="handleFullVideoClick"
            :controls="isTouch"
            :poster="require('./../assets/case-studies/sobu/poster-3.jpg')"
          >
            <source src="~assets/case-studies/sobu/videos/full/3.mp4" type="video/mp4" />
          </video>
          <video
            class="video"
            id="video-full-4"
            plays-inline
            @click="handleFullVideoClick"
            :controls="isTouch"
            :poster="require('./../assets/case-studies/sobu/poster-4.jpg')"
          >
            <source src="~assets/case-studies/sobu/videos/full/4.mp4" type="video/mp4" />
          </video>
          <video
            class="video"
            id="video-full-5"
            plays-inline
            @click="handleFullVideoClick"
            :controls="isTouch"
            :poster="require('./../assets/case-studies/sobu/poster-5.jpg')"
          >
            <source src="~assets/case-studies/sobu/videos/full/5.mp4" type="video/mp4" />
          </video>
        </div>

        <div v-if="!isTouch" id="funf-prev-layer">
          <video class="video" plays-inline data-videoId="1" loop muted @mouseenter="playPrevVideo" @mouseleave="pausePrevVideo" @click="handlePrevVideoClick">
            <source src="~assets/case-studies/sobu/videos/1.mp4" type="video/mp4" />
          </video>
          <video class="video" plays-inline data-videoId="2" loop muted @mouseenter="playPrevVideo" @mouseleave="pausePrevVideo" @click="handlePrevVideoClick">
            <source src="~assets/case-studies/sobu/videos/2.mp4" type="video/mp4" />
          </video>
          <video class="video" plays-inline data-videoId="3" loop muted @mouseenter="playPrevVideo" @mouseleave="pausePrevVideo" @click="handlePrevVideoClick">
            <source src="~assets/case-studies/sobu/videos/3.mp4" type="video/mp4" />
          </video>
          <video class="video" plays-inline data-videoId="4" loop muted @mouseenter="playPrevVideo" @mouseleave="pausePrevVideo" @click="handlePrevVideoClick">
            <source src="~assets/case-studies/sobu/videos/4.mp4" type="video/mp4" />
          </video>
          <video class="video" plays-inline data-videoId="5" loop muted @mouseenter="playPrevVideo" @mouseleave="pausePrevVideo" @click="handlePrevVideoClick">
            <source src="~assets/case-studies/sobu/videos/5.mp4" type="video/mp4" />
          </video>
        </div>
      </section>

      <counter-footer :counters="footerCounters"></counter-footer>
      <case-footer></case-footer>
    </div>
  </div>
</template>

<script>
import CaseHeader from '../components/Case-Header.vue'
import CaseFooter from '../components/Case-Footer.vue'
import Grundgedanke from '../components/Grundgedanke.vue'
import CounterFooter from '../components/Counter-Footer.vue'
import VideoPlayer from '../components/Video-Player.vue'
import CaseLoadStatusMixin from '../mixins/case-load-status'

export default {
  name: 'sobu',
  components: {
    'case-header': CaseHeader,
    'case-footer': CaseFooter,
    'grund-gedanke': Grundgedanke,
    'video-player': VideoPlayer,
    'counter-footer': CounterFooter,
  },
  mixins: [CaseLoadStatusMixin],
  data() {
    return {
      headerBackgroundVideoUrl: require('./../assets/case-studies/sobu/header.mp4'),
      headerImgUrl: require('./../assets/case-studies/sobu/main.jpg'),
      footerCounters: [
        {
          score: 0,
          max: 20,
          title: 'In 1 Jahr Bekanntheitsgrad von 0,3 auf 20 % gesteigert.',
        },
        {
          score: 0,
          max: 36,
          title: '36 % der Zielgruppe erinnerte sich an die Hauptbotschaft der Kampagne.',
        },
        {
          score: 0,
          max: 2445.75,
          title: 'Fr. 2445.75 gewann die Siegerin des Battles mit Shoppen und Weiterempfehlen. ',
        },
      ],
    }
  },
  computed: {
    isU577() {
      return this.$store.getters.isU577
    },
    isU900() {
      return this.$store.getters.isU900
    },
    isTouch() {
      return this.$store.getters.isTouch
    },
    imgRes() {
      return window.innerWidth <= 577 ? 'low-' : ''
    },
  },
  methods: {
    playPrevVideo(e) {
      var video = e.target
      video.play()
    },
    pausePrevVideo(e) {
      var video = e.target
      video.pause()
    },
    handlePrevVideoClick(e) {
      var video = e.target
      this.videoIsPlaying = video.dataset.videoid
      var fullVideo = document.getElementById('video-full-' + video.dataset.videoid)
      TweenMax.to(video, 0.5, { width: '40vw', height: '112.5vw', y: '-25%' })
      TweenMax.to('#funf-prev-layer', 0.5, { pointerEvents: 'none', alpha: 0 })
      TweenMax.to(fullVideo, 0.5, { pointerEvents: 'auto', alpha: 1 })
      fullVideo.play()
    },
    handleFullVideoClick(e) {
      var fullVideo = e.target
      TweenMax.to(fullVideo, 0.5, { pointerEvents: 'none', alpha: 0 })
      TweenMax.to('#funf-prev-layer video:nth-child(' + this.videoIsPlaying + ')', 0.5, { width: '20vw', height: '56.25vw', y: '0%' })
      TweenMax.to('#funf-prev-layer', 0.5, { pointerEvents: 'auto', alpha: 1 })
      fullVideo.pause()
      fullVideo.currentTime = 0
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.initilizeLoad(() => {})
    })
  },
  beforeDestroy() {
    this.$store.commit('removeScrollMagicScenes', 'sobu')
  },
}
</script>

<style lang="scss" scoped>
.fuenf-videos {
  background: #000;
  position: relative;

  #funf-prev-layer {
    height: 56.25vw;
    overflow: hidden;
    display: flex;
    justify-content: center;
    width: 140vw;
    transform: translateX(-20vw);

    video {
      width: 20vw;
      height: 56.25vw;
      cursor: url('~assets/theme_icons/play.png') 20 20, auto;
    }
  }

  #funf-full-layer {
    &.spaltenView {
      position: absolute;
      overflow: hidden;
      height: 56.25vw;
      width: 100%;

      video {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        cursor: url('~assets/theme_icons/close.png') 20 20, auto;
        pointer-events: none;
        opacity: 0;
      }
    }

    &:not(.spaltenView) {
      video {
        object-fit: cover;
      }
    }
  }
}
</style>
