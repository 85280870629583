import { EventBus } from './../store/eventbus'

export default {
  data() {
    return {
      counterFull: false,
      caseIsLoading: true,
      loadingPartsCounter: 0,
      loadingPartsMax: 2,
      minLoadTime: 2000,
      maxLoadTime: 8000,
    }
  },
  computed: {
    isU577() {
      return this.$store.getters.isU577
    },
    isTouch() {
      return this.$store.getters.isTouch
    },
  },
  methods: {
    increaseLoadingPartCounter(callback) {
      this.loadingPartsCounter++
      if (this.loadingPartsCounter >= this.loadingPartsMax && !this.counterFull) {
        this.counterFull = true
        this.startLoadingTimeline(callback)
      }
    },

    startLoadingTimeline(callback) {
      var tl = new TimelineLite()
        .call(() => {
          this.$emit('hideLoadingInHeader')
          if (this.$store.state.allowScrollManualySites.indexOf(this.$route.path) == -1) {
            this.$store.dispatch('allowScroll')
          }
          this.$store.dispatch('handleScrollToDetectIconColor')
        })
        .call(
          () => {
            this.caseIsLoading = false
            TweenMax.to('.details-arrow-down', 0.5, { 'pointer-events': 'auto', alpha: 1, y: 0 })
          },
          this,
          [],
          2
        )
        .call(
          () => {
            if (this.$route.path.indexOf(this.$options.name) != -1) {
              this.$emit('caseIsLoaded')
              EventBus.$emit('case-page-laoded')
              callback()
            }
          },
          this,
          [],
          3
        )
    },

    initilizeLoad(callback) {
      this.$store.dispatch('forbidScroll')
      var videos_to_load = []

      // add vidos to load
      if (!this.isU577) {
        this.videosToLoad = this.$el.querySelectorAll('video')
        for (let video of this.videosToLoad) {
          if (video.getAttribute('preload') !== 'none') {
            this.loadingPartsMax++
            videos_to_load.push({ type: 'video', data: video })
          }
        }
      }

      // add images to load
      if (this.imagesToLoad && this.imagesToLoad.length) {
        for (let img of this.imagesToLoad) {
          this.loadingPartsMax++
          this.preloadImg(img, callback)
        }
      }

      // loop throu files
      for (let file of videos_to_load) {
        if (file.type == 'video' && file.data.getAttribute('preload') !== 'none') {
          file.data.addEventListener('loadedmetadata', () => {
            this.increaseLoadingPartCounter(callback)
          })
        }
      }

      // min loadtime
      setTimeout(() => {
        Pace.once('done', () => {
          this.increaseLoadingPartCounter(callback)
        })
        Pace.restart()
        this.increaseLoadingPartCounter(callback)
      }, this.minLoadTime)

      // Max loadtime
      setTimeout(() => {
        if (this.loadingPartsCounter < this.loadingPartsMax) {
          this.loadingPartsMax = this.loadingPartsCounter
          this.increaseLoadingPartCounter(callback)
        }
      }, this.maxLoadTime)
    },

    preloadImg(source, callback) {
      var img = new Image()
      img.src = source
      var interval = setInterval(() => {
        if (img.complete) {
          clearInterval(interval)
          this.increaseLoadingPartCounter(callback)
        }
      }, 400)
    },
  },
  created() {},
  beforeDestroy() {
    if (!this.isU577 && this.videosToLoad) {
      for (let video of this.videosToLoad) {
        this.loadingPartsMax = 0
        video.removeEventListener('loadeddata', () => {
          this.increaseLoadingPartCounter('video')
        })
      }
    }
  },
}
