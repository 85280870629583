<template>
  <div>
    <case-header :backgroundvid="headerBackgroundVideoUrl" :headerImgUrl="headerImgUrl">
      <h1 slot="h1-title" id="top-headline">Sandoz Global</h1>
      <h3 slot="h3-title">Sandoz Global</h3>
      <div slot="details">
        <p>
          <strong>?</strong>Wie wird ein Generika-Hersteller, der ehemals Original-Medikamente hergestellt hat, zum Global Player in Biopharmazeutika? Zum
          besseren Verständnis: Letztere sind Arzneistoffe die mit den Mitteln der Biotechnologie und gentechnisch veränderten Organismen hergestellt werden.
          Kann man das, dann kann man in diesem Geschäftsbereich wachsen.
        </p>
        <div class="tags">
          <p>B2B Campaign | B2P Campaign | Corporate Design | Packaging Design | Website | Visual Imagery | Logo | Claiming</p>
        </div>
      </div>
    </case-header>

    <div v-show="!caseIsLoading" id="case-content">
      <parallax-glider :imgurl="require('./../assets/case-studies/sandozglobal/' + imgRes + 'parallax-img.jpg')"></parallax-glider>

      <grund-gedanke>
        <h3 slot="title">Sandoz Global</h3>
        <p slot="text">
          <strong>!</strong>Wir erschufen eine eigene Welt, die die Leistung in der Biotechnologie mit der Mondlandung verglich. «Ein grosser Schritt für die
          Menschheit.» Sandoz als Pionier, dies vermittelte nicht nur die Bildwelt, sondern auch der Claim: Pioniering the Future. Die Kampagne wurde abgerundet
          durch ein edel spaciges, einzigartiges Packaging-Design. Und wir können stolz behaupten, dass es auch heute noch seinesgleichen sucht. <br /><br />
          <strong>=</strong>Es ist eher ein persönlicher Erfolg statt einer, welcher von konkreten Zahlen belegt ist. Sandoz war mutig genug, unseren
          unkonventionellen Weg mitzugehen. Als Dankeschön und Vertrauensbeweis wurden wir zum Corporate-Design-Polizisten erkoren, weltweit über alle
          Sandoz-Länder. Eine spannende, grossartige Herausforderung.
        </p>
      </grund-gedanke>

      <section class="laptop-section laptop-section-b black-icons">
        <video v-if="!isU577" id="laptopvideo-doppelleu" class="video" autoplay muted loop>
          <source src="~assets/case-studies/sandozglobal/laptop-sandoz-global.mp4" type="video/mp4" />
          <source src="~assets/case-studies/sandozglobal/laptop-sandoz-global.webm" type="video/webm" />
        </video>
        <img v-if="isU577" class="laptop-mobile" src="~assets/case-studies/sandozglobal/sandozglobal-laptop.jpg" />
      </section>

      <section class="slider-section slider-section-2">
        <carousel :perPage="1" :navigationEnabled="true" :loop="true">
          <slide>
            <img :src="require('./../assets/case-studies/sandozglobal/slider2/' + imgRes + '01.jpg')" />
          </slide>
          <slide>
            <img :src="require('./../assets/case-studies/sandozglobal/slider2/' + imgRes + '02.jpg')" />
          </slide>
          <slide>
            <img :src="require('./../assets/case-studies/sandozglobal/slider2/' + imgRes + '03.jpg')" />
          </slide>
          <slide>
            <img :src="require('./../assets/case-studies/sandozglobal/slider2/' + imgRes + '04.jpg')" />
          </slide>
          <slide>
            <img :src="require('./../assets/case-studies/sandozglobal/slider2/' + imgRes + '05.jpg')" />
          </slide>
        </carousel>
      </section>

      <section class="animation-section-a">
        <img :src="require('./../assets/case-studies/sandozglobal/' + imgRes + 'binocrit-lila.jpg')" id="binocrit-gelb" />
        <img :src="require('./../assets/case-studies/sandozglobal/' + imgRes + 'binocrit-gelb.jpg')" />
      </section>

      <section class="img-section-a">
        <img src="~assets/case-studies/sandozglobal/broschuere.jpg" />
      </section>

      <section class="img-section-b black-icons">
        <img src="~assets/case-studies/sandozglobal/ci.jpg" />
      </section>

      <counter-footer :counters="footerCounters"></counter-footer>

      <case-footer></case-footer>
    </div>
  </div>
</template>

<script>
import CaseHeader from '../components/Case-Header.vue'
import CaseFooter from '../components/Case-Footer.vue'
import Grundgedanke from '../components/Grundgedanke.vue'
import ParallaxGlider from '../components/Parallax-Glider.vue'
import CounterFooter from '../components/Counter-Footer.vue'
import VideoPlayer from '../components/Video-Player.vue'
import CaseLoadStatusMixin from '../mixins/case-load-status'

export default {
  name: 'sandozglobal',
  components: {
    'case-header': CaseHeader,
    'case-footer': CaseFooter,
    'grund-gedanke': Grundgedanke,
    'parallax-glider': ParallaxGlider,
    'counter-footer': CounterFooter,
  },
  mixins: [CaseLoadStatusMixin],
  data() {
    return {
      headerBackgroundVideoUrl: require('./../assets/case-studies/sandozglobal/header.mp4'),
      headerImgUrl: require('./../assets/case-studies/sandozglobal/main.jpg'),
      footerCounters: [
        {
          score: 0,
          max: 35,
          title: 'In 35 Sandoz-Ländern neues Corporate Design eingeführt.',
        },
        {
          score: 0,
          max: 3420,
          title: '3420 km in drei Monaten zurückgelegt (Zürich - Holzkirchen D).',
        },
        {
          score: 0,
          max: 24,
          title: '24 Monate lang CD-Polizist für Sandoz Worldwide.',
        },
      ],
    }
  },
  computed: {
    isU577() {
      return this.$store.getters.isU577
    },
    imgRes() {
      return window.innerWidth <= 577 ? 'low-' : ''
    },
  },
  methods: {
    initCoulourFadeAnimation() {
      var tl = new this.TimelineLite().fromTo('#binocrit-gelb', 1, { alpha: 1 }, { alpha: 0 }, 0)
      var scene = new this.ScrollMagic.Scene({
        triggerElement: '.animation-section-a',
        triggerHook: 0.3,
      }).setTween(tl)
      this.$store.commit('addScrollMagicScene', scene)
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.initilizeLoad(() => {
        this.initCoulourFadeAnimation()
      })
    })
  },
  beforeDestroy() {
    this.$store.commit('removeScrollMagicScenes', 'sandozglobal')
  },
}
</script>

<style lang="scss" scoped>
.animation-section-a {
  position: relative;

  img {
    width: 100%;
  }

  #binocrit-gelb {
    top: 0;
    position: absolute;
  }
}

.img-section-a,
.img-section-b {
  img {
    width: 100%;
  }
}
</style>
