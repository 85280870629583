<template>
  <div>
    <case-header>
      <h1 slot="h1-title" id="top-headline">ViiV Healthcare</h1>
      <h3 slot="h3-title">ViiV Healthcare</h3>
      <div slot="details">
        <p>
          <strong>?</strong>Was braucht ein Healthcare-Unternehmen für eine Werbestrategie, dessen Ziel es ist, irgendwann entbehrlich zu sein? ViiV Healthcare
          ist zum einen auf die Entwicklung innovativer HIV-Medikamenten fokussiert, welche infizierten Menschen mehr Lebensqualität geben. Zum anderen erfüllt
          ViiV Healthcare gleichzeitig den gesellschaftlichen Auftrag der Prävention. Wir bringen das alles unter einen Hut. In der B2B-Bearbeitung der Ärzte,
          in der B2C-Kommunikation gegenüber den Hauptzielgruppen sowie bei Sponsoring- und Event-Auftritten.
        </p>
        <div class="tags">
          <p>B2B Campaign | B2C Campaign | Visual Imagery | Social Media | Guerilla Marketing | Sponsoring | Event-Marketing</p>
        </div>
      </div>
    </case-header>

    <div v-show="!caseIsLoading" id="case-content">
      <video-player
        :videourl="require('./../assets/case-studies/viivhealthcare/videos/spot.mp4')"
        :poster="require('./../assets/case-studies/viivhealthcare/videos/spot-poster.jpg')"
      >
      </video-player>

      <grund-gedanke>
        <h3 slot="title">ViiV <br />Healthcare</h3>
        <p slot="text">
          <strong>!</strong>Entweder man hat HIV oder eben nicht. Der Gedanke ist schwarzweiss, die Bildwelt ist es auch. HIV ist noch immer unheilbar, aber man
          kann lindern. 30 Tabletten am Tag gehört der Vergangenheit an. Es gibt neue Therapien, die HIV erträglicher machen. Aber HIV bleibt in dir. Die
          Kampagne vergleicht HIV mit einer lebenslänglichen Haftstrafe. Es gibt auch da kein Entrinnen. Aber ViiV Healthcare arbeitet mit Hochdruck daran, dies
          irgendwann zu ändern. <br /><br />
          <strong>=</strong>Ehrlich währt am längsten. Nach dieser Devise konnten wir ViiV Healthcare als ehrlichen und authentischen Produzenten von
          HIV-Medikamenten positionieren. Die Marktposition wurde so ordentlich gestärkt. Die Akzeptanz und damit die Reputation wurden bei den HIV-Spezialisten
          extrem erhöht. Schön, wenn man als Agentur ein von Grund auf ehrliches Konzept abliefern und realisieren kann.
        </p>
      </grund-gedanke>

      <section class="slider-section slider-section-1 dots-after">
        <carousel :perPage="1" :navigationEnabled="true" :loop="true">
          <slide>
            <img :src="require('./../assets/case-studies/viivhealthcare/slider2/' + imgRes + '01.jpg')" />
          </slide>
          <slide>
            <img :src="require('./../assets/case-studies/viivhealthcare/slider2/' + imgRes + '02.jpg')" />
          </slide>
          <slide>
            <img :src="require('./../assets/case-studies/viivhealthcare/slider2/' + imgRes + '03.jpg')" />
          </slide>
          <slide>
            <img :src="require('./../assets/case-studies/viivhealthcare/slider2/' + imgRes + '04.jpg')" />
          </slide>
        </carousel>
      </section>

      <section class="animation-section-a" :class="{ low: imgRes }">
        <p>Damit HIV künftig nicht mehr lebenslänglich heisst.</p>
      </section>

      <section class="animation-section-b black-icons">
        <img src="~assets/case-studies/viivhealthcare/schleife1.jpg" />
        <img src="~assets/case-studies/viivhealthcare/schleife2.jpg" />
        <img src="~assets/case-studies/viivhealthcare/schleife3.jpg" />
      </section>

      <section>
        <video :src="AnimationVideoUrl" muted playsinline loop autoplay></video>
      </section>

      <section class="animation-section-c black-icons">
        <div class="content-container">
          <img src="~assets/case-studies/viivhealthcare/engel.jpg" />
          <img class="engle-color" src="~assets/case-studies/viivhealthcare/engel_color.jpg" />
        </div>
        <img class="instagram" src="~assets/case-studies/viivhealthcare/instagram.gif" />
      </section>

      <section class="animation-section-d black-icons" :class="{ low: imgRes }">
        <div class="zebra-container"></div>
      </section>

      <counter-footer :counters="footerCounters"></counter-footer>

      <case-footer></case-footer>
    </div>
  </div>
</template>

<script>
import CaseHeader from '../components/Case-Header-ViiV.vue'
import CaseFooter from '../components/Case-Footer.vue'
import Grundgedanke from '../components/Grundgedanke.vue'
import CounterFooter from '../components/Counter-Footer.vue'
import CaseLoadStatusMixin from '../mixins/case-load-status'
import VideoPlayer from '../components/Video-Player.vue'

export default {
  name: 'viivhealthcare',
  components: {
    'case-header': CaseHeader,
    'case-footer': CaseFooter,
    'grund-gedanke': Grundgedanke,
    'counter-footer': CounterFooter,
    'video-player': VideoPlayer,
  },
  mixins: [CaseLoadStatusMixin],
  data() {
    return {
      AnimationVideoUrl: require('./../assets/case-studies/viivhealthcare/header.mp4'),
      imagesToLoad: [require('./../assets/case-studies/viivhealthcare/zebrastreifen.jpg')],
      footerCounters: [
        {
          score: 0,
          max: 25000,
          title: 'Rund 25000 Menschen leben mit HIV in der Schweiz. Jeder ist einer zu viel! ',
        },
        {
          score: 0,
          max: 6.3,
          title: 'Durchschnittlich 6,3 % Rücklauf erzielten unsere B2B-Mailings.',
        },
        {
          score: 0,
          max: 304,
          title: '304 Bilder wurden für die Erstellung der neuen Bildwelt geshootet. ',
        },
      ],
    }
  },
  computed: {
    isU577() {
      return this.$store.getters.isU577
    },
    imgRes() {
      return window.innerWidth <= 577 ? 'low-' : ''
    },
  },
  methods: {
    initHivAnimation() {
      var tl = new this.TimelineLite().to('.animation-section-a', 1, { backgroundColor: '#888' }, 0).to('.animation-section-a>p', 1, { alpha: 1 }, 0)
      var scene = new this.ScrollMagic.Scene({
        triggerElement: '.animation-section-a',
        triggerHook: 0,
        duration: window.innerHeight * 0.5,
      }).setTween(tl)
      this.$store.commit('addScrollMagicScene', scene)
    },
    initSchleifenAnimation() {
      var tl = new this.TimelineLite()
        .fromTo(
          '.animation-section-b>img:nth-child(1)',
          1,
          { x: '-50%', y: '30%', scaleX: 1.5, scaleY: 1.5 },
          { x: '-50%', y: '10%', scaleX: 1, scaleY: 1, ease: Power2.easeIn },
          0
        )
        .fromTo('.animation-section-b>img:nth-child(2)', 1, { x: '200%' }, { x: '35%', ease: Power2.easeIn }, 0)
        .fromTo('.animation-section-b>img:nth-child(3)', 1, { x: '-200%' }, { x: '-127%', ease: Power2.easeIn }, 0)
      var scene = new this.ScrollMagic.Scene({
        triggerElement: '.animation-section-b',
        triggerHook: 0.7,
        duration: window.innerHeight * 0.8,
      }).setTween(tl)
      this.$store.commit('addScrollMagicScene', scene)
    },
    initZebraAnimation() {
      var tl = new this.TimelineLite()
        .set('.zebra-container', { backgroundPosition: '33.33% 0' }, 0.5)
        .set('.zebra-container', { backgroundPosition: '66.66% 0' }, 1)
        .set('.zebra-container', { backgroundPosition: '100% 0' }, 1.5)
      var scene = new this.ScrollMagic.Scene({
        triggerElement: '.animation-section-d',
        triggerHook: 0.4,
        duration: window.innerHeight * 0.5,
      }).setTween(tl)
      this.$store.commit('addScrollMagicScene', scene)
    },
    initInstagramAnimation() {
      var tl = new this.TimelineLite()
        .fromTo('.instagram', 1, { rotation: '-15deg', x: '-300%' }, { rotation: '-5deg', x: '-70%' }, 0)
        .fromTo('.engle-color', 0.5, { alpha: 0 }, { alpha: 1 }, 0.2)
      var scene = new this.ScrollMagic.Scene({
        triggerElement: '.instagram',
        triggerHook: 0.4,
        duration: 300,
      }).setTween(tl)
      this.$store.commit('addScrollMagicScene', scene)
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.initilizeLoad(() => {
        this.initHivAnimation()
        this.initZebraAnimation()
        if (!this.isU577) {
          this.initSchleifenAnimation()
          this.initInstagramAnimation()
        }
      })
    })
  },
  beforeDestroy() {
    this.$store.commit('removeScrollMagicScenes', 'viivhealthcare')
  },
}
</script>

<style lang="scss" scoped>
.animation-section-a {
  position: relative;
  height: 100vh;
  overflow: hidden;
  text-align: center;
  background-image: url('~assets/case-studies/viivhealthcare/hiv.jpg');
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 140px;
  background-color: #fff;
  background-blend-mode: multiply;

  &.low {
    background-image: url('~assets/case-studies/viivhealthcare/low-hiv.jpg');
  }

  p {
    color: #fff;
    font-weight: 300;
    font-size: 50px;
    margin: 0;
    opacity: 0;
  }

  @media (max-width: 577px) {
    height: 400px;
    padding-bottom: 30px;

    p {
      font-size: 8vw;
    }
  }
}

.animation-section-b {
  @media (min-width: 578px) {
    position: relative;
    height: 100vh;
    overflow: hidden;
    text-align: center;

    > img {
      position: absolute;
      left: 50%;

      &:nth-child(1) {
        height: 70%;
        transform: translateX(-50%);
      }

      &:nth-child(2) {
        height: 70%;
        transform: translateX(35%);
        top: 20%;
      }

      &:nth-child(3) {
        height: 70%;
        top: 15%;
        transform: translateX(-127%);
      }
    }
  }

  @media (max-width: 577px) {
    > img {
      margin: 30px;
      width: calc(100% - 60px);
    }
  }
}

.animation-section-c {
  @media (min-width: 578px) {
    display: flex;
    position: relative;
    height: 100vh;
    justify-content: space-evenly;
    padding: 100px 0;
    margin-top: 0;

    .content-container {
      width: 100%;
      text-align: right;
      position: relative;

      .engle-color {
        position: absolute;
        top: 0;
        right: 0;
        opacity: 0;
      }
    }

    .instagram {
      position: absolute;
      height: 90vh;
      bottom: -12vh;
    }

    .arrow-before {
      margin-left: 60px;
      font-size: 30px;
    }
  }

  @media (max-width: 577px) {
    .engle-color {
      display: none;
    }

    > img {
      margin: 30px;
      width: calc(100% - 60px);
    }
  }
}

.animation-section-d {
  padding: 80px 0;

  .zebra-container {
    margin: 0 auto;
    width: 80vw;
    height: 53.5vw;
    background-image: url('~assets/case-studies/viivhealthcare/zebrastreifen.jpg');
    background-size: 400% 100%;
    background-position: left;

    &.low {
      background-image: url('~assets/case-studies/viivhealthcare/low-zebrastreifen.jpg');
    }
  }
}
</style>
