import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import Store from './store/store'
import $ from 'jquery'
import { EventBus } from './store/eventbus'

// Plugins
import globalExternalScriptsPlugin from './plugins/global-external-scripts'
import VueCarousel from './plugins/carousel/index'

Vue.use(globalExternalScriptsPlugin)
Vue.use(VueRouter)
Vue.use(VueCarousel)

const router = new VueRouter({
  routes: Store.state.routes,
  mode: 'history',
  base: window.__BASE_URL__,
})

var FirstLoad = true
// Hash Legacy
router.beforeEach((to, from, next) => {
  // Redirect if fullPath begins with a hash (ignore hashes later in path)
  if (to.fullPath.substr(0, 2) === '/#') {
    const path = to.fullPath.substr(2)
    next(path)
    return
  }

  // hide Welcome Box
  EventBus.$emit('hideChatWelcomeBox')

  // update ChatWelcomeTextColumn
  var chatTextColumn = false
  var LOADEDEVENT = false
  if (to.name == 'home') {
    chatTextColumn = 'home'
    LOADEDEVENT = 'HomeLoaded'
  } else if (to.name == 'about') {
    chatTextColumn = 'about'
    LOADEDEVENT = 'about-page-laoded'
  } else if (to.name && to.name.indexOf('case-') !== -1) {
    chatTextColumn = 'works'
    LOADEDEVENT = 'case-page-laoded'
  }
  if (chatTextColumn) {
    // set name

    // start Timer
    if (FirstLoad) {
      EventBus.$once(LOADEDEVENT, () => {
        EventBus.$emit('setChatWelcomTextColumn', chatTextColumn)
        EventBus.$emit('restartChatTimeline')
      })
    } else {
      EventBus.$emit('setChatWelcomTextColumn', chatTextColumn)
      EventBus.$emit('restartChatTimeline')
    }
  }
  //
  FirstLoad = false
  next()
})

const app = new Vue({
  el: '#app',
  store: Store,
  render: (h) => h(App),
  router: router,
})
