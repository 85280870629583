<template>
  <section :id="sectionID" class="parallax-glider" :style="{ backgroundImage: 'url(' + img1 + ')' }">
    <div :id="layerID" class="layer" :style="{ backgroundImage: 'url(' + img2 + ')' }"></div>
  </section>
</template>

<script>
export default {
  props: ['img1', 'img2', 'start_position', 'final_position'],
  data() {
    return {
      sectionID: 'parallax-glider-' + Math.round(Math.random() * 100000),
      layerID: 'parallax-glider-' + Math.round(Math.random() * 100000),
    }
  },
  methods: {
    changeDurationOnRezise() {
      var section = document.getElementById(this.sectionID)
      var newHeight = section.offsetHeight
      section.style.height = newHeight * 0.6
    },
    initParallaxAnimation() {
      var tl = new this.TimelineLite()
        .fromTo('#' + this.sectionID, 1, { backgroundPosition: this.start_position }, { backgroundPosition: this.final_position }, 0)
        .fromTo('#' + this.layerID, 1, { backgroundPosition: '50% 0' }, { backgroundPosition: '50% 100%' }, 0)
      var scene = new this.ScrollMagic.Scene({
        triggerElement: '#' + this.sectionID,
        triggerHook: 0.9,
        duration: window.innerHeight * 2,
      }).setTween(tl)
      this.$store.commit('addScrollMagicScene', scene)
      window.addEventListener('resize', () => {
        scene.duration(window.innerHeight * 2)
      })
    },
  },
  mounted() {
    this.$parent.$on('caseIsLoaded', () => {
      this.initParallaxAnimation()
    })
  },
}
</script>

<style lang="scss" scoped>
.parallax-glider {
  overflow: hidden;
  height: 80vw;
  background-size: 100% auto;
  position: relative;

  .layer {
    position: absolute;
    height: 100%;
    width: 100%;
    background-size: 100% auto;
  }
}

// @media (max-width: 1100px) {
//   .parallax-glider {
//     height: 50vh;
//   }
// }
</style>
