export default {
  computed: {
    isU577() {
      return this.$store.getters.isU577
    },
  },
  methods: {
    /**
     * Animation to "A" on Home
     **/
    fullTransition__ToHome(el, done) {
      var tl = new this.TimelineLite()
        .call(() => {
          TweenMax.set('#transition-layer', { height: window.innerHeight })
          this.$store.commit('setTransitionLayerToTop')
          this.$store.dispatch('showTransitionLayer')
          this.$store.commit('showToHome')
          this.$store.dispatch('playAnimation', 'toHome')
        })
        .call(
          () => {
            this.$store.commit('forceIconsBlack')
          },
          [],
          this,
          0.5
        )
        .to('#page-transition--white-background', 0.3, { alpha: 1 }, 0.6)
        .set('#page-transition--white-background', { alpha: 0 }, 1.5)
        .call(
          () => {
            done()
          },
          [],
          this,
          1
        )
        .call(
          () => {
            this.$store.commit('dontForceIconsColor')
            this.$store.dispatch('handleScrollToDetectIconColor') // Hat Probleme gemacht: nach der Transtion waren die Icons Weiß
            this.$store.dispatch('resetAnimation', 'toHome')
            this.$store.commit('changeCloseToToggle')
            this.$store.dispatch('hideOverlayMenu')
            this.$store.commit('setTransitionLayerToBackground')
            this.$store.dispatch('hideTransitionLayer')
            this.$store.commit('hideToHome')
            TweenMax.set('#transition-layer', { height: '100vh' })
            this.$store.dispatch('allowScroll')
            window.scrollTo(0, 0)
          },
          [],
          this,
          1.5
        )
    },

    /**
     * Slide Animation to Case
     **/
    prepareViewSlide(view) {
      var tl = new this.TimelineLite()
        .set('.VueCarousel-pagination', { 'z-index': 0 })
        .set(view, { position: 'absolute', top: window.pageYOffset, left: 0, width: '100vw' })
        .call(() => {
          this.$store.dispatch('forbidScroll', false)
          this.$store.commit('changeCloseToToggle')
          this.$store.dispatch('handleScrollToDetectIconColor')
        })
    },

    slideCaseInFromRight(view) {
      var tl = new this.TimelineLite()
        .fromTo(view, 2, { x: '100%' }, { x: '0%', ease: Power2.easeInOut }, 0)
        .fromTo(view.querySelector('.details-button'), 1, { x: '100%' }, { x: '0%', ease: Power2.easeInOut }, 1)
        .fromTo(view.querySelector('.details-button-after'), 1, { x: window.innerWidth / 2 }, { x: 0, ease: Power3.easeInOut }, 1)
    },

    slideMenuToLeft() {
      var tl = new this.TimelineLite()
        .to('#overlay-menu', 2, { x: '-100%', ease: Power2.easeInOut }, 0)
        .set('#overlay-menu', { x: '0%' }, 2)
        .call(
          () => {
            this.$store.dispatch('hideOverlayMenu')
          },
          [],
          this,
          2
        )

      if (window.innerWidth > 577) {
        var tl2 = new this.TimelineLite().to('#overlay-menu .about', 2, { x: '-100%', ease: Power2.easeInOut }, 0).set('#overlay-menu .about', { x: '0%' }, 2)
      }
    },

    slideLastViewToLeft(isCaseScroller) {
      var tl = new this.TimelineLite().to(this.oldView, 2, { x: '-100%', ease: Power2.easeInOut }, 0).set(this.oldView, { x: '0%' }, 2)

      if (isCaseScroller) {
        var tl2 = new this.TimelineLite().to(this.oldView.querySelector('.activeCaseInScroller h3'), 2, { x: '-150%', ease: Power2.easeInOut }, 0)
      }
    },

    endViewSlide(view, done, scrollPos) {
      var tl = new this.TimelineLite()
        .set(view, { top: 0 }, 2)
        .set(view, { clearProps: 'all' }, 2)
        .call(
          () => {
            done()
          },
          [],
          this,
          2
        )

      if (scrollPos) {
        var tl2 = new this.TimelineLite().set(view, { marginTop: 0 }, 2).call(
          () => {
            window.scrollTo(0, this.$store.state.lastInstaScrollPos)
            this.$store.commit('setLastInstaScrollPos', 0)
          },
          [],
          this,
          2
        )
      }
    },

    /**
     * Slide Single From Right
     **/
    slideSingleInFromRight(view) {
      var whiteBGOffset = window.innerWidth <= 900 ? 0 : (window.innerWidth - 900) / 2 + 200 //calc( (100% - 900px)/2 + 700px)
      var tl = new this.TimelineLite()
        .fromTo(view, 2, { x: '100%' }, { x: '0%', ease: Power2.easeInOut }, 0)
        .fromTo(
          view.querySelector('.white-background'),
          2,
          { width: 'calc(100% - 0px)' },
          { width: 'calc(100% - ' + whiteBGOffset + 'px)', ease: Power2.easeInOut },
          0
        )
        .fromTo(view.querySelector('.post-text'), 1, { x: 50 }, { x: 0, ease: Power3.easeInOut }, 1)
        .fromTo(view.querySelector('.post-media'), 2, { x: 50 }, { x: 0, ease: Power3.easeInOut }, 0)
        .fromTo(view.querySelector('.arrowBack img'), 0.5, { alpha: 0, x: 50 }, { alpha: 1, x: 0, ease: Power3.easeInOut }, 1.5)
        .call(() => {
          this.$store.dispatch('allowScroll')
          window.scrollTo(0, 0)
        })
    },
    slideLastViewToRight() {
      var tl = new this.TimelineLite().to(this.oldView, 2, { x: '100%', ease: Power2.easeInOut }, 0).set(this.oldView, { x: '0%' }, 2)
    },
    slideSingleInFromLeft(view) {
      var whiteBGOffset = window.innerWidth <= 900 ? 0 : (window.innerWidth - 900) / 2 + 200
      var tl = new this.TimelineLite()
        .fromTo(view, 2, { x: '-100%' }, { x: '0%', ease: Power2.easeInOut }, 0)
        .fromTo(
          this.oldView.querySelector('.white-background'),
          2,
          { width: 'calc(100% - ' + whiteBGOffset + 'px)' },
          { width: 'calc(100% - 0px)', ease: Power2.easeInOut },
          0
        )
        .fromTo(this.oldView.querySelector('.post-text'), 2, { x: 0 }, { x: 50, ease: Power3.easeInOut }, 0)
        .fromTo(this.oldView.querySelector('.arrowBack img'), 0.5, { alpha: 1, x: 0 }, { alpha: 0, x: 50, ease: Power3.easeInOut }, 0)
        .call(() => {
          this.$store.dispatch('allowScroll')
        })
    },

    /**
     * Slide From Botton
     **/
    slideFromBottom(view, done) {
      var duration = 1
      window.scrollTo(0, document.body.scrollHeight)
      TweenMax.to(window, 1, { scrollTo: { y: document.body.scrollHeight } })

      // Höhe des Views begrenzen, wenn das Overlay geöffnet ist
      if (this.$store.state.overlayMenuvisible) {
        TweenMax.set(this.oldView, { height: window.innerHeight })
      }

      var tl = new this.TimelineLite()
        .call(() => {
          this.$store.dispatch('forbidScroll')
          this.$store.state.lastScrollPosition = 0
        })
        .set(view, { position: 'absolute', left: 0, width: '100vw' })
        .to('#overlay-menu', duration, { y: '-100%', ease: Power2.easeInOut }, 0)
        .to(view, duration, { y: window.innerHeight * -1, ease: Power2.easeInOut }, 0)
        .fromTo(this.oldView, duration, { y: 0 }, { y: window.innerHeight * -1, ease: Power2.easeInOut }, 0)
        .call(
          () => {
            window.scrollTo(0, 0)
            if (this.$route.path.indexOf('/sidrosan') == -1) {
              this.$store.dispatch('allowScroll')
            }
            if (this.$route.query.scrollTo) {
              if (this.$route.query.scrollTo == 'works') {
                TweenMax.to(window, 1, { scrollTo: { y: '#caseStudies', autoKill: false } })
              } else {
                var tagetID = this.$route.query.scrollTo
                TweenMax.to(window, 1, { scrollTo: { y: '#' + tagetID, autoKill: false } })
              }
            }
            this.$store.dispatch('hideOverlayMenu')
            this.$store.dispatch('handleScrollToDetectIconColor')
            done()
          },
          [],
          this,
          duration
        )
        .set(view, { clearProps: 'all' })
        .set('#overlay-menu', { clearProps: 'all' })
        .set(this.oldView, { clearProps: 'all' })
    },
  },
}
