export const Aufgaben = [
  {
    title: 'Strategie',
    content:
      'Überlassen Sie Ihren Unternehmenserfolg nicht dem Zufall. Für maximale Effektivität und Return On Investment Ihrer Marketing-Investition ist eine durchdachte Strategie unerlässlich. Wir erarbeiten eine ganzheitliche strategische Ausrichtung für Ihr Unternehmen, damit Sie Ihre Zielgruppe zur richtigen Zeit am richtigen Ort mit der richtigen Botschaft erreichen.<br><br>Leistungen:<br>Vision / Mission / Leitbild / Marketingstrategie / Kommunikationsstrategie / Branding-Strategie / Social-Media-Strategie / Digital-Strategie / Social-Media-Strategie',
    isActive: false,
  },
  {
    title: 'Branding',
    content:
      'Ihr Unternehmen ist einzigartig. Und zwar unabhängig davon, welche Produkte und Dienstleistungen Sie anbieten. Lassen Sie uns gemeinsam Ihr Innerstes nach aussen tragen und Ihre Marke erlebbar machen. Mit packender Erzählweise und einem Gesamtauftritt, der Ihre Werte, Persönlichkeit, Vision, Botschaft und Bestimmung vermittelt.<br><br>Leistungen:<br>Naming / Logo / Corporate Identity / Corporate Design / Brand Identity / Product Identity / Packaging Design',
    isActive: false,
  },
  {
    title: 'Kreation',
    content:
      'Geben Sie nicht unnötig Geld aus für Werbung, die ignoriert oder nach 5 Sekunden weggeklickt wird. Als mehrfach ausgezeichnete Kreativ-Agentur sind wir spezialisiert auf unkonventionelle Ideen und hochwertige Umsetzungen mit hohem Unterhaltungswert. Wir erfüllen nicht nur Briefings, sondern lassen unserer Kreativität freien Lauf.<br><br>Leistungen:<br>Analog / Digital / Guerilla / B2B-Kampagne / B2C-Kampagne / Employer Branding Kampagne / Digital- & Social-Media-Kampagne / Crossmedia / Out of Home (OOH) / TV / Radio',
    isActive: false,
  },
  {
    title: 'Content',
    content:
      'Content ist nicht gleich Content. Lassen Sie uns gemeinsam einen Content-Plan für Ihre Social-Media-Kanäle erarbeiten und sie mit Inhalten füllen, die sich von anderen abheben. Lassen Sie Ihr Zielpublikum an der Geschichte Ihres Unternehmens teilhaben und gewähren Sie auf authentische Weise Einblick in die Welt Ihrer Marke.<br><br>Leistungen:<br>Social-Media-Strategie / Social Storytelling / Social Production & Management / Influencer Marketing',
    isActive: false,
  },
  {
    title: 'Media & Technologie',
    content:
      'Nur messbare Erfolge sind echte Erfolge. Unsere Datenanalysen und -auswertungen liefern wertvolle Einblicke und ermöglichen präzise Quantifizierungen Ihrer Erfolge. Mit modernsten Tools messen wir für Sie die Wirksamkeit Ihrer Marketing-Massnahmen - bei Kampagnen kontinuierlich – und stellen sicher, dass Sie Ihr Geld immer am richtigen Ort investieren. <br><br>Leistungen:<br>SEO / SEA / Mediaplanung, -buchung & strategie / Digital / Social / Data Driven Advertising / Feed Based Advertising / Programmatic Campaigns / Data Analytics',
    isActive: false,
  },
]

export const Werte = [
  {
    title: '1. Wir sind keine Werbeagentur!',
    text: 'Weil heute mehr denn je ganzheitliche Lösungen und keine Disziplinen gesucht sind, braucht es vielmehr Antworten. Es kann also durchaus sein, dass wir selbst Produkte, Preispolitik oder Distributionswege kritisch in Frage stellen.',
  },
  {
    title: '2. Wir handeln unternehmerisch!',
    text: 'Als Vollblut-Unternehmer denken wir unternehmerisch und handeln für die Produkte und Dienstleistungen unserer Kunden als wären es unsere eigenen. Als Macher nehmen wir in den wenigsten Fällen den einfachsten Weg. Übrigens trifft man uns eher knietief im Dreck als voll parfümiert auf dem Golfplatz.',
  },
  {
    title: '3. Wir fokussieren Relevanz!',
    text: 'Wir machen unsere Kunden relevant für deren Kunden! Damit platzieren wir die Botschaften genau dann und dort, wenn sie bei den Konsumenten auch relevant sind. Und natürlich in einem ausgeklügelten Storytelling-Prozess. Denn was heute relevant ist, kann morgen bereits irrelevant sein.',
  },
  {
    title: '4. Wir hassen Blabla!',
    text: 'Wir hassen aufgeblähte Prozesse, Geschwafel oder langweilige Konzeptpapiere. Viel lieber sind uns konkrete Customer Journeys und Hands-on Workshops. Wir planen kurz, bündig und denken in Tasks und Umsetzungen. «Delivery is our Strategy!»',
  },
  {
    title: '5. Wir sind schnell!',
    text: 'Schlafen können wir immer noch, wenn wir tot sind. Wir wollen bewegen, Berge versetzen und immer einen Schritt schneller sein, damit wir jederzeit die richtigen Antworten parat haben. Kleine Teams, agile Vorgehensweisen und Involvements unserer Kunden machen uns zum Schnellboot. Auch wenn’s mal brennt.',
  },
  {
    title: '6. Wir lieben Technologie!',
    text: 'Daten sind die Basis unserer Strategien und Tests unserer Entscheidungen. High-tech-Tools helfen uns dabei, spezifische Botschaften an verschiedene Segmente und Persönlichkeiten zu bringen. Damit verhindern wir Streuverluste. Denn Schiessen ohne Zielfernrohr war gestern.',
  },
  {
    title: '7. Wir achten auf Excellence im Detail!',
    text: 'Der Teufel liegt bekanntlich im Detail. Mit einem lustigen Visual alleine kriegen wir keine Kunden ins Netz. Manchmal entscheidet auch nur die Farbe des Kaufbuttons über den Erfolg einer Kampagne. Wir achten also auch auf Kleinigkeiten. Auch weil gerade diese manchmal matchentscheidend sind.',
  },
]

export const Team = [
  {
    name: 'Sarah Hiltebrand',
    position: 'CD & Partner',
    text: 'Ging nach Abschluss der Kunstgewerbeschule direkt in die Werbung. Sammelte mehrjährige Erfahrung in diversen Agenturen. Gründete 2005 mit Reto Dürrenberger die Werbeanstalt Schweiz AG, welche 2018 zu DIE ANTWORT umbenannt wurde. Seit 2009 ist sie zudem Mitinhaberin und Markenchefin bei Rent a Rentner AG. Seit 2018 ist Sarah zudem Jurymitglied bei den Webby Awards, dem grössten und prestigeträchtigsten Preis in der Digitalen Branche.',
    mail: 'hiltebrand@dieantwort.com',
    linkedin: 'https://www.linkedin.com/in/sarah-hiltebrand-497b305/?originalSubdomain=ch',
    insta: 'https://www.instagram.com/hiltebrandsarah/',
    tiktok: 'https://www.tiktok.com/@dieantwortag',
    facebook: 'https://www.facebook.com/sarah.hiltebrand.1',
    img: require('./../assets/about/team/_Antwort_Sarah3414_670x960px_RGB.jpg'),
  },
  {
    name: 'Reto Dürrenberger',
    position: 'Stratege & Partner',
    text: 'War jahrelang erst auf Kunden- und dann auf Agenturseite tätig. Ist eidg. dipl. Marketingleiter und Dozent bei Marketinglehrgängen. Gründete zusammen mit Sarah Hiltebrand 2005 Werbeanstalt Schweiz AG, welche 2018 zu DIE ANTWORT umbenannt wurde. Seit 2009 ist er ausserdem Mitinhaber und Geschäftsführer bei Rent a Rentner AG.',
    mail: 'duerrenberger@dieantwort.com',
    linkedin: 'https://www.linkedin.com/in/reto-dürrenberger-6aa88321/',
    insta: 'https://www.instagram.com/retoduerr/',
    twitter: 'https://twitter.com/Duerrenberger',
    tiktok: 'https://www.tiktok.com/@dieantwortag',
    facebook: 'https://www.facebook.com/rduerrenberger',
    img: require('./../assets/about/team/Antwort_Reto3863_670x960px_RGB.jpg'),
  },
  {
    name: 'Mike Schwede',
    mail: 'schwede@dieantwort.com',
    img: require('./../assets/about/team/Die_Antwort_MikeSchwede.jpg'),
  },
  {
    name: 'Bernhard Bauhofer',
    mail: 'bauhofer@dieantwort.com',
    img: require('./../assets/about/team/__Bernhard_Bauhofer.jpg'), 
  },
  {
    name: 'Cenk Korkmaz',
    mail: 'korkmaz@dieantwort.com',
    img: require('./../assets/about/team/Antwort_Cenk2431_670x960px_RGB.jpg'),
  },
  {
    name: 'Mike Krüll',
    mail: 'kruell@dieanwort.com',
    img: require('./../assets/about/team/Die_Antwort_MikeKruell.jpg'),
  },
  {
    name: 'Jamira Baù',
    mail: 'bau@dieantwort.com',
    img: require('./../assets/about/team/_Antwort_JamiraBau_670x960px_RGB.jpg'),
  },
  {
    name: 'Joris Brönnimann',
    mail: 'frage@dieantwort.com',
    img: require('./../assets/about/team/Antwort_Joris2249_670x960px_RGB.jpg'),
  },
  {
    name: 'Leonie Bachmann',
    mail: 'bachmann@dieantwort.com',
    img: require('./../assets/about/team/_Antwort_Leoniebachmann_670x960px_RGB.jpg'),
  },
  {
    name: 'Coming Soon ',
    mail: 'frage@dieantwort.com',
    img: require('./../assets/about/team/Platzhalter_Melanie_RGB.jpg'),
  },
  {
    name: 'Andrea Mossa',
    mail: 'mossa@dieantwort.com',
    img: require('./../assets/about/team/_Antwort_AndreaMossa_670x960px_RGB.jpg'),
  },
  {
    name: 'Victoria Njoki',
    mail: 'njoki@dieantwort.com',
    img: require('./../assets/about/team/__Victoria_Njoki.jpg'),  
  },
  {
    name: 'Marc Lanz',
    mail: 'frage@dieantwort.com',
    img: require('./../assets/about/team/_Antwort_MarcLanz_670x960px_RGB.jpg'),
  },
  {
    name: 'Alexandra Mastai',
    mail: 'frage@dieantwort.com',
    img: require('./../assets/about/team/Die_Antwort_AlexandraMastai.jpg'),
  },
  {
    name: 'Nicolas Chicherio',
    mail: 'chicherio@dieantwort.com',
    img: require('./../assets/about/team/__Nicolas_Chicherio.jpg'),   
  },
  {
    name: 'Deine Initiative zählt!',
    text: 'Hast du Interesse bei uns zu arbeiten? Dann schreib uns. Wir fördern Talente.',
    mail: 'frage@dieantwort.com',
    img: 'black',
  },
  // {
  //   name: 'Sandro Haag',
  //   mail: 'frage@dieantwort.com',
  //   img: require('./../assets/about/team/Antwort_Sandro4226_670x960px_RGB.jpg'),
  // },
  // {
  //   name: 'Martin Bättig',
  //   mail: 'frage@dieantwort.com',
  //   img: require('./../assets/about/team/Die_Antwort_MartinBaettig.jpg'),
  // },
]
