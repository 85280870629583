<template>
  <section class="section-team-carousel">
    <div class="owl-carousel carousel-team" ref="carouselteam">
      <div
        class="item"
        v-for="(person, index) in team"
        :key="person.name"
        @touchstart="handleTouch(index, $event)"
        :class="[{ touched: activeTouchItem === index, info: person.img == 'black' }, person.wrapperClass ? person.wrapperClass : '']"
      >
        <img v-if="person.img !== 'black'" :src="person.img" />
        <div class="overlay">
          <h3>{{ person.name }}</h3>
          <h4 v-if="person.position">{{ person.position }}</h4>
          <div class="text" v-if="person.text" v-html="person.text"></div>
          <div class="link-section">
            <a class="link-kontakt" v-if="person.mail" :href="'mailto:' + person.mail">{{ person.kontaktText ? person.kontaktText : 'Kontakt' }}</a>
            <div class="social-links">
              <a v-if="person.linkedin" :href="person.linkedin" target="_blank">
                <IconLinkedin />
              </a>
              <a v-if="person.twitter" :href="person.twitter" target="_blank">
                <IconTwitter />
              </a>
              <a v-if="person.insta" :href="person.insta" target="_blank">
                <IconInstagram />
              </a>
              <a v-if="person.whatsapp" :href="person.whatsapp" target="_blank">
                <IconWhatsapp />
              </a>
              <a v-if="person.tiktok" :href="person.tiktok" target="_blank">
                <IconTiktok />
              </a>
              <a v-if="person.facebook" :href="person.facebook" target="_blank">
                <IconFacebook />
              </a>
            </div>
            <a class="link-kontakt-phone" v-if="person.phone" :href="'tel:' + person.phone">{{ person.phone }}</a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel'
import { Team } from './../store/data-about.jsx'

import IconLinkedin from './../assets/theme_icons/social-icons/logo-linkedin.svg?inline'
import IconTwitter from './../assets/theme_icons/social-icons/logo-twitter-bird.svg?inline'
import IconInstagram from './../assets/theme_icons/social-icons/logo-instagram.svg?inline'
import IconWhatsapp from './../assets/theme_icons/social-icons/logo-whatsapp.svg?inline'
import IconTiktok from './../assets/theme_icons/social-icons/logo-tiktok.svg?inline'
import IconFacebook from './../assets/theme_icons/social-icons/logo-facebook.svg?inline'

export default {
  name: 'team-carousel',

  components: {
    IconLinkedin,
    IconTwitter,
    IconInstagram,
    IconWhatsapp,
    IconTiktok,
    IconFacebook,
  },

  data() {
    return {
      team: Team,
      activeTouchItem: -1,
      icons: {
        linkedin: require('./../assets/theme_icons/social-icons/logo-linkedin.svg'),
        twitter: require('./../assets/theme_icons/social-icons/logo-twitter-bird.svg'),
        insta: require('./../assets/theme_icons/social-icons/logo-instagram.svg'),
        whatsapp: require('./../assets/theme_icons/social-icons/logo-whatsapp.svg'),
      },
    }
  },

  methods: {
    initCarousel() {
      $(this.$refs.carouselteam).owlCarousel({
        loop: true,
        mouseDrag: false,
        touchDrag: false,
        nav: true,
        navText: ['<span class="arrow"></span>', '<span class="arrow"></span>'],
        dots: false,
        responsive: {
          0: {
            items: 1,
            autoWidth: true,
          },
          767: {
            items: 2,
          },
          1000: {
            items: 3,
          },
        },
      })
    },
    handleTouch(index, event) {
      // click auf offenenes Overlay
      if (this.activeTouchItem === index) {
        const element = event.target
        // element itself is the link-section
        if (element.classList && element.classList.contains('link-section')) {
          return true
          // Element has Link section as parent
        } else if (element.closest('div.link-section')) {
          return true
        }
      }

      this.activeTouchItem = this.activeTouchItem === index ? -1 : index
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.initCarousel()
    })
  },
}
</script>

<style lang="scss">
@import '~assets/scss/vars';

.owl-carousel.carousel-team {
  button {
    &.owl-prev,
    &.owl-next {
      position: absolute;
      top: 0;
      left: 0;
      width: 50px;
      height: 100%;
      background: $green;
      opacity: 1;
      transition: all 250ms ease-in-out;
      outline: none !important;

      @media not all and (hover: none) {
        opacity: 0.6;

        &:hover {
          opacity: 1;
        }
      }

      > .arrow {
        &::before {
          content: url('~assets/theme_icons/arrow-left.svg');
          width: 27px;
          display: inline-block;
        }
      }

      @media (max-width: 577px) {
        height: 80px;
        top: calc(50% - 40px);
      }
    }

    &.owl-next {
      left: auto;
      right: 0;
      width: 62px;

      > .arrow {
        &::before {
          transform: rotate(180deg);
          margin-right: 12px;
        }
      }
    }
  }

  .owl-stage {
    display: flex;
  }

  .item {
    position: relative;

    @media (max-width: 767px) {
      width: 100vw;
    }

    .overlay {
      position: absolute;
      padding: 60px;
      background: $green;
      top: 0;
      left: 0;
      width: calc(100% - 120px);
      height: calc(100% - 120px);
      display: flex;
      justify-content: center;
      flex-direction: column;
      font-weight: 900;
      transition: all 250ms ease-in-out;
      opacity: 0;
      pointer-events: none;

      h3 {
        font-size: 30px;
        margin-bottom: 0;
      }

      .text {
        line-height: 1.3;
        font-size: 13px;
      }

      .link-section {
        padding: 30px;
        margin-left: -30px;
        width: fit-content;
      }

      .link-kontakt {
        color: #000;

        @media (hover: hover) {
          transition: all 250ms ease-in-out;

          &:hover {
            opacity: 0.6;
          }
        }

        &::after {
          content: url('~assets/theme_icons/arrow-small-right.svg');
          width: 16px;
          display: inline-block;
          margin-left: 10px;
        }
      }
    }

    &.black-overlay {
      .overlay {
        background: #000;

        h3,
        .text,
        .link-kontakt {
          color: #fff;
        }

        .link-kontakt {
          @media (hover: hover) {
            &::after {
              content: url('~assets/theme_icons/arrow-small-right-weiss.svg');
            }
          }
        }
      }
    }

    @media (hover: hover) {
      &:hover {
        > .overlay {
          opacity: 1;
          pointer-events: auto;
        }
      }
    }

    &.touched {
      > .overlay {
        opacity: 1;
        pointer-events: auto;
      }
    }

    &.info {
      height: 100%;

      > .overlay {
        opacity: 1;
        background-color: #000;
        pointer-events: auto;
        position: static;
        transition: none;
        padding-right: 80px;
        width: calc(100% - 140px);

        h3 {
          color: #fff;
        }

        .text {
          color: #fff;
          margin-top: 15px;
        }

        .link-kontakt {
          color: #fff;
        }
      }
    }

    .social-links {
      margin-top: 10px;

      a {
        display: block;
        width: 28px;
        height: 28px;
        padding: 10px;
        float: left;
        border-bottom: 1px solid transparent;

        &:first-child {
          padding-left: 0;
        }

        @media (max-width: 567px) {
          width: 30px;
          height: 30px;
          padding: 14px;
        }

        @media (hover: hover) {
          transition: all 250ms ease-in-out;

          &:hover {
            border-color: #000;
          }
        }
      }
    }

    .link-kontakt-phone {
      color: #000;
    }
  }
}
</style>
