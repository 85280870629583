<template>
  <div
    id="transition-layer"
    :class="{
      'transition-layer--visible': transitionLayerVisible,
      isOnTop: transitionLayerisOnTop,
    }"
  >
    <div id="page-transition--white-background"></div>
    <div id="page-transition--case-loader"></div>
    <div id="page-transition--to-home" :class="{ visible: toHomeIsVisible }" ref="page-transition--to-home"></div>
    <div id="animation--menu-in" :class="{ visible: menuInIsVisible }" ref="animation--menu-in"></div>
    <div id="animation--menu-out" :class="{ visible: menuOutIsVisible }" ref="animation--menu-out"></div>
  </div>
</template>

<script>
import animationToHomeData from './../assets/aninmations/page-transition--to-home.json'
import animationMenuInData from './../assets/aninmations/animation--menu-in.json'
import animationMenuOutData from './../assets/aninmations/animation--menu-out.json'

export default {
  data() {
    return {}
  },
  computed: {
    transitionLayerVisible() {
      return this.$store.state.transitionLayerVisible
    },
    toHomeIsVisible() {
      return this.$store.state.toHomeIsVisible
    },
    menuInIsVisible() {
      return this.$store.state.menuInIsVisible
    },
    menuOutIsVisible() {
      return this.$store.state.menuOutIsVisible
    },
    transitionLayerisOnTop() {
      return this.$store.state.transitionLayerisOnTop
    },
  },
  methods: {
    createAnimations() {
      var animationToHome = this.bodymovin.loadAnimation({
        container: this.$refs['page-transition--to-home'],
        renderer: 'svg',
        loop: false,
        autoplay: false,
        animationData: animationToHomeData,
      })
      this.$store.commit('addAnimation', {
        name: 'toHome',
        animation: animationToHome,
      })

      var animationMenuIn = this.bodymovin.loadAnimation({
        container: this.$refs['animation--menu-in'],
        renderer: 'svg',
        loop: false,
        autoplay: false,
        animationData: animationMenuInData,
      })
      this.$store.commit('addAnimation', {
        name: 'menuIn',
        animation: animationMenuIn,
      })

      var animationMenuOut = this.bodymovin.loadAnimation({
        container: this.$refs['animation--menu-out'],
        renderer: 'svg',
        loop: false,
        autoplay: false,
        animationData: animationMenuOutData,
      })
      this.$store.commit('addAnimation', {
        name: 'menuOut',
        animation: animationMenuOut,
      })
    },
  },
  mounted() {
    this.createAnimations()
  },
}
</script>

<style lang="scss" scoped>
@import '~assets/scss/vars';

#transition-layer {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  bottom: 0;
  overflow: hidden;
  pointer-events: none;
  opacity: 0;
  z-index: 8;

  &.transition-layer--visible {
    pointer-events: auto;
    opacity: 1;
  }

  &.isOnTop {
    z-index: 13;
  }

  #page-transition--to-home {
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    position: absolute;
    top: 50%;
    left: calc(50% - 5px);
    transform: translate(-50%, -50%);
    max-width: 100%;
    opacity: 0;

    &.visible {
      opacity: 1;
    }

    @media (max-aspect-ratio: 16 / 9) {
      max-width: none;
      max-height: 100%;
      height: 100%;
      width: calc((100vh / 9) * 16);
    }
  }

  #page-transition--white-background {
    width: 100%;
    height: 100%;
    position: absolute;
    background: #fff;
    opacity: 0;
  }

  #animation--menu-in {
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 100%;
    opacity: 0;

    &.visible {
      opacity: 1;
    }

    @media (max-aspect-ratio: 16 / 9) {
      max-width: none;
      max-height: 100%;
      height: 100%;
      width: calc((100vh / 9) * 16);
    }
  }

  #animation--menu-out {
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 100%;
    opacity: 0;

    &.visible {
      opacity: 1;
    }

    @media (max-aspect-ratio: 16 / 9) {
      max-width: none;
      max-height: 100%;
      height: 100%;
      width: calc((100vh / 9) * 16);
    }
  }
}
</style>
