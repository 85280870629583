import { TimelineLite } from 'gsap'
import ScrollMagic from 'ScrollMagic'
import 'animation.gsap'
import bodymovin from 'bodymovin'

const globalExternalScriptsPlugin = {}

globalExternalScriptsPlugin.install = function (Vue) {
  Vue.prototype.ScrollMagic = ScrollMagic
  Vue.prototype.TimelineLite = TimelineLite
  Vue.prototype.bodymovin = bodymovin
}

export default globalExternalScriptsPlugin
