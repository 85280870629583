import TransitionsMixin from './transitions'

export default {
  computed: {
    state() {
      return this.$store.state.nextPageTransition
    },
  },
  mixins: [TransitionsMixin],
  methods: {
    appearTransition(el) {
      this.$store.commit('saveLastView', el)
    },

    leaveTransition(el, done) {
      if (this.state) {
        if (this.state == 'toHome--fromLogo') {
          this.fullTransition__ToHome(el, done)
        } else {
          done()
        }
      } else {
        done()
      }
      this.$store.commit('setNextPageTransition', false)
    },

    enterTransition(view, done) {
      this.oldView = this.$store.state.lastView

      this.oldView.classList.remove('active-view')
      view.classList.add('active-view')
      if (this.state) {
        if (this.state == 'toHome--fromLogo') {
          TweenMax.set('#start', { height: window.innerHeight })
          done()
        } else if (this.state == 'toCase--fromMenu') {
          this.prepareViewSlide(view)
          this.slideCaseInFromRight(view)
          this.endViewSlide(view, done)
          this.slideMenuToLeft()
        } else if (this.state == 'toCase--fromScroller') {
          this.prepareViewSlide(view)
          this.slideCaseInFromRight(view)
          this.endViewSlide(view, done)
          this.slideLastViewToLeft()
        } else if (this.state == 'slideFromBottom') {
          this.slideFromBottom(view, done)
        } else if (this.state == 'toInstaSingle') {
          this.prepareViewSlide(view)
          this.slideLastViewToLeft(false)
          this.slideSingleInFromRight(view)
          this.endViewSlide(view, done)
        } else if (this.state == 'toInsta') {
          var tl = new this.TimelineLite().set(view, { marginTop: this.$store.state.lastInstaScrollPos * -1 })
          this.prepareViewSlide(view)
          this.slideLastViewToRight()
          this.slideSingleInFromLeft(view)
          this.endViewSlide(view, done, true)
        }
      } else {
        done()
      }
      this.$store.commit('saveLastView', view)
      this.$store.state.lastScrollPosition = 0
    },
  },
}
