<template>
  <div id="app">
    <div id="black-scrollbar-bg"></div>

    <appheader></appheader>

    <chat></chat>

    <transition @appear="appearTransition" @enter="enterTransition" @leave="leaveTransition" mode="in-out" :css="false">
      <keep-alive :include="['home']">
        <router-view class="view"></router-view>
      </keep-alive>
    </transition>
  </div>
</template>

<script>
import Header from './components/Header.vue'
import Chat from './components/Chat.vue'
import TransitionHandlerMixin from './mixins/transition-handler'

export default {
  components: {
    appheader: Header,
    chat: Chat,
  },
  mixins: [TransitionHandlerMixin],
  mounted() {
    document.querySelector('#app > .view').classList.add('active-view')
    this.$store.dispatch('handleScrollToDetectIconColor')
    var path = window.location.pathname
    if (path !== window.__BASE_URL__) {
      var tl = new this.TimelineLite()
        .to('#preloader .wird-geladen', 0.6, {
          alpha: 0,
          'pointer-events': 'none',
        })
        .to('#preloader > img', 0.5, { alpha: 0, 'pointer-events': 'none' }, 0.3)
        .to('#preloader', 1, { alpha: 0, 'pointer-events': 'none' }, 0.9)
    }
    document.dispatchEvent(new Event('app.rendered'))
  },
}
</script>

<style lang="scss">
@import '~assets/scss/globals';

#black-scrollbar-bg {
  width: 12px;
  background-image: url('#test');
  height: 100%;
  position: absolute;
  right: 0;
  background-color: #000;
  z-index: 9;
  opacity: 0;
  pointer-events: none;
  transition: opacity 250ms ease-in-out;

  &.visible {
    opacity: 1;
  }

  @media (max-width: 900px) {
    display: none;
  }
}

.case-stevia-sweet {
  .header-section {
    .second-container {
      h3 {
        white-space: nowrap !important;
      }
    }
  }
}
</style>
