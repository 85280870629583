<template>
  <div class="contact-overlay" :class="{ visible: open }">
    <div class="close" @click="close">
      <div>
        <span></span>
        <span></span>
      </div>
    </div>
    <div class="content">
      <h2>Uns Kennenlernen?<br />Wir Antworten.</h2>
      <form v-if="!isSubmitted" @submit="submit" autocomplete="on">
        <div class="error" v-if="errorMsg">{{ errorMsg }}</div>
        <div class="row col-2">
          <input type="text" name="vorname" v-model="msg.vorname" placeholder="Vorname*" required />
          <input type="text" name="nachname" v-model="msg.nachname" placeholder="Nachname*" required />
        </div>
        <div class="row col-2">
          <input type="text" name="firma" v-model="msg.firma" placeholder="Firma" />
          <input type="email" name="email" autocomplete="email" v-model="msg.email" placeholder="E-Mail*" required />
        </div>
        <div class="row">
          <textarea placeholder="Nachricht" v-model="msg.nachricht"></textarea>
          <div class="submit-wrapper">
            <input type="submit" name="submit" value="" />
          </div>
        </div>
        <div class="row">
          <p class="adresse">Die Antwort AG | Bertastrasse 1 | 8003 Zürich | Tel.+41 43 305 00 99</p>
        </div>
      </form>
      <div v-if="isSubmitted" class="thankyou">
        <p>Danke für die Anfrage. Wir melden uns bei dir.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'contact-overlay',
  data() {
    return {
      msg: {
        vorname: '',
        nachname: '',
        firma: '',
        email: '',
        nachricht: '',
      },
      isSubmitted: false,
      errorMsg: false,
    }
  },
  computed: {
    open() {
      return this.$store.getters.isContactOverlayVisible
    },
  },
  methods: {
    close() {
      this.$store.dispatch('hideContactOverlay')
    },
    submit(e) {
      e.preventDefault()

      this.errorMsg = false
      const body = JSON.stringify({ msg: this.msg })
      fetch('/static/mail/contact.php', {
        method: 'post',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
          'Content-Length': body.length.toString(),
        },
        body,
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.status == 'failed') {
            this.handleError()
          } else {
            this.isSubmitted = true
          }
        })
        .catch(this.handleError)
    },
    handleError(err = 'keine Fehlermeldung') {
      this.errorMsg = 'Leider gab es einen Fehler.'
      console.log('Fehlermeldeung: ' + err)
    },
  },
  mounted() {
    $('textarea').on('input', function () {
      $(this).height(35).height(this.scrollHeight)
    })
  },
}
</script>

<style lang="scss">
.contact-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #000;
  z-index: 15;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  transform: scaleX(1.1) scaleY(1.1);
  transition: 150ms all ease-in-out;

  .close {
    position: absolute;
    left: calc(100vw - 110px);
    top: 60px;
    width: 40px;
    height: 40px;
    cursor: pointer;

    > div {
      transform: translateY(17px);
    }

    span {
      display: block;
      position: absolute;
      height: 5px;
      width: 40px;
      border-radius: 2px;
      opacity: 1;
      left: 0;
      background-color: #fff;

      &:nth-child(1) {
        transform: rotate(45deg);
      }

      &:nth-child(2) {
        transform: rotate(-45deg);
      }
    }

    @media (max-width: 900px) {
      left: calc(100vw - 60px);
      top: 20px;
    }
  }

  &.visible {
    opacity: 1;
    pointer-events: auto;
    transform: scaleX(1) scaleY(1);
  }

  .content {
    width: 740px;
    max-width: 100%;
    max-height: 90vh;
    overflow-y: auto;
    padding: 0 20px;

    h2 {
      color: #fff;
      font-size: 60px;
      margin-top: 0;
      padding: 0 7px;

      @media (max-width: 700px) {
        font-size: 8vw;
      }
    }

    .row {
      display: grid;
      position: relative;

      &.col-2 {
        grid-template-columns: 1fr 1fr;

        @media (max-width: 577px) {
          grid-template-columns: 1fr;
        }
      }
    }

    .error {
      color: red;
      padding-bottom: 30px;
      padding-left: 7px;
    }

    .thankyou {
      color: #fff;
    }

    input[type='text'],
    input[type='email'],
    textarea {
      background: transparent;
      color: #fff;
      border: none;
      border-bottom: 2px solid #fff;
      font-size: 18px;
      padding: 5px;
      outline: none;
      width: calc(100% - 30px);
      box-sizing: border-box;
      margin: 10px 7px;
      font-family: Favorit, sans-serif;
      border-radius: 0;

      &::placeholder {
        color: #ccc;
      }
    }

    textarea {
      width: calc(100% - 37px);
      resize: none;
      height: 35px;
    }

    .submit-wrapper {
      position: absolute;
      right: 26px;
      bottom: 20px;
      height: 24px;
      width: 24px;

      input[type='submit'] {
        border: none;
        height: 24px;
        width: 24px;
        padding: none;
        cursor: pointer;
        background: transparent;
        outline: none;
      }

      &::before {
        content: '';
        background-image: url('~assets/theme_icons/arrow-small-right-weiss.svg');
        height: 15px;
        width: 18px;
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        pointer-events: none;
        right: 3px;
        top: 4px;
        transition: 150ms all ease-in-out;
      }

      &:hover {
        &::before {
          transform: translateX(5px);
        }
      }
    }

    .adresse {
      color: #fff;
      margin-top: 0;
      padding: 5px 12px;
      font-size: 12px;
    }
  }
}
</style>
