<template>
  <div>
    <case-header class="header-section--munz" :backgroundvid="headerBackgroundImgUrl" :headerImgUrl="headerImgUrl">
    <h1 slot="h1-title" id="top-headline">Munz</h1>
    <h3 slot="h3-title">Munz</h3>
    <div slot="details">
        <p>
        <strong>?</strong>Wir haben uns schon immer eine Schokoladenmarke für unser Portfolio gewünscht. Als die neuen Munz Prügeli «Extra» einen 
        schweizweiten Auftritt brauchten, standen wir an vorderster Front in der Pitch-Schlange und freuten uns entsprechend lautstark über das Mandat.
        Die «Gemeinsam ist besser»-Kampagne sollte abgelöst werden. Eine Innovation kam auf den Markt. Der Auftritt sollte die Neuheit laut und deutlich 
        ankündigen und die Produktvorteile in Szene setzen. Denn die neuen Prügeli sind kompromisslos nachhaltig. Sie werden Co2-neutral und mit 
        Fair-Trade-Kakao hergestellt – ohne Palmöl oder Soja. Die einzigartige Rezeptur mit dem cremigen Kern ist zudem ein innovatives Ergebnis 
        jahrelanger Forschung. Bei der Kampagne sollte der Genuss weiterhin im Vordergrund stehen, während die Einzigartigkeit klar kommuniziert wird.
        </p>
    </div>
    </case-header>

    <div v-show="!caseIsLoading" id="case-content">
    <grund-gedanke>
        <h3 slot="title">Genuss, <br />Innovation <br />und <br />Nachhaltigkeit</h3>
        <p slot="text">
        <strong>!</strong>Für die crossmediale schweizweite Kampagne erarbeiteten wir ein breites Spektrum an Ansätzen für die Darstellung und Botschaften 
        des neuen Sujets. Es sollte auf Plakaten genauso gut funktionieren wie in digitaler und animierter Form. Schliesslich entschieden wir uns für 
        die rockige Variante und kreierten ein lautes Sujet, das nach Genuss schreit.
        <br /><br />
        <strong>=</strong>Das Sujet ist auf hohen Zuspruch und durchgehend positive Resonanzen gestossen. Wir freuen uns, für einen weiteren Wunsch-Brand 
        tätig zu sein und auch in Zukunft sein zu dürfen.
        </p>
    </grund-gedanke>
    
    <parallax-glider :imgurl="require('./../assets/case-studies/munz/Munz_Case_Visual_Hoch.jpg')" :triggerHook="0.6"/>

    <section class="slider-section slider-section-1">
        <carousel
        :paginationEnabled="false"
        navigationPrevLabel="<span class='arrow'><span>"
        navigationNextLabel="<span class='arrow'><span>"
        class="small-navigation"
        :perPage="1"
        :navigationEnabled="true"
        :loop="true"
        >
        <slide v-for="slideIndex in 3" :key="slideIndex">
            <img :src="require(`./../assets/case-studies/munz/plakate/Munz_Case_a_1920x1080_${slideIndex}.jpg`)" />
        </slide>
        </carousel>
    </section>

    <section class="slider-section slider-section-1">
        <carousel
        :paginationEnabled="false"
        navigationPrevLabel="<span class='arrow'><span>"
        navigationNextLabel="<span class='arrow'><span>"
        class="small-navigation"
        :perPage="1"
        :navigationEnabled="true"
        :loop="true"
        >
        <slide v-for="slideIndex in 3" :key="slideIndex">
            <img :src="require(`./../assets/case-studies/munz/plakate/Munz_Case_b_1920x1080_${slideIndex}.jpg`)" />
        </slide>
        </carousel>
    </section>

    <section class="slider-section slider-section-1 avoid-thin-line">
        <carousel
        :paginationEnabled="false"
        navigationPrevLabel="<span class='arrow'><span>"
        navigationNextLabel="<span class='arrow'><span>"
        class="small-navigation"
        :perPage="1"
        :navigationEnabled="true"
        :loop="true"
        >
        <slide v-for="slideIndex in 4" :key="slideIndex">
            <img :src="require(`./../assets/case-studies/munz/plakate/Munz_Case_c_1920x1080_${slideIndex}.jpg`)" />
        </slide>
        </carousel>
    </section>

    <section class="img-section-a avoid-thin-line">
        <img src="./../assets/case-studies/munz/Munz_Case_1920x1080_CDManual.jpg" />
    </section>

    <section class="avoid-thin-line">
        <video :src="require('./../assets/case-studies/munz/videos/Case_Munz_Bewegtbild.mp4')" muted playsinline loop autoplay></video>
    </section>

    <section class="section---stadelhofen-video img-section-a">
        <video-player 
        :videourl="require('./../assets/case-studies/munz/videos/02_Munz_Stadelhofen_Feinschnitt_HD.mp4')"
        :poster="require('./../assets/case-studies/munz/videos/02_Munz_Stadelhofen_Feinschnitt_HD.jpg')" />
    </section>

    <case-footer></case-footer>
    </div>
  </div>
</template>
  
<script>
import CaseHeader from '../components/Case-Header.vue'
import CaseFooter from '../components/Case-Footer.vue'
import Grundgedanke from '../components/Grundgedanke.vue'
import CounterFooter from '../components/Counter-Footer.vue'
import VideoPlayer from '../components/Video-Player.vue'
import CaseLoadStatusMixin from '../mixins/case-load-status'
import VideoOnScroll from '../components/VideoOnScroll.vue'
import ParallaxGlider from '../components/Parallax-Glider.vue'

export default {
  name: 'munz',
  components: {
    'case-header': CaseHeader,
    'case-footer': CaseFooter,
    'grund-gedanke': Grundgedanke,
    'video-player': VideoPlayer,
    'counter-footer': CounterFooter,
    'video-on-scroll': VideoOnScroll,
    'parallax-glider': ParallaxGlider,
  },
  mixins: [CaseLoadStatusMixin],
  data() {
    return {
      headerBackgroundImgUrl: require('./../assets/case-studies/munz/header.jpg'),
      headerImgUrl: require('./../assets/case-studies/munz/main.jpg'),
      windowWidth: window.innerWidth,
    }
  },
  computed: {
    imgRes() {
      return this.windowWidth <= 577 ? '-low' : ''
    },
    useMobileVideos() {
      return this.windowWidth <= 577
    },
  },
  methods: {
    setWindowWidth() {
      this.windowWidth = window.innerWidth
    },
  },
  mounted() {
    window.addEventListener('resize', this.setWindowWidth.bind(this))

    this.$nextTick(() => {
      this.initilizeLoad(() => {
        this.$emit('initilizeLoaded')
      })
    })
  },
  beforeDestroy() {
    this.$store.commit('removeScrollMagicScenes', 'munz')
    window.removeEventListener('resize', this.setWindowWidth.bind(this))
  },
}
</script>
  
<style lang="scss">
@import '../assets/scss/vars';

@media (max-width: 577px) {
  .header-section--munz {
    #top-headline {
      font-size: 8vw;
    }
  }
}

.section--video-row {
  display: flex;

  > .video-section {
    flex: 1 1 0px;
  }

  &-smartphones-green {
    @media (min-width: 1025px) {
      background-color: #dcff55; // $green;
      padding: 0 15%;
    }
  }

  @media (max-width: 1024px) {
    flex-wrap: wrap;

    > .video-section {
      flex-basis: 50%;
      min-width: 50%;
    }
  }

  @media (max-width: 600px) {
    > .video-section {
      flex-basis: 100%;
      min-width: 100%;
    }
  }
}

.avoid-thin-line {
  margin-top: -2px;
}
</style>