<template>
  <div>
    <case-header :backgroundvid="headerBackgroundVideoUrl" :headerImgUrl="headerImgUrl">
      <h1 slot="h1-title" id="top-headline">Lunge Zürich</h1>
      <h3 slot="h3-title">Lunge Zürich</h3>
      <div slot="details">
        <p>
          <strong>?</strong>Wie mache ich die Asthma-Kranken in der Schweiz wirksam darauf aufmerksam, dass quasi jeder Dritte sein Asthma-Spray falsch
          anwendet? Dabei ist Erfahrung der beste Koch. Schliesslich arbeiten wir seit 2005 für die Non-Profit-Organisation Verein Lunge Zürich, eine von 20
          kantonalen Lungenligen, die dem Verein Lungenliga Schweiz angehört. Mehrere COPD- und Rauchstopp-Kampagnen von uns waren seitdem strategisch richtig,
          differenzierend und wirkungsvoll.
        </p>
        <div class="tags">
          <p>B2C Campaign | B2B Campaign | Corporate Design | Logo | TV-Commercial | Billboards | Video | Digital Ads | Guerilla Marketing</p>
        </div>
      </div>
    </case-header>

    <div v-show="!caseIsLoading" id="case-content">
      <parallax-glider :imgurl="require('./../assets/case-studies/lungezuerich/' + imgRes + 'parallax-img.jpg')"></parallax-glider>

      <grund-gedanke>
        <h3 slot="title">Lunge Zürich</h3>
        <p slot="text">
          <strong>!</strong>Wir übertreiben die Falschanwendung des Asthma-Sprays. Nein, es ist kein Deo und die Frisur hält auch nicht besser. Mit dieser
          Dramatik überraschten wir die Zielgruppe Off- genauso wie Online. Um sie nicht im Sprayregen stehen zu lassen, ergänzten wir die Kampagne mit
          Schulungsfilmen und Infomaterialien bei Hausärzten, damit wir über die richtige Nutzung aufklären konnten. Schliesslich ist 60 % Falschnutzung
          unglaublich viel, so dass dringend eine hochwirksame Kommunikation von Nöten war. <br /><br />
          <strong>=</strong>Die Kampagne war ein voller Erfolg, denn wir konnten ein Tabuthema zum Gesprächsthema machen, das bis zu diesem Zeitpunkt
          unterschätzt wurde. Mit dieser Kampagne haben wir wertvolle Aufklärungsarbeit geleistet, indem wir auf sympathische Art und Weise die – eigentlich
          banale – Nutzung eines einfachen Asthma-Sprays dramatisierten. Und woran wir im Traum nicht gedacht haben: aus einer Zürcher Kampagne wurde eine
          Schweizerische Kampagne, denn viele weitere Lungenligen übernahmen unsere Kampagne in ihre Komm-Planung.
        </p>
      </grund-gedanke>

      <video-player :videourl="require('./../assets/case-studies/lungezuerich/lunge.mp4')" :poster="videoPoster1"></video-player>

      <section class="slider-section slider-section-1 black-icons">
        <carousel :perPage="1" :navigationEnabled="true" :loop="true">
          <slide>
            <img :src="require('./../assets/case-studies/lungezuerich/slider1/' + imgRes + '01.jpg')" />
          </slide>
          <slide>
            <img :src="require('./../assets/case-studies/lungezuerich/slider1/' + imgRes + '02.jpg')" />
          </slide>
          <slide>
            <img :src="require('./../assets/case-studies/lungezuerich/slider1/' + imgRes + '03.jpg')" />
          </slide>
          <slide>
            <img :src="require('./../assets/case-studies/lungezuerich/slider1/' + imgRes + '04.jpg')" />
          </slide>
          <slide>
            <img :src="require('./../assets/case-studies/lungezuerich/slider1/' + imgRes + '05.jpg')" />
          </slide>
        </carousel>
      </section>

      <section class="slider-section slider-section-2">
        <carousel :perPage="1" :navigationEnabled="true" :loop="true">
          <slide>
            <img :src="require('./../assets/case-studies/lungezuerich/slider2/' + imgRes + '01.jpg')" />
          </slide>
          <slide>
            <img :src="require('./../assets/case-studies/lungezuerich/slider2/' + imgRes + '02.jpg')" />
          </slide>
        </carousel>
      </section>

      <counter-footer :counters="footerCounters"></counter-footer>

      <case-footer></case-footer>
    </div>
  </div>
</template>

<script>
import CaseHeader from '../components/Case-Header.vue'
import CaseFooter from '../components/Case-Footer.vue'
import Grundgedanke from '../components/Grundgedanke.vue'
import ParallaxGlider from '../components/Parallax-Glider.vue'
import CounterFooter from '../components/Counter-Footer.vue'
import VideoPlayer from '../components/Video-Player.vue'
import CaseLoadStatusMixin from '../mixins/case-load-status'

export default {
  name: 'lungezuerisch',
  components: {
    'case-header': CaseHeader,
    'case-footer': CaseFooter,
    'grund-gedanke': Grundgedanke,
    'parallax-glider': ParallaxGlider,
    'counter-footer': CounterFooter,
    'video-player': VideoPlayer,
  },
  mixins: [CaseLoadStatusMixin],
  data() {
    return {
      headerBackgroundVideoUrl: require('./../assets/case-studies/lungezuerich/header.mp4'),
      headerImgUrl: require('./../assets/case-studies/lungezuerich/main.jpg'),
      footerCounters: [
        {
          score: 0,
          max: 5,
          title: '5 COPD- und Stop-Rauchen- Kampagnen realisiert.',
        },
        {
          score: 0,
          max: 4,
          title: '4 Mitarbeitende überzeugt, mit dem Rauchen aufzuhören.',
        },
        {
          score: 0,
          max: 100169,
          title: '100169 Lungenfunktions-Messungen im LuftiBus durchgeführt.',
        },
      ],
    }
  },
  computed: {
    isU577() {
      return this.$store.getters.isU577
    },
    videoPoster1() {
      return require('./../assets/case-studies/lungezuerich/video-poster-1.jpg')
    },
    imgRes() {
      return window.innerWidth <= 577 ? 'low-' : ''
    },
  },
  methods: {},
  mounted() {
    this.$nextTick(() => {
      this.initilizeLoad(() => {})
    })
  },
  beforeDestroy() {
    this.$store.commit('removeScrollMagicScenes', 'lungezuerisch')
  },
}
</script>

<style lang="scss" scoped></style>
