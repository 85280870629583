<template>
  <div>
    <case-header :backgroundvid="headerBackgroundVideoUrl" :headerImgUrl="headerImgUrl">
      <h1 slot="h1-title" id="top-headline">Nebelspalter</h1>
      <h3 slot="h3-title">Nebelspalter</h3>
      <div slot="details">
        <p>
          <strong>?</strong>Der Nebelspalter ist heute das älteste Satiremagazin der Welt und hat sich seit der Ersterscheinung im Jahre 1875 stetig
          weiterentwickelt. Mit der neuen Inhaberin, der Klarsicht AG unter Markus Somm, soll nun ein Online-Portal lanciert werden, welches die liberalen Werte
          der Gesellschaft vertritt und bewusst kritisch sowie auch differenziert vom üblichen Mainstream Qualitäts-Journalismus betreibt. Nachdem wir uns in
          einem Pitch-Verfahren durchgesetzt haben, geht es nun darum, dieses Portal bekannt zu machen und zahlende Abonnenten zu begeistern.
        </p>
        <div class="tags">
          <p>Creative Campaigning | Plakat | Anzeigen | Display Ads – 4-stufig | Social Ads – 4-stufig | Guerilla | Event | Logo | Landingpage</p>
        </div>
      </div>
    </case-header>

    <div v-show="!caseIsLoading" id="case-content">
      <video-player
        :videourl="require('./../assets/case-studies/nebelspalter/videos/case.mp4')"
        :poster="require('./../assets/case-studies/nebelspalter/videos/poster-case.jpg')"
      >
      </video-player>

      <grund-gedanke>
        <h3 slot="title">Nebelspalter</h3>
        <p slot="text">
          <strong>?</strong>Den unsichtbaren Nebel zu spalten, der täglich in den Medien und in der Politik verbreitet wird, stellt eine grosse Herausforderung
          dar. Ihn für eine Kampagne zu visualisieren, auch. Deshalb haben wir den unsichtbaren Nebel bereits vor der Lancierung des neuen Nebelspalters
          sichtbar gemacht und in einer anonymen Teaser-Kampagne Menschen gezeigt, die sich täglich vernebeln lassen. Online, Analog sowie auf der Strasse. Ohne
          Logo. Ohne Absender. Nur die frohe Botschaft: «Die Tage des Nebels sind gezählt.» Dabei fingen wir bereits an, Daten zu sammeln bei den Leserinnen und
          Lesern der nzz.ch und weltwoche.ch. Die Auflösung zum Launch des neuen Nebelspalters am 18. März 2021 erfolgte dann mit Markus Somm und Meta
          Hiltebrand – und einem Foodtruck, aus dem sie gemeinsam Nebelsuppe verteilten. <br /><br />
          Damit wurde die Kampagne schliesslich lanciert und unser bewährter Funnel kam zum Einsatz. In einem ersten Schritt generierten wir Aufmerksamkeit mit
          klassischer Image-Arbeit. Aufgrund der bereits gesammelten Daten bildeten wir sogenannte Similar-Audience-Gruppen und erweiterten so unsere Reichweite
          mit den Adressstämmen. In der Folge bedienten wir die anvisierten ZIelgruppen im Storytelling-Ablauf dezent mit den Argumenten für ein
          Nebelspalter-Abonnement.
          <br /><br />
          <strong>=</strong>Der politisch liberal aufgestellte Nebelspalter wird natürlich nicht überall mit offenen Armen empfangen. Und auch Markus Somm sieht
          sich einer relativ starken Polarisierung bei der Schweizer Bevölkerung ausgesetzt. Bei knapp 2 Millionen Impressionen können wir jedoch mit Recht
          behaupten, dass der Nebelspalter definitiv wieder im Gespräch ist und die Wiedergeburt landesweit wahrgenommen wurde.
        </p>
      </grund-gedanke>

      <section class="nebelspalter-logomorph black-icons">
        <video-on-scroll :loop="true" :videoSrc="require('./../assets/case-studies/nebelspalter/videos/logomorph_2.mp4')" />
      </section>

      <video-columns :uid="'video-columns-nebelspalter'" :columns="4" :videos="VideoColumnsVideos"></video-columns>

      <section class="slider-section slider-section-1">
        <carousel
          :paginationEnabled="false"
          navigationPrevLabel="<span class='arrow'><span>"
          navigationNextLabel="<span class='arrow'><span>"
          class="small-navigation"
          :perPage="1"
          :navigationEnabled="true"
          :loop="true"
        >
          <slide>
            <video-player
              :videourl="require('./../assets/case-studies/nebelspalter/videos/banner/001.mp4')"
              :poster="require('./../assets/case-studies/nebelspalter/videos/banner/001.jpg')"
            >
            </video-player>
          </slide>
          <slide>
            <video-player
              :videourl="require('./../assets/case-studies/nebelspalter/videos/banner/002.mp4')"
              :poster="require('./../assets/case-studies/nebelspalter/videos/banner/002.jpg')"
            >
            </video-player>
          </slide>
          <slide>
            <video-player
              :videourl="require('./../assets/case-studies/nebelspalter/videos/banner/003.mp4')"
              :poster="require('./../assets/case-studies/nebelspalter/videos/banner/003.jpg')"
            >
            </video-player>
          </slide>
        </carousel>
      </section>

      <section class="nebelspalter-iphones black-icons">
        <video-on-scroll :videoSrc="require('./../assets/case-studies/nebelspalter/videos/iphones.mp4')" />
      </section>

      <counter-footer :counters="footerCounters"></counter-footer>

      <case-footer></case-footer>
    </div>
  </div>
</template>

<script>
import CaseHeader from '../components/Case-Header.vue'
import CaseFooter from '../components/Case-Footer.vue'
import Grundgedanke from '../components/Grundgedanke.vue'
import CounterFooter from '../components/Counter-Footer.vue'
import VideoPlayer from '../components/Video-Player.vue'
import CaseLoadStatusMixin from '../mixins/case-load-status'
import VideoOnScroll from '../components/VideoOnScroll.vue'
import VideoColumns from '../components/Video-Columns.vue'

export default {
  name: 'nebelspalter',
  components: {
    'case-header': CaseHeader,
    'case-footer': CaseFooter,
    'grund-gedanke': Grundgedanke,
    'video-player': VideoPlayer,
    'counter-footer': CounterFooter,
    'video-on-scroll': VideoOnScroll,
    'video-columns': VideoColumns,
  },
  mixins: [CaseLoadStatusMixin],
  data() {
    return {
      headerBackgroundVideoUrl: require('./../assets/case-studies/nebelspalter/header.mp4'),
      headerImgUrl: require('./../assets/case-studies/nebelspalter/main.jpg'),
      footerCounters: [
        {
          score: 0,
          max: 36,
          title: '36 % Zielerreichung bereits 10 Tage nach Kampagnenstart.',
        },
        {
          score: 0,
          max: 5.9,
          title: 'Jeder potentielle Abonnent wurde 5.9x mittels Display erreicht.',
        },
        {
          score: 0,
          max: 6,
          title: '36% der Zielgruppe erinnerte sich an die Hauptbotschaft der Kampagne',
        },
      ],
      VideoColumnsVideos: [
        {
          full: require('./../assets/case-studies/nebelspalter/videos/video-columns/full-01.mp4'),
          fullPoster: require('./../assets/case-studies/nebelspalter/videos/video-columns/full-poster-01.jpg'),
          prev: require('./../assets/case-studies/nebelspalter/videos/video-columns/prev-01.mp4'),
          prevPoster: require('./../assets/case-studies/nebelspalter/videos/video-columns/prev-poster-01.jpg'),
        },
        {
          full: require('./../assets/case-studies/nebelspalter/videos/video-columns/full-02.mp4'),
          fullPoster: require('./../assets/case-studies/nebelspalter/videos/video-columns/full-poster-02.jpg'),
          prev: require('./../assets/case-studies/nebelspalter/videos/video-columns/prev-02.mp4'),
          prevPoster: require('./../assets/case-studies/nebelspalter/videos/video-columns/prev-poster-02.jpg'),
        },
        {
          full: require('./../assets/case-studies/nebelspalter/videos/video-columns/full-03.mp4'),
          fullPoster: require('./../assets/case-studies/nebelspalter/videos/video-columns/full-poster-03.jpg'),
          prev: require('./../assets/case-studies/nebelspalter/videos/video-columns/prev-03.mp4'),
          prevPoster: require('./../assets/case-studies/nebelspalter/videos/video-columns/prev-poster-03.jpg'),
        },
        {
          full: require('./../assets/case-studies/nebelspalter/videos/video-columns/full-04.mp4'),
          fullPoster: require('./../assets/case-studies/nebelspalter/videos/video-columns/full-poster-04.jpg'),
          prev: require('./../assets/case-studies/nebelspalter/videos/video-columns/prev-04.mp4'),
          prevPoster: require('./../assets/case-studies/nebelspalter/videos/video-columns/prev-poster-04.jpg'),
        },
      ],
    }
  },
  methods: {},
  mounted() {
    this.$nextTick(() => {
      this.initilizeLoad(() => {
        this.$emit('initilizeLoaded')
      })
    })
  },
  beforeDestroy() {
    this.$store.commit('removeScrollMagicScenes', 'nebelspalter')
  },
}
</script>

<style lang="scss">
.nebelspalter-logomorph {
  .video-on-scroll {
    width: 1001px;
    max-width: 100%;
    margin: 0 auto;
  }
}

.nebelspalter-iphones {
  background-color: #e1e0e1;
  padding-top: 90px;
  padding-bottom: 90px;

  .video-on-scroll {
    width: 100%;
    margin: 0 auto;
  }
}
</style>
