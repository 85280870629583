<template>
  <section class="video-section">
    <img class="video-icon" v-if="simple && !isTouch" src="~assets/theme_icons/play.svg" />
    <video
      :id="videoid"
      @loadedmetadata="setMaxAttribute"
      @timeupdate="updateTimeLine"
      :poster="poster"
      class="video-player-video"
      :class="{ playing: isPlaying }"
      @click="toggleVideoPlayState"
      :loop="simple"
      :controls="isTouch"
    >
      <source :src="videourl" type="video/mp4" />
    </video>
    <div v-if="!simple && !isTouch" class="progress" :class="{ active: isStarted }">
      <progress
        :id="progressid"
        @mousemove="handleMouseMoveOnTimeline"
        @mousedown="handleMouseDonwOnTimeline"
        @mouseup="handleMouseUpOnTimeline"
        value="0"
        min="0"
      ></progress>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    videourl: {},
    videoMobileUrl: {},
    poster: {},
    simple: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isPlaying: false,
      isStarted: false,
      isHoldOnTimeline: false,
      TimelineInProcess: false,
      videoid: 'video' + Math.round(Math.random() * 100000),
      progressid: 'progress' + Math.round(Math.random() * 100000),
    }
  },
  computed: {
    isTouch() {
      return this.$store.getters.isTouch
    },
  },
  methods: {
    toggleVideoPlayState() {
      this.isStarted = true
      this.isPlaying = !this.isPlaying
      this.isPlaying ? this.video.play() : this.video.pause()
    },
    updateTimeLine() {
      if (!this.simple && !this.isTouch) {
        this.progress.value = this.video.currentTime
        if (this.video.currentTime == this.progress.max) {
          this.isPlaying = false
        }
      }
    },
    handleMouseMoveOnTimeline(e) {
      if (this.isHoldOnTimeline && !this.TimelineInProcess) {
        this.TimelineInProcess = true
        setTimeout(() => {
          this.goToInTimeline(e)
          this.TimelineInProcess = false
        }, 80)
      }
    },
    handleMouseDonwOnTimeline(e) {
      this.goToInTimeline(e)
      this.isHoldOnTimeline = true
    },
    handleMouseUpOnTimeline(e) {
      this.isHoldOnTimeline = false
    },
    goToInTimeline(e) {
      let elementOffset = e.layerX
      let elementWidth = this.progress.offsetWidth
      let percent = (100 / elementWidth) * elementOffset
      this.video.currentTime = (this.progress.max / 100) * percent
    },
    setMaxAttribute() {
      if (!this.simple && !this.isTouch) {
        this.progress.setAttribute('max', this.video.duration)
      }
    },
    resetVideo() {
      if (this.isPlaying) {
        this.toggleVideoPlayState()
      }
      if (this.isStarted) {
        this.video.currentTime = 0
      }
    },
  },
  mounted() {
    this.video = document.getElementById(this.videoid)
    if (!this.isTouch) {
      this.progress = document.getElementById(this.progressid)
    }

    // wenn das Carousel sich ändert, dass dann das Video resetted wird
    this.$parent.$on('carouselPageChange', (index) => {
      this.resetVideo()
    })
  },
}
</script>

<style lang="scss" scoped>
@import '~assets/scss/vars';

.video-section {
  position: relative;

  video {
    cursor: url('~assets/theme_icons/play.png') 20 20, auto;

    &.playing {
      cursor: url('~assets/theme_icons/pause.png') 20 20, auto;
    }
  }

  .video-icon {
    position: absolute;
    width: 50px;
    right: 15px;
    top: 15px;
    pointer-events: none;
  }

  .progress {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    pointer-events: none;

    &.active {
      pointer-events: auto;
    }

    progress {
      &[value] {
        display: block;
        width: 100%;
        border: none;
        overflow: hidden;
        appearance: none;
        background: transparent;
      }

      &::-webkit-progress-bar {
        background: transparent;
      }

      &::-webkit-progress-value {
        background-color: $green;
      }

      &::-moz-progress-bar {
        background-color: $green;
      }
    }
  }
}
</style>
