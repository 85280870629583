<template>
  <section class="section-newsletter-signup black-icons">
    <h3>Noch Fragen? Erhalten Sie regelmässig Antworten in unseren Newsletter</h3>
    <newsletter-signup-form></newsletter-signup-form>
    <div class="button-wrapper">
      <button-custom color="black" class="white-text-color" :align="'center'" link="mailto:frage@dieantwort.com">Pitch-Anfrage</button-custom>
      <button-custom color="black" :align="'center'" link="mailto:frage@dieantwort.com">Quick Briefing</button-custom>
    </div>

    <div class="section-beziehungsprobleme">
      <h3>Beziehungsprobleme mit der aktuellen Agentur?<br />Ist es kompliziert?<br /><br />Wir machen Schluss für Sie!</h3>
      <button-custom
        color="black"
        :align="'center'"
        class="schlussmachen-button"
        download="Kuendigungsschreiben-fuer-Agentur.doc"
        link="/static/word/Kuendigungsschreiben-fuer-Agentur.doc"
        >Schlussmachvorlage</button-custom
      >
    </div>
  </section>
</template>

<script>
import ButtonCustom from './ButtonCustom'
import NewsletterSignupForm from './Newsletter-Signup-Form'

export default {
  name: 'Newsletter-Signup',
  components: {
    'button-custom': ButtonCustom,
    'newsletter-signup-form': NewsletterSignupForm,
  },
}
</script>

<style lang="scss">
@import '~assets/scss/vars';

.schlussmachen-button {
  margin-top: 30px;
}

.section-newsletter-signup {
  padding: 120px 30px;
  text-align: center;

  .button-wrapper {
    max-width: 600px;
    margin: 50px auto 0;

    .button {
      margin-bottom: 30px;
    }

    @media (min-width: 600px) {
      display: flex;
      justify-content: space-between;
    }

    .white-text-color a {
      color: #fff;
    }
  }

  .section-beziehungsprobleme {
    background-color: $green;
    padding-top: 45px;
    padding-bottom: 45px;
    margin-top: 90px;

    @media (min-width: 560px) {
      margin-right: 60px;
      margin-left: 60px;
    }
  }
}
</style>
