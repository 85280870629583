<template>
  <div v-show="parentContainer.pageCount > 1" class="VueCarousel-pagination" :id="paginationWrapperId">
    <div class="VueCarousel-dot-container" ref="container" @mouseleave="handleMouseLeaveDotsContainer">
      <div class="VueCarousel-dot-selector" ref="dotSelector">
        <div class="VueCarousel-dot-inner"></div>
      </div>
      <div
        class="VueCarousel-dot"
        :class="{
          'VueCarousel-dot--active': index === parentContainer.currentPage,
        }"
        :data-index="index"
        v-for="(page, index) in parentContainer.pageCount"
        @click="parentContainer.goToPage(index)"
        @mouseover="handleDotsMouseover"
      >
        <div class="VueCarousel-dot-inner"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'pagination',
  props: ['paginationWrapperId'],
  data() {
    return {
      parentContainer: this.$parent,
      isSimple: this.$parent.simple,
    }
  },
  computed: {
    isU577() {
      return this.$store.getters.isU577
    },
  },
  methods: {
    handleDotsMouseover(e) {
      let target = e.target
      let className = target.className
      if (className == 'VueCarousel-dot-inner') {
        target = e.target.parentElement
      }
      this.moveToDot(target)
    },
    handleMouseLeaveDotsContainer(target) {
      this.goToActiveDot()
    },
    goToActiveDot() {
      let activeDot = this.pageinationContainer.querySelector('.VueCarousel-dot--active')
      this.moveToDot(activeDot)
    },
    moveToDot(target, index = undefined) {
      let dotIndex = index == undefined ? parseInt(target.getAttribute('data-index')) : index
      let dotHeight = 21
      let dest = 0 + dotHeight * dotIndex
      let curPostion = this.getSelectorPosition()
      if (dest == curPostion) {
        return false
      }
      if (!this.isSimple) {
        var TL = new TimelineLite()
          .to(this.selector, 0.15, {
            y: dest,
            scaleX: 0.5,
            scaleY: 1.3,
            ease: Back.easeIn,
          })
          .to(
            this.selector,
            0.15,
            {
              onUpdate: this.applyBlur,
              onUpdateParams: ['{self}', 0, 1],
            },
            0
          )
          .to(this.selector, 0.25, {
            y: dest,
            scaleX: 1,
            scaleY: 1,
            ease: Back.easeOut,
          })
          .to(
            this.selector,
            0.25,
            {
              onUpdate: this.applyBlur,
              onUpdateParams: ['{self}', 1, 0],
            },
            0.15
          )
      } else {
        var pos = dest
        var TL = new TimelineLite()
          .to(this.selector, 0.15, {
            x: pos,
            scaleX: 0.5,
            scaleY: 1.3,
            ease: Back.easeIn,
          })
          .to(
            this.selector,
            0.15,
            {
              onUpdate: this.applyBlur,
              onUpdateParams: ['{self}', 0, 1],
            },
            0
          )
          .to(this.selector, 0.25, {
            x: pos,
            scaleX: 1,
            scaleY: 1,
            ease: Back.easeOut,
          })
          .to(
            this.selector,
            0.25,
            {
              onUpdate: this.applyBlur,
              onUpdateParams: ['{self}', 1, 0],
            },
            0.15
          )
      }
    },
    applyBlur(tl, start, end) {
      var tlp = (tl.progress() * 100) >> 0
      if (start < end) {
        var inc = start + (Math.abs(start - end) / 100) * tlp
      } else {
        var inc = start - (Math.abs(start - end) / 100) * tlp
      }
      TweenMax.set(tl.target, {
        '-webkit-filter': 'blur(' + inc + 'px)',
        filter: 'blur(' + inc + 'px)',
      })
    },
    getSelectorPosition() {
      var el = this.selector
      var st = window.getComputedStyle(el, null)
      var tr =
        st.getPropertyValue('-webkit-transform') ||
        st.getPropertyValue('-moz-transform') ||
        st.getPropertyValue('-ms-transform') ||
        st.getPropertyValue('-o-transform') ||
        st.getPropertyValue('transform') ||
        'FAIL'
      var values = tr.split('(')[1].split(')')[0].split(',')
      return this.isSimple ? parseInt(values[4]) : parseInt(values[5])
    },
    handlePageChangeEvent(index) {
      this.moveToDot(false, index)
    },
  },
  created() {
    this.$parent.$on('pageChange', this.handlePageChangeEvent)
  },
  mounted() {
    this.selector = this.$refs.dotSelector
    this.pageinationContainer = this.$refs.container
    this.$emit('paginationloaded')
  },
}
</script>

<style lang="scss" scoped>
@import '~assets/scss/vars';

.VueCarousel-pagination {
  position: absolute;
  left: 0;
  width: 32px;
  height: auto;
  padding: 30px 0;
  background: $green;
  top: 0;
  margin: 0;
  z-index: 2;

  @media (max-width: 577px) {
    top: 50%;
    transform: translateY(-50%);
    padding: 0;
  }
}

.VueCarousel-dot-container {
  display: inline-block;
  margin: 0 auto;
  list-style-type: none;
  background: $green;
  position: relative;
  padding: 15px 0;

  .VueCarousel-dot {
    padding: 2px;
    vertical-align: middle;
    border-radius: 100%;
    width: 8px;
    height: 8px;
    margin: 9px;
    cursor: pointer;
    position: relative;
    margin-bottom: 9px;

    .VueCarousel-dot-inner {
      width: 10px;
      height: 10px;
      background: #000;
      border-radius: 100%;
    }
  }
}

.VueCarousel-dot-selector {
  position: absolute;
  padding: 9px;
  cursor: pointer;
  pointer-events: none;
  transform: matrix(1, 0, 0, 1, 0, 0);

  .VueCarousel-dot-inner {
    width: 14px;
    height: 14px;
    background: #000;
    border-radius: 100%;
  }
}
</style>
<style lang="scss">
.VueCarousel {
  &.simple {
    box-shadow: 10px 10px 0 0 rgba(0, 0, 0, 0.4);

    .VueCarousel-pagination {
      position: absolute;
      left: 0;
      width: 100%;
      z-index: 2;
      padding: 0;
      background: transparent;
      top: auto;

      .VueCarousel-dot-container {
        position: relative;
        padding: 15px 0;
        text-align: center;
        background: transparent;
        display: flex;
        justify-content: center;

        .VueCarousel-dot-selector {
          position: relative;
          width: 0;
          margin-right: -6px;
          padding: 9px 0;
        }

        .VueCarousel-dot {
          margin: 8.5px 5px;
        }
      }
    }
  }
}
</style>
