<template>
  <section class="werte-carousel black-icons">
    <carousel :perPage="1" :navigationEnabled="true" :paginationEnabled="true" :loop="true" :marginright="true">
      <slide v-for="wert in werte" :key="wert.title">
        <div class="inner-slide">
          <h3>{{ wert.title }}</h3>
          <div class="text">{{ wert.text }}</div>
        </div>
      </slide>
    </carousel>
  </section>
</template>

<script>
import { Werte } from './../store/data-about.jsx'

export default {
  name: 'werte-carousel',
  data() {
    return {
      werte: Werte,
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.$emit('caseIsLoaded')
    })
  },
}
</script>

<style lang="scss">
.werte-carousel {
  .inner-slide {
    width: 800px;
    max-width: calc(90vw - 140px);
    margin: 0 auto;
    padding-top: 80px;
    padding-bottom: 100px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: calc(100% - 180px);

    @media (max-width: 577px) {
      max-width: calc(90vw - 80px);
    }
  }

  h3 {
    font-size: 50px;
    margin-bottom: 20px;
    margin-top: 0;

    @media (max-width: 577px) {
      font-size: 30px;
      hyphens: auto;
    }
  }

  .text {
    line-height: 1.5;
    font-weight: 900;
    font-size: 20px;

    @media (max-width: 577px) {
      font-size: 16px;
    }
  }
}
</style>
