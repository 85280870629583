<template>
  <div>
    <case-header>
      <h1 slot="h1-title" id="top-headline">Sidroga Teexpress</h1>
      <h3 slot="h3-title">Sidroga Teexpress</h3>
      <div slot="details">
        <p>
          <strong>?</strong>Sidroga verfügt in Deutschland, Österreich und der Schweiz über einen sehr hohen Bekanntheitsgrad und eine hohe Akzeptanz als
          Anbieter von qualitativ hochstehendem Gesundheitstee. Wir wurden beauftragt, das neue Instanttee-Sortiment für Kinder aufzubauen und in der Folge auch
          und kommunikativ zu betreuen.
        </p>
        <div class="tags">
          <p>B2C Campaign | B2B Campaign | Packaging Design | Naming | Website | Below the Line</p>
        </div>
      </div>
    </case-header>

    <div v-show="!caseIsLoading" id="case-content">
      <section id="video-autoplay-section">
        <video v-if="!isU577" id="video1" class="video" ref="video1" muted playsinline>
          <source src="~assets/case-studies/teexpress/teddy.mp4" type="video/mp4" />
        </video>
        <img v-if="isU577" src="~assets/case-studies/teexpress/baerchen.jpg" />
      </section>

      <grund-gedanke>
        <h3 slot="title">Sidroga Teexpress</h3>
        <p slot="text">
          <strong>!</strong>Nachdem wir dem Sortiment und auch jedem Produkt einen Namen gegeben haben, bekamen alle Produkte eine individuelle, visuelle
          Erscheinung, die ihrer eigenen Märchenwelt entsprach. Und ein Leaflet in jeder Packung mit einem Kurz-Märchen, das nicht nur die Kinderherzen
          höherschlagen soll, sondern auch die Absatzzahlen, indem wir effizientes Crossselling betreiben. Mit der anschliessenden Kommunikation haben wir
          aufgezeigt, dass die Teexpress-Produkte der ständige Begleiter für das Kind ist, egal wo und in welcher Situation. <br /><br />
          <strong>=</strong>Noch bevor die Kommunikation – also das Rausverkaufen – richtig los ging, waren die ersten Apotheken bereits stockout. Wir haben mit
          dem Design, dem Naming und der gesamten Aufmachung des Sortiments alleine ins Schwarze getroffen und den Nerv ... nein das Herz der Mütter und Väter
          getroffen. Das befand übrigens auch die Jury des Swiss Packaging Awards, die uns für diese Arbeit einen Award verlieh. Mit der anschliessenden
          Kommunikation B2B und B2C konnten die Absatzzahlen weit über den Erwartungen erreicht werden.
        </p>
      </grund-gedanke>

      <section class="simple-img">
        <img :src="require('./../assets/case-studies/teexpress/' + imgRes + 'buechli.jpg')" />
      </section>

      <section class="animation-section-b black-icons">
        <img class="el-rucksack" src="~assets/case-studies/teexpress/gadgets/rucksack.jpg" />
        <img class="el-tasse" src="~assets/case-studies/teexpress/gadgets/tasse.png" />
        <img class="el-flasche" src="~assets/case-studies/teexpress/gadgets/flasche.png" />
      </section>

      <section class="slider-section slider-section-1 white">
        <carousel :perPage="1" :navigationEnabled="true" :loop="true">
          <slide>
            <img :src="require('./../assets/case-studies/teexpress/slider/' + imgRes + '01.jpg')" />
          </slide>
          <slide>
            <img :src="require('./../assets/case-studies/teexpress/slider/' + imgRes + '02.jpg')" />
          </slide>
          <slide>
            <img :src="require('./../assets/case-studies/teexpress/slider/' + imgRes + '03.jpg')" />
          </slide>
          <slide>
            <img :src="require('./../assets/case-studies/teexpress/slider/' + imgRes + '04.jpg')" />
          </slide>
        </carousel>
      </section>

      <section class="space-between-100"></section>

      <section class="animation-section-c black-icons">
        <img src="~assets/case-studies/teexpress/malboegen/01.png" />
        <img src="~assets/case-studies/teexpress/malboegen/02.png" />
        <img src="~assets/case-studies/teexpress/malboegen/03.png" />
      </section>

      <section class="animation-section-a" :class="{ isTouch: isTouch }">
        <div class="frame-wrapper"></div>
        <div v-if="!isTouch" class="vorhang-area" @mouseenter="startBuehneMovie" @mouseleave="reverseBuehneMovie"></div>
        <div v-if="isTouch && !buehneAnimisPlayed" class="play-button" @click="startBuehneMovie"></div>
      </section>

      <section class="img-section-a black-icons">
        <img src="~assets/case-studies/teexpress/award.jpg" />
      </section>

      <counter-footer :counters="footerCounters"></counter-footer>

      <case-footer></case-footer>
    </div>
  </div>
</template>

<script>
import CaseHeader from '../components/Case-Header-Teexpress.vue'
import CaseFooter from '../components/Case-Footer.vue'
import Grundgedanke from '../components/Grundgedanke.vue'
import CounterFooter from '../components/Counter-Footer.vue'
import CaseLoadStatusMixin from '../mixins/case-load-status'

export default {
  name: 'teexpress',
  components: {
    'case-header': CaseHeader,
    'case-footer': CaseFooter,
    'grund-gedanke': Grundgedanke,
    'counter-footer': CounterFooter,
  },
  mixins: [CaseLoadStatusMixin],
  data() {
    return {
      teddyAnimationIsPlayed: false,
      totalSteps: 12, //24,
      buehneAnim: false,
      buehneAnimisPlayed: false,
      imagesToLoad: [require('./../assets/case-studies/teexpress/animation-buehne-13.jpg'), require('./../assets/case-studies/teexpress/header.jpg')],
      footerCounters: [
        {
          score: 0,
          max: 2,
          title: '2 Wochen nach Lancierung: Stockout',
        },
        {
          score: 0,
          max: 1,
          title: 'Platz 1 beim Swiss Packaging Award 2018.',
        },
        {
          score: 0,
          max: 81,
          title: '81 % numerischer Distributionsgrad bei Apotheken in DACH.',
        },
      ],
    }
  },
  computed: {
    isU577() {
      return this.$store.getters.isU577
    },
    isTouch() {
      return this.$store.getters.isTouch
    },
    imgRes() {
      return window.innerWidth <= 577 ? 'low-' : ''
    },
  },
  methods: {
    startTeddyMovie() {
      var vid = document.getElementById('video1')
      var scene = new this.ScrollMagic.Scene({
        triggerElement: '#video-autoplay-section ',
        triggerHook: 0.2,
      }).on('enter', () => {
        if (!this.teddyAnimationIsPlayed) {
          this.teddyAnimationIsPlayed = true
          vid.play()
        }
      })
      this.$store.commit('addScrollMagicScene', scene)
    },
    createBuehneMovie() {
      this.buehneAnim = TweenMax.to('.animation-section-a .frame-wrapper', this.totalSteps / 25, {
        x: '-92.31%',
        ease: SteppedEase.config(this.totalSteps),
        paused: true,
      })
    },
    startBuehneMovie() {
      this.buehneAnimisPlayed = true
      this.buehneAnim.play()
    },
    reverseBuehneMovie() {
      this.buehneAnim.reverse()
    },
    initGadgetAnimation() {
      var tl = new this.TimelineLite().to('.el-tasse', 1, { x: 0 }, 0).to('.el-flasche', 1, { x: 0 }, 0)
      var scene = new this.ScrollMagic.Scene({
        triggerElement: '.animation-section-b',
        triggerHook: 0.65,
        duration: window.innerHeight * 0.9,
      }).setTween(tl)
      this.$store.commit('addScrollMagicScene', scene)
    },
    initMalboegenAnimation() {
      var tl = new this.TimelineLite()
        .to('.animation-section-c>img:nth-child(1)', 1, { x: '-75%', rotation: '-7deg' }, 0)
        .to('.animation-section-c>img:nth-child(2)', 1, { x: '-25%', rotation: '7deg' }, 0)
      var scene = new this.ScrollMagic.Scene({
        triggerElement: '.animation-section-c',
        triggerHook: 0.6,
        duration: 500,
      }).setTween(tl)
      this.$store.commit('addScrollMagicScene', scene)
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.initilizeLoad(() => {
        if (!this.isU577) {
          this.startTeddyMovie()
        }
        if (!this.isTouch) {
          this.initMalboegenAnimation()
        }
        this.initGadgetAnimation()
        this.createBuehneMovie()
      })
    })
  },
  beforeDestroy() {
    this.$store.commit('removeScrollMagicScenes', 'teexpress')
  },
}
</script>

<style lang="scss" scoped>
.simple-img {
  img {
    width: 100%;
  }
}

.animation-section-b {
  position: relative;
  height: 90vh;
  overflow: hidden;
  text-align: center;

  @media (max-width: 900px) {
    height: 100vw;
  }

  .el-rucksack {
    height: 80%;
    margin-top: 3%;
  }

  .el-tasse {
    position: absolute;
    height: 40%;
    bottom: 10%;
    right: calc(50vw - 50vh);
    transform: translateX(20vw);

    @media (max-width: 900px) {
      right: -5vw;
    }
  }

  .el-flasche {
    position: absolute;
    height: 55%;
    bottom: 8%;
    left: calc(50vw - 37vh);
    transform: translateX(-20vw);

    @media (max-width: 900px) {
      left: 10vw;
    }
  }
}

.animation-section-c {
  @media (min-width: 901px) {
    position: relative;
    height: 100vh;
    overflow: hidden;
    text-align: center;

    > img {
      height: 80%;
      position: absolute;
      top: 5%;
      left: 50%;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
      transform: translateX(-50%);
    }
  }

  @media (max-width: 900px) {
    > img {
      margin: 30px;
      width: calc(100% - 60px);
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    }
  }
}

.space-between-100 {
  height: 100px;
}

.animation-section-a {
  width: 100vw;
  height: 56.25vw;
  position: relative;

  .frame-wrapper {
    background-image: url('~assets/case-studies/teexpress/animation-buehne-13.jpg');
    background-size: cover;
    background-position: 0 0;
    height: 100%;
    width: 1300%;
    position: relative;

    @media (max-width: 577px) {
      background-image: url('~assets/case-studies/teexpress/animation-buehne-13-kl.jpg');
    }
  }

  .play-button {
    width: 50px;
    height: 50px;
    background-image: url('~assets/theme_icons/play.svg');
    background-size: cover;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
}

.vorhang-area {
  width: 47vw;
  height: 31vw;
  position: absolute;
  left: 30vw;
  top: 15vw;
}

.img-section-a {
  text-align: center;

  img {
    padding: 100px 0;
  }
}
</style>
