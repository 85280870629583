export default {
  data() {
    return {
      isLoading: true,
      detailsButtonText: 'wird geladen',
      showVideo: false,
    }
  },

  computed: {
    isU577() {
      return this.$store.getters.isU577
    },
  },

  methods: {
    scrollToCaseContent() {
      TweenMax.to(window, 1, { scrollTo: { y: '#case-content', autoKill: false } })
    },

    playTopVideo() {
      if (this.autopla && !isU577) {
        var topVid = document.getElementById('top-video')
        if (topVid) {
          topVid.play()
        }
      }
    },

    showDetails() {
      if (!this.isLoading) {
        var half = window.innerWidth * -1
        // about
        if (this.$route.path == '/about') {
          var tl = new this.TimelineLite()
            .to('#top .content ', 0.8, { x: '-50%' }, 0)
            .to('.header-img-people', 0.8, { x: half, ease: Power1.easeOut }, 0)
            .set('.header-img-people', { alpha: 0, delay: 0.8 })
        } else {
          var letters = document.querySelectorAll('#top .first-container h1 span')
          var tl = new this.TimelineLite()
            .set('#top .first-container .details-button-after', { width: 100 })
            .to('#top .first-container .details-button', 0.8, { x: half, ease: Power1.easeIn }, 0)
            .to('#top .first-container .details-button-after', 0.8, { x: half * 2, ease: Power1.easeOut }, 0)
            .staggerTo(
              '#top .first-container h1 span',
              0.5,
              {
                cycle: {
                  x: (i) => {
                    return i * 20 - letters.length * 20
                  },
                },
                ease: Power1.easeIn,
              },
              0,
              0
            )
            .to('#top .first-container #top-headline', 1, { x: half, ease: Power1.easeIn }, 0.3)
            .to('#top .content ', 0.8, { x: '-50%' }, 0.8)
            .to('#top .second-container .content-container>div:nth-child(2)', 0.8, { x: '80%' }, 0.3)
            .to('#top .second-container .content-container>div:nth-child(2)', 0.8, { x: '0%' }, 1.1)
        }
      }
    },

    cutHeadline() {
      let headlineEl = document.querySelectorAll('#top-headline')
      let el = headlineEl[headlineEl.length - 1]
      let headlineChars = el.innerText.split('')
      el.innerHTML = ''
      for (var i = 0; i < headlineChars.length; i++) {
        el.innerHTML += '<span>' + headlineChars[i] + '</span>'
      }
    },

    hideLoading() {
      var overlayAlpha = this.$route.path == '/rentarentner' ? 0.4 : 0.7
      var tl = new this.TimelineLite()
        .fromTo('.details-button-after', 0.7, { alpha: 1 }, { alpha: 0 }, 0)
        .call(
          () => {
            if (!this.isU577) {
              this.showVideo = true
            }
            this.loadingTL.clear()
          },
          this,
          [],
          0.7
        )
        .to('.details-button', 1, { alpha: 0 }, 0.5)
        .set('.details-button', { y: 20 })
        .set('.details-button-after', { left: 'calc(50% - 5px)', clearProps: 'right' })
        .to('.details-button', 1, { alpha: 1, y: 0 }, 1.7)
        .call(
          () => {
            this.detailsButtonText = 'Details'
            this.isLoading = false
          },
          this,
          [],
          1.7
        )
        .fromTo('.details-button-after', 1, { alpha: 0, width: 0 }, { alpha: 1, width: 10 }, 1.8)
        .to('#top.header-section .overlay', 1, { alpha: overlayAlpha }, 1.5)
        .to('.scroll-down-on-case-arrow', 1, { alpha: 1, y: 0 }, 1.5)
        .call(
          () => {
            this.playTopVideo()
          },
          this,
          [],
          1.5
        )
        .set('.scroll-down-on-case-arrow', { 'pointer-events': 'auto' }, 3)
    },

    createloadingTimeline() {
      var speed = 1
      this.loadingTL = new this.TimelineLite({
        onComplete: function () {
          this.restart()
        },
      })
        .to('.details-button', 1, { alpha: 0.3 })
        .to('.details-button', 1, { alpha: 1 })
        .to('.details-button-after', speed, { right: 0, left: 'auto', ease: Power0.easeNone }, 0)
        .to('.details-button-after', speed, { left: 0, right: 'auto', ease: Power0.easeNone }, speed)
        .to('.details-button-after', speed / 2, { width: 50, ease: Power0.easeNone }, 0)
        .to('.details-button-after', speed / 2, { width: 10, ease: Power0.easeNone }, speed * 0.5)
        .to('.details-button-after', speed / 2, { width: 50, ease: Power0.easeNone }, speed)
        .to('.details-button-after', speed / 2, { width: 10, ease: Power0.easeNone }, speed * 1.5)
    },

    animateWorksArrowOut() {
      if (!this.isLoading && !this.isU577) {
        var tl = new this.TimelineLite()
          .to('.scroll-down-on-case-arrow img', 0.4, { y: 100, ease: Power3.easeIn }, 0)
          .to('.scroll-down-on-case-arrow .arrow-extention', 0.4, { y: 30, height: 100, ease: Power3.easeIn }, 0)
      }
    },

    setCaseheaderHeightMobile() {
      var height = window.innerWidth <= 900 ? window.innerHeight : '100vh'
      TweenMax.set('#top, .header-section .content, .header-sidrosan, .header-section-teeexpress', { height: height })
    },
  },

  mounted() {
    this.createloadingTimeline()
    this.$nextTick(() => {
      this.cutHeadline()
    })
    this.$parent.$on('hideLoadingInHeader', () => {
      this.hideLoading()
    })
  },

  activated() {
    this.setCaseheaderHeightMobile()
    window.addEventListener('resize', this.setCaseheaderHeightMobile)
  },

  deactivated() {
    window.removeEventListener('resize', this.setCaseheaderHeightMobile)
  },
}
