<template>
  <div>
    <case-header :backgroundvid="headerBackgroundVideoUrl" :autoplay="isU577" :headerImgUrl="headerImgUrl">
      <h1 slot="h1-title" id="top-headline">Sidrosan</h1>
      <h3 slot="h3-title">Sidrosan</h3>
      <div slot="details">
        <p>
          <strong>?</strong>Wie kann man in einem gesättigten Markt als Hersteller erstklassiger Gesundheitstees weiter wachsen? Ein Pluspunkt, auf dem wir
          aufbauen: Sidroga ist in Deutschland, Österreich und der Schweiz bekannt als Hersteller von Qualitäts-Gesundheitstees. Das hilft uns, bei der Lösung
          der anspruchsvollen Ausgabe.
        </p>
        <div class="tags">
          <p>B2C Campaign | B2B Campaign | Naming | Packaging Design | Website</p>
        </div>
      </div>
    </case-header>

    <div v-show="!caseIsLoading" id="case-content">
      <div class="black-icons">
        <parallax-glider :imgurl="require('./../assets/case-studies/sidrosan/' + imgRes + 'faust.jpg')"></parallax-glider>
      </div>

      <grund-gedanke>
        <h3 slot="title">Sidrosan</h3>
        <p slot="text">
          <strong>!</strong>Der Gedanke ist einfach, aber politisch heikel: Wir verkaufen den Tee von Sidroga in Deutschland nicht nur in Apotheken, sondern
          auch in Drogeriemärkten. Um einen Konflikt zwischen den beiden Verkaufskanälen zu vermeiden, verpassen wir den Tees einen neuen Markennamen, in enger
          Verwandtschaft zu Sidroga. Sidrosan war geboren. Und mit diesem Namen in Verbindung mit dem darauf abgestimmten Packaging Design gingen wir auf
          Erfolgstour. Der passende Kommunikationsauftritt unter dem Motto «Die Natur bietet Hand, wenn es einem mal nicht gut geht.» machte den Rest der
          Arbeit. <br /><br />
          <strong>=</strong>Die Idee und die Zusammenarbeit mit dm-Drogeriemarkt zahlt sich mehr als aus. Sidrosan findet reissenden Absatz in den 1800
          dm-Filialen deutschlandweit, obwohl der Verkaufspreis bis zu doppelt so hoch ist als andere Tees. Das beweist: Kampagne und Packaging-Design greifen
          ineinander. Die Packungen leben von wissenschaftlichen Zeichnungen. Diejenigen für die drei Kindertees hat ein fünfjähriges Mädchen gezeichnet, quasi
          vom Konsument für den Konsumenten.
        </p>
      </grund-gedanke>

      <section id="video-autoplay-section" class="black-icons">
        <video v-if="!isTouch && !isU900" id="video1" class="video">
          <source src="~assets/case-studies/sidrosan/tee-animation.mp4" type="video/mp4" />
        </video>
        <img v-if="isTouch || isU900" src="~assets/case-studies/sidrosan/tee-p-mobile.jpg" />
      </section>

      <section class="slider-section slider-section-1">
        <carousel :perPage="1" :navigationEnabled="true" :loop="true">
          <slide>
            <img :src="require('./../assets/case-studies/sidrosan/slider1/' + imgRes + '01.jpg')" />
          </slide>
          <slide>
            <img :src="require('./../assets/case-studies/sidrosan/slider1/' + imgRes + '02.jpg')" />
          </slide>
          <slide>
            <img :src="require('./../assets/case-studies/sidrosan/slider1/' + imgRes + '03.jpg')" />
          </slide>
        </carousel>
      </section>

      <counter-footer :counters="footerCounters"></counter-footer>

      <case-footer></case-footer>
    </div>
  </div>
</template>

<script>
import CaseHeaderSidrosan from '../components/Case-Header-Sidrosan.vue'
import CaseFooter from '../components/Case-Footer.vue'
import Grundgedanke from '../components/Grundgedanke.vue'
import ParallaxGlider from '../components/Parallax-Glider.vue'
import CounterFooter from '../components/Counter-Footer.vue'
import CaseLoadStatusMixin from '../mixins/case-load-status'

export default {
  name: 'sidrosan',
  components: {
    'case-header': CaseHeaderSidrosan,
    'case-footer': CaseFooter,
    'grund-gedanke': Grundgedanke,
    'parallax-glider': ParallaxGlider,
    'counter-footer': CounterFooter,
  },
  mixins: [CaseLoadStatusMixin],
  data() {
    return {
      //headerBackgroundVideoUrl: require('./../assets/case-studies/sidrosan/header.mp4'),
      headerBackgroundVideoUrl: false,
      headerImgUrl: require('./../assets/case-studies/sidrosan/main.jpg'),
      TeeAnimationIsPlayed: false,
      imagesToLoad: [require('./../assets/case-studies/sidrosan/animation-tee.jpg')],
      footerCounters: [
        {
          score: 0,
          max: 1920,
          title: 'In 1920 dm-Filialen sind Sidrosan Produkte deutschlandweit gelistet.',
        },
        {
          score: 0,
          max: 3,
          title: 'Reissenden Absatz, obwohl bis zu 3x teurer als vergleichende Tees.',
        },
        {
          score: 0,
          max: 6,
          title: 'Innert 6 Monaten: Konzeption, Erstkontakt dm bis zur Listung.',
        },
      ],
    }
  },
  computed: {
    isU577() {
      return this.$store.getters.isU577
    },
    isU900() {
      return this.$store.getters.isU900
    },
    isTouch() {
      return this.$store.getters.isTouch
    },
    imgRes() {
      return window.innerWidth <= 577 ? 'low-' : ''
    },
  },
  methods: {
    startTeeMovie() {
      var vid = document.getElementById('video1')
      var scene = new this.ScrollMagic.Scene({
        triggerElement: '#video-autoplay-section ',
        triggerHook: 0.5,
      }).on('enter', () => {
        if (!this.TeeAnimationIsPlayed) {
          this.TeeAnimationIsPlayed = true
          vid.play()
        }
      })
      this.$store.commit('addScrollMagicScene', scene)
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.initilizeLoad(() => {
        if (!this.isTouch && !this.isU900) {
          this.startTeeMovie()
          this.$emit('initilizeLoadSidrosan')
        } else {
          this.$store.dispatch('allowScroll')
        }
      })
    })
  },
  beforeDestroy() {
    this.$store.commit('removeScrollMagicScenes', 'sidrosan')
  },
}
</script>

<style lang="scss" scoped></style>
