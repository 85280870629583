<template>
  <div>
    <case-header :backgroundvid="headerBackgroundVideoUrl" :headerImgUrl="headerImgUrl">
      <h1 slot="h1-title" id="top-headline">Sandoz Schweiz</h1>
      <h3 slot="h3-title">Sandoz Schweiz</h3>
      <div slot="details">
        <p>
          <strong>?</strong>Kann man ein Unternehmen, dass Medikamente kopiert, ein unverwechselbares Gesicht geben? Sandoz ist ein Meister des Kopierens in den
          Bereichen Generika und Biosimilars. Die Herausforderung bestand darin, Sandoz als ehemaligen Hersteller von Originals unverwechselbar zu machen. Und
          das auf allen Ebenen.
        </p>
        <div class="tags">
          <p>
            B2C Campaign | B2B Campaign | B2P Campaign | TV Commercial | Billboards | Direct Marketing | Video | Magazine | Visual Imagery | Website | Microsite
          </p>
        </div>
      </div>
    </case-header>

    <div v-show="!caseIsLoading" id="case-content">
      <section class="animation-section-a">
        <div class="col col-1" :class="{ low: imgRes }"></div>
        <div class="col col-2" :class="{ low: imgRes }"></div>
        <div class="col col-3" :class="{ low: imgRes }"></div>
      </section>

      <grund-gedanke>
        <h3 slot="title">Sandoz Schweiz</h3>
        <p slot="text">
          <strong>!</strong>Eine erste Antwort: Wir spielen zum ersten Mal den Sandoz-Vorteil aus. Eine Strassenumfrage brachte uns auf den Kerngedanken. In
          Fribourg reagierte eine junge Passantin wie folgt: «Natürlich bevorzuge ich Sandoz. Als ehemaliger Originalhersteller haben sie doch bewiesen, dass
          sie es können. Wer, wenn nicht Sandoz?» Der Schritt zur Kampagne war ein kleiner: Die besseren Generika kommen vom ehemaligen Original-Hersteller!
          Gleichgut, aber günstiger. <br /><br />
          Eine zweite Antwort: Wir hämmern zum zweiten Mal auf dieselbe Stelle beim Konsumenten. Diesmal kam ein neuer Aspekt hinzu: Seit 1887 gibt es Sandoz
          als Pharmaunternehmen in der Schweiz. Dies legitimiert zur Alleinstellung der Schweizer Generikahersteller. Wir machen aus Tabletten Schweizer
          Souvenirs mit typisch Schweizer Motiven.
          <br /><br />
          Eine dritte Antwort: Pharma-Assistentinnen werden in der Schweiz stiefmütterlich bearbeitet. Unverständlich, denn es sind Menschen mit starkem
          Einfluss am POS und bei den Konsumenten. Denn was sie den Konsumenten raten, wird in den allermeisten Fällen auch gekauft. Also ist es nur ein allzu
          logischer Schritt, dass wir die rund 1800 wichtigen Beeinflusserinnen an Sandoz binden. Allerdings geht das nicht mit dem Holzhammer, sondern auf
          sympathische und effektive Art und Weise: mit einem Kundenmagazin, das sich gewaschen hat. Es besticht durch den perfekten Mix aus Lifestyle,
          Product-Placement, Serviceleistung und ganz wichtig, Emotionen. Das Magazin schlug ein wie eine Bombe. Schliesslich holten wir die
          Pharma-Assistentinnen genau dort ab, wo sie sich angesprochen und ernst genommen fühlten.
          <br /><br />
          <strong>=</strong>Differenzierung heisst das Stichwort. Dank der Marketingstrategie war Sandoz in aller Munde, zumindest was Generika betrifft. Damit
          haben wir den Generika-Markt ins Rollen gebracht und die Poleposition verteidigt. Mit dem Gewinn der Marketingtrophy von Swissmarketing wurde der Mut
          unseres Kunden und unsere Leistung honoriert.
        </p>
      </grund-gedanke>

      <video-player
        :videourl="require('./../assets/case-studies/sandozschweiz/spot.mp4')"
        :poster="require('./../assets/case-studies/sandozschweiz/spot-poster.jpg')"
      >
      </video-player>

      <section class="slider-section slider-section-1">
        <carousel :perPage="1" :navigationEnabled="true" :loop="true">
          <slide>
            <img :src="require('./../assets/case-studies/sandozschweiz/slider1/' + imgRes + '01.jpg')" />
          </slide>
          <slide>
            <img :src="require('./../assets/case-studies/sandozschweiz/slider1/' + imgRes + '02.jpg')" />
          </slide>
          <slide>
            <img :src="require('./../assets/case-studies/sandozschweiz/slider1/' + imgRes + '03.jpg')" />
          </slide>
          <slide>
            <img :src="require('./../assets/case-studies/sandozschweiz/slider1/' + imgRes + '04.jpg')" />
          </slide>
        </carousel>
      </section>

      <section class="animation-section-b black-icons desktop">
        <img src="~assets/case-studies/sandozschweiz/anzeige01.jpg" />
        <img src="~assets/case-studies/sandozschweiz/anzeige02.jpg" />
      </section>

      <section class="animation-section-b-1 black-icons mobile">
        <img src="~assets/case-studies/sandozschweiz/anzeige01.jpg" />
        <img src="~assets/case-studies/sandozschweiz/anzeige02.jpg" />
      </section>

      <section class="slider-section slider-section-2">
        <carousel :perPage="1" :navigationEnabled="true" :loop="true">
          <slide>
            <img :src="require('./../assets/case-studies/sandozschweiz/slider2/' + imgRes + '01.jpg')" />
          </slide>
          <slide>
            <img :src="require('./../assets/case-studies/sandozschweiz/slider2/' + imgRes + '02.jpg')" />
          </slide>
          <slide>
            <img :src="require('./../assets/case-studies/sandozschweiz/slider2/' + imgRes + '03.jpg')" />
          </slide>
          <slide>
            <img :src="require('./../assets/case-studies/sandozschweiz/slider2/' + imgRes + '04.jpg')" />
          </slide>
          <slide>
            <img :src="require('./../assets/case-studies/sandozschweiz/slider2/' + imgRes + '05.jpg')" />
          </slide>
        </carousel>
      </section>

      <parallax-glider :imgurl="require('./../assets/case-studies/sandozschweiz/' + imgRes + 'broschueren.jpg')"></parallax-glider>

      <section class="trophy-section black-icons">
        <img src="~assets/case-studies/sandozschweiz/trophy.jpg" />
      </section>

      <counter-footer :counters="footerCounters"></counter-footer>

      <case-footer></case-footer>
    </div>
  </div>
</template>

<script>
import CaseHeader from '../components/Case-Header.vue'
import CaseFooter from '../components/Case-Footer.vue'
import Grundgedanke from '../components/Grundgedanke.vue'
import ParallaxGlider from '../components/Parallax-Glider.vue'
import CounterFooter from '../components/Counter-Footer.vue'
import VideoPlayer from '../components/Video-Player.vue'
import CaseLoadStatusMixin from '../mixins/case-load-status'

export default {
  name: 'sandozschweiz',
  components: {
    'case-header': CaseHeader,
    'case-footer': CaseFooter,
    'grund-gedanke': Grundgedanke,
    'parallax-glider': ParallaxGlider,
    'counter-footer': CounterFooter,
    'video-player': VideoPlayer,
  },
  mixins: [CaseLoadStatusMixin],
  data() {
    return {
      headerBackgroundVideoUrl: require('./../assets/case-studies/sandozschweiz/header.mp4'),
      headerImgUrl: require('./../assets/case-studies/sandozschweiz/main.jpg'),
      footerCounters: [
        {
          score: 0,
          max: 1,
          title: 'Innerhalb von 2 Jahren wurde Sandoz die Nr. 1 im Generika-Markt.',
        },
        {
          score: 0,
          max: 1,
          title: '1 Marketing-Trophy von Swissmarketing gewonnen.',
        },
        {
          score: 0,
          max: 61,
          title: '61 % der Schweizer Bevölkerung erinnerte sich an die farbige B2C-Kampagne.',
        },
      ],
    }
  },
  computed: {
    isU577() {
      return this.$store.getters.isU577
    },
    imgRes() {
      return window.innerWidth <= 577 ? 'low-' : ''
    },
  },
  methods: {
    initBanditAnimation() {
      var tl = new this.TimelineLite()
        .to('.animation-section-a>.col-1', 1.5, { backgroundPosition: '100% 200%', ease: Elastic.easeOut.config(1, 0.4) }, 0)
        .to('.animation-section-a>.col-2', 1.5, { backgroundPosition: '100% 100%', ease: Elastic.easeOut.config(1, 0.4) }, 0.2)
        .to('.animation-section-a>.col-3', 1.5, { backgroundPosition: '100% 200%', ease: Elastic.easeOut.config(1, 0.4) }, 0.4)
      var scene = new this.ScrollMagic.Scene({
        triggerElement: '.animation-section-a',
        triggerHook: 0.5,
        reverse: false,
      }).setTween(tl)
      this.$store.commit('addScrollMagicScene', scene)
    },
    initAnzeigenAnimation() {
      var w = window.innerWidth
      var tl = new this.TimelineLite()
        .fromTo('.animation-section-b>img:nth-child(1)', 1, { rotation: '0deg', y: 0, x: '-50%' }, { rotation: '-5deg', y: '5%', x: w * 0.3 }, 0)
        .fromTo('.animation-section-b>img:nth-child(2)', 1, { rotation: '0deg', x: '150%' }, { rotation: '5deg', x: '40%' }, 0)

      var scene = new this.ScrollMagic.Scene({
        triggerElement: '.animation-section-b',
        triggerHook: 0.4,
        duration: 400,
      }).setTween(tl)
      this.$store.commit('addScrollMagicScene', scene)
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.initilizeLoad(() => {
        this.initAnzeigenAnimation()
        this.initBanditAnimation()
      })
    })
  },
  beforeDestroy() {
    this.$store.commit('removeScrollMagicScenes', 'sandozschweiz')
  },
}
</script>

<style lang="scss" scoped>
.animation-section-a {
  display: inline-block;

  .col {
    width: 33.33vw;
    height: 33.3vw;
    float: left;
    background-position: 100% 100%;
    background-size: cover;

    &.col-1 {
      background-image: url('~assets/case-studies/sandozschweiz/bandit/01.jpg');

      &.low {
        background-image: url('~assets/case-studies/sandozschweiz/bandit/low-01.jpg');
      }
    }

    &.col-2 {
      background-image: url('~assets/case-studies/sandozschweiz/bandit/02.jpg');

      &.low {
        background-image: url('~assets/case-studies/sandozschweiz/bandit/low-02.jpg');
      }

      background-position: 100% 200%;
    }

    &.col-3 {
      background-image: url('~assets/case-studies/sandozschweiz/bandit/03.jpg');

      &.low {
        background-image: url('~assets/case-studies/sandozschweiz/bandit/low-03.jpg');
      }
    }
  }
}

.animation-section-b {
  @media (min-width: 578px) {
    position: relative;
    height: 65vw;
    max-height: 100vh;
    overflow: hidden;
    text-align: 101% 200%;
    display: block;

    > img {
      position: absolute;
      height: 80%;
      top: 10%;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);

      &:nth-child(1) {
        transform: translateX(-130%) translateY(5%) rotate(-5deg);
        z-index: 1;
      }

      &:nth-child(2) {
        transform: translateX(-30%) rotate(5deg);
      }
    }
  }

  @media (max-width: 577px) {
    display: none;
  }
}

.animation-section-b-1 {
  display: none;

  @media (max-width: 577px) {
    display: block;
  }

  > img {
    margin: 30px;
    width: calc(100% - 60px);
  }
}

.trophy-section {
  text-align: center;

  img {
    padding: 100px 0;
  }
}
</style>
