<template>
  <section class="section-accordion">
    <heading :align="'center'">Wir sind ihre Antwort auf</heading>
    <div class="accordion" ref="accordion">
      <div
        class="item"
        v-for="(aufgabe, index) of aufgaben"
        v-bind:key="'key-index-' + index"
        :id="getLeistungenSlug(aufgabe)"
        :class="{ active: aufgabe.isActive }"
        @click="toggleAccordion(index)"
      >
        <h3 class="title">{{ aufgabe.title }}</h3>
        <div class="content" v-html="aufgabe.content"></div>
      </div>
    </div>
  </section>
</template>

<script>
import { Aufgaben } from './../store/data-about.jsx'
import Heading from './Heading.vue'

export default {
  name: 'AccordionServices',
  components: {
    heading: Heading,
  },

  data() {
    return {
      aufgaben: Aufgaben,
    }
  },

  methods: {
    getLeistungenSlug(exp) {
      return (
        'leistung-' +
        exp.title
          .toLowerCase()
          .replace(/ /g, '-')
          .replace(/[^\w-]+/g, '')
      )
    },

    toggleAccordion(activeIndex) {
      // If the clicked Item is aready open
      if (this.aufgaben[activeIndex].isActive) {
        this.toggleItem(activeIndex, 'close')
        this.aufgaben[activeIndex].isActive = false

        // If the Item is close
      } else {
        this.toggleItem(activeIndex, 'open')

        // look at all items
        for (var i = 0; i < this.aufgaben.length; i++) {
          // if an other item is open
          if (this.aufgaben[i].isActive) {
            this.toggleItem(i, 'close')
            this.aufgaben[i].isActive = false
          }
        }

        // Set new Open item to active
        this.aufgaben[activeIndex].isActive = true
      }
    },
    toggleItem(i, direction) {
      var accordion = this.$refs.accordion
      const el = accordion.querySelector('.item:nth-child(' + (i + 1) + ')')
      const ease = direction == 'open' ? Back.easeOut.config(1.3) : Power2.easeNone
      const height = parseInt(el.getAttribute('data-height_' + direction))
      TweenMax.to(el, 0.35, { height, ease })
    },

    setAccordionHeights() {
      var items = this.$refs.accordion.querySelectorAll('.item')
      for (var i = 0; i < items.length; i++) {
        items[i].style.height = 50
        var content = items[i].childNodes[2]
        var contentHeight = content.clientHeight
        var title = items[i].childNodes[0]
        var titleHeight = title.clientHeight
        items[i].setAttribute('data-height_open', titleHeight + contentHeight)
        items[i].setAttribute('data-height_close', titleHeight)

        if (!items[i].classList.contains('active')) {
          items[i].style.height = titleHeight + 'px'
        }
      }
    },
  },

  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.setAccordionHeights()
      }, 500)

      window.addEventListener('resize', (e) => {
        this.setAccordionHeights()
      })
    })
  },
}
</script>

<style lang="scss">
@import '~assets/scss/vars';

.section-accordion {
  line-height: 1.5;
  background: #000;
  color: #fff;
  padding: 120px 60px;
  margin: 0 auto;
  max-width: 1100px;

  @media (max-width: 577px) {
    padding-left: 30px;
    padding-right: 30px;
  }

  .item {
    cursor: pointer;
    overflow: hidden;
    margin-top: 45px;

    @media (max-width: 577px) {
      margin-top: 10px;
    }

    .title {
      font-size: 60px;
      font-weight: 500;
      color: #000;
      background-color: $green;
      margin: 0;
      padding: 15px 45px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      display: flex;
      align-items: center;

      @media (max-width: 1200px) {
        font-size: 40px;
      }

      @media (max-width: 577px) {
        font-size: 24px;
        line-height: 1.3;
      }

      &::after {
        content: url('~assets/theme_icons/arrow-left.svg');
        width: 40px;
        transform: rotate(180deg) translateY(5px);
        margin-left: 60px;
        transition: 150ms transform ease-in-out;
      }

      @media (min-width: 578px) {
        &:hover {
          &::after {
            transform: rotate(180deg) translateY(5px) translateX(-15px);
          }
        }
      }
    }

    .content {
      font-size: 25px;
      font-weight: 500;
      color: #fff;
      padding: 30px 60px;
      transition: 250ms color ease-in-out;

      @media (max-width: 577px) {
        font-size: 16px;
        padding: 10px 15px 15px;
      }
    }
  }
}
</style>
