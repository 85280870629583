<template>
  <div class="newsletter-signup-form">
    <form v-if="!isSubmitted" @submit="submit">
      <input type="email" required name="mail" v-model="email" placeholder="E-Mail" />
      <div class="error" v-if="errorMsg">{{ errorMsg }}</div>
      <div class="submit-wrapper">
        <input type="submit" name="submit" value="" />
      </div>
    </form>
    <div v-if="isSubmitted" class="thankyou">
      <p>{{ msgTankYou }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Newsletter-Signup-Form',
  props: {
    targetMail: {
      type: String,
      default: 'frage@dieantwort.de',
    },
    msgTankYou: {
      type: String,
      default: 'Danke, dass du unseren Newsletter abonniert hast!',
    },
  },
  data() {
    return {
      email: '',
      isSubmitted: false,
      errorMsg: false,
    }
  },
  methods: {
    submit(e) {
      e.preventDefault()

      this.errorMsg = false
      const body = JSON.stringify({
        email: this.email,
        target: this.targetMail,
      })

      fetch('/static/mail/newsletter.php', {
        method: 'post',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
          'Content-Length': body.length.toString(),
        },
        body,
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.status == 'failed') {
            this.handleError('Leider gab es einen Fehler.')
          } else {
            this.isSubmitted = true
          }
        })
        .catch(this.handleError)
    },

    handleError(err) {
      this.errorMsg = 'Leider gab es einen Fehler. Versuchen sie es später noch einmal.'
      console.log(err)
    },
  },
}
</script>

<style lang="scss">
@import '~assets/scss/vars';

.newsletter-signup-form {
  max-width: calc(100% - 30px);
  margin: 0 auto;

  form {
    display: inline-block;
    position: relative;
    box-sizing: border-box;
    max-width: 100%;
  }

  input {
    &[type='email'] {
      font-size: 18px;
      border: none;
      border-bottom: 2px solid #000;
      padding: 5px 60px 5px 5px;
      width: 450px;
      outline: none;
      margin-top: 30px;
      max-width: 100%;
      box-sizing: border-box;
      background: transparent;
    }
  }

  .submit-wrapper {
    position: absolute;
    right: 5px;
    bottom: 10px;
    height: 24px;
    width: 24px;

    input[type='submit'] {
      border: none;
      height: 24px;
      width: 24px;
      padding: none;
      cursor: pointer;
      background: transparent;
    }

    &::before {
      content: '';
      background-image: url('~assets/theme_icons/arrow-small-right.svg');
      height: 15px;
      width: 18px;
      background-size: contain;
      background-repeat: no-repeat;
      position: absolute;
      pointer-events: none;
      transition: 150ms all ease-in-out;
      right: 3px;
      top: 4px;
    }

    &:hover {
      &::before {
        transform: translateX(5px);
      }
    }
  }

  .error {
    position: absolute;
    line-height: 1;
    width: 100%;
    padding-top: 10px;
    color: #fb064a;
  }
}
</style>
