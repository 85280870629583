<template>
  <div class="chat-wrapper">
    <div
      class="welcome-box-wrapper"
      :class="{ visible: isWelcomeVisible }"
      @click="handleWelcomBoxClick"
      @mouseenter="handleMouseEnterWelcomeBox"
      @mouseleave="handleMouseLeaveWelcomeBox"
    >
      <div class="welcome-box-shadow"></div>
      <div class="welcome-box">«DIE ANTWORT»<br />{{ welcomeText }}</div>
    </div>
    <div class="welcome-box-wrapper mobil" :class="{ visible: isWelcomeMobileVisible }" @click="handleWelcomBoxClick">
      <div class="welcome-box-shadow"></div>
      <div class="welcome-box"><span></span><span></span><span></span></div>
    </div>

    <div class="chat-form-wrapper" :class="{ visible: isChatVisible }">
      <div class="close" @click="handleChatCloseCrossClick">
        <div>
          <span></span>
          <span></span>
        </div>
      </div>
      <form v-if="!isSubmitted" @submit="submit">
        <p class="explanation">Bis gleich.</p>
        <p class="error" v-if="errorMsg">{{ errorMsg }}</p>
        <textarea placeholder="Hier antworten ..." v-model="msg.nachricht"></textarea>
        <input type="email" name="mail" v-model="msg.email" placeholder="E-Mail" />
        <div class="submit-wrapper">
          <input type="submit" name="submit" value="" />
        </div>
      </form>

      <div v-if="isSubmitted" class="thankyou">
        <p>Danke für deine Nachricht. Wir melden uns so schnell wie möglich bei dir.</p>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from './../store/eventbus'

export default {
  name: 'chat',
  data() {
    return {
      msg: {
        email: '',
        nachricht: '',
      },
      errorMsg: false,
      isSubmitted: false,
      isWelcomeVisible: false,
      isChatVisible: false,
      isWelcomeMobileVisible: false,
      welcomTextLogikActive: true,
      welcomeTextTL: false,
      welcomeText: '',
      welcomTextColumn: 'home',
      welcomTexts: {
        home: [
          'Hallo, guten Tag! Schön Dich zu sehen. Können wir «antworten»?',
          'Wie wäre es mit einem kleinen Snack?',
          'Wir haben DIE ANTWORT für Dich, wetten?',
          'Wir müssen uns treffen! Morgen um 14h bei uns an der Bertastrasse, ok?',
        ],
        works: [
          'Unser ganzer Stolz. Können wir irgendwie helfen?',
          'Und? Gefallen Dir unsere Arbeiten?',
          'Wir haben noch mehr in Petto. Bildwelten, CI/CD’s und und und. Was suchst Du?',
          'Wir wollen auch einen Case für Dich! Wann wollen wir uns treffen?',
        ],
        about: [
          'Wir sind keine «normale» Agentur. Wetten?',
          'Keine leeren Versprechungen, die Du hier siehst. Können wir Dir das beweisen?',
          'Wir sind Macher und sprechen nicht um den heissen Brei. Eine Firmenpräsi vielleicht?',
          'Also gut. Morgen um 13.30h bei Dir. Gibst Du uns noch kurz die Adresse durch?',
        ],
      },
      welcomeTextUpdateTimes: [5, 30, 60, 120],
      welcomTextDuration: 10,
    }
  },
  computed: {
    isSmallScreen() {
      let w = window.innerWidth
      let h = window.innerHeight
      return (w < 577 && w < h) || (h < 577 && h < w)
    },
  },
  methods: {
    handleMouseEnterWelcomeBox() {
      if (this.welcomeTextTL) {
        this.welcomeTextTL.paused(true)
      }
    },
    handleMouseLeaveWelcomeBox() {
      if (this.welcomeTextTL) {
        this.welcomeTextTL.paused(false)
      }
    },

    handleWelcomBoxClick() {
      this.setWelcomeBoxVisibility(false)
      this.setChatWindowVisibility(true)
      this.killTimer()
      this.welcomTextLogikActive = false
      this.isWelcomeMobileVisible = false
    },

    handleChatCloseCrossClick() {
      this.setChatWindowVisibility(false)
    },

    setChatWindowVisibility(state) {
      this.isChatVisible = state
    },

    setWelcomeBoxVisibility(state) {
      this.isWelcomeVisible = state
    },

    submit(e) {
      e.preventDefault()
      this.errorMsg = false

      const body = JSON.stringify({ msg: this.msg })
      fetch('/static/mail/chat.php', {
        method: 'post',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
          'Content-Length': body.length.toString(),
        },
        body,
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.status == 'failed') {
            this.errorMsg = 'Leider gab es einen Fehler.'
          } else {
            this.isSubmitted = true
          }
        })
        .catch(() => {
          this.errorMsg = 'Leider gab es einen Fehler.'
        })
    },
    watchTextareaSize() {
      $('textarea').on('input', function () {
        $(this).height(100).height(this.scrollHeight)
      })
    },
    startTimer() {
      if (this.welcomTextLogikActive) {
        this.welcomeTextTL = new TimelineLite()
          .call(
            () => {
              this.welcomeText = this.welcomTexts[this.welcomTextColumn][0]
              this.isWelcomeVisible = true
            },
            [],
            this,
            this.welcomeTextUpdateTimes[0]
          )
          .call(
            () => {
              this.isWelcomeVisible = false
            },
            [],
            this,
            '=+' + this.welcomTextDuration
          )
          .call(
            () => {
              this.welcomeText = this.welcomTexts[this.welcomTextColumn][1]
              this.isWelcomeVisible = true
            },
            [],
            this,
            this.welcomeTextUpdateTimes[1]
          )
          .call(
            () => {
              this.isWelcomeVisible = false
            },
            [],
            this,
            '=+' + this.welcomTextDuration
          )
          .call(
            () => {
              this.welcomeText = this.welcomTexts[this.welcomTextColumn][2]
              this.isWelcomeVisible = true
            },
            [],
            this,
            this.welcomeTextUpdateTimes[2]
          )
          .call(
            () => {
              this.isWelcomeVisible = false
            },
            [],
            this,
            '=+' + this.welcomTextDuration
          )
          .call(
            () => {
              this.welcomeText = this.welcomTexts[this.welcomTextColumn][3]
              this.isWelcomeVisible = true
            },
            [],
            this,
            this.welcomeTextUpdateTimes[3]
          )
          .call(
            () => {
              this.isWelcomeVisible = false
            },
            [],
            this,
            '=+' + this.welcomTextDuration
          )
      }
    },
    killTimer() {
      if (this.welcomeTextTL) {
        this.welcomeTextTL.paused(true)
        this.welcomeTextTL.kill()
        this.welcomeTextTL = null
      }
    },
    setChatWelcomTextColumn(columnName) {
      this.welcomTextColumn = columnName
    },
    startMobileChatTimline() {
      this.welcomeTextTL = new TimelineLite()
        .call(
          () => {
            this.isWelcomeMobileVisible = true
          },
          [],
          this,
          5
        )
        .call(
          () => {
            this.isWelcomeMobileVisible = false
            this.welcomeTextTL.kill()
            this.welcomeTextTL = null
          },
          [],
          this,
          15
        )
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.watchTextareaSize()

      if (this.isSmallScreen) {
        this.startMobileChatTimline()
      } else {
        // Controlled by the beforeEach-Guard
        EventBus.$on('hideChatWelcomeBox', () => {
          this.killTimer()
          this.setWelcomeBoxVisibility(false)
        })
        EventBus.$on('setChatWelcomTextColumn', this.setChatWelcomTextColumn)
        EventBus.$on('restartChatTimeline', this.startTimer)
      }
    })
  },
}
</script>

<style lang="scss">
@import '~assets/scss/vars';

.welcome-box-wrapper {
  position: fixed;
  right: 15px;
  bottom: 30px;
  z-index: 9;
  width: 200px;
  cursor: pointer;
  transform: translateX(120%);
  transition: all 250ms ease-in-out;

  &.visible {
    transform: translateX(0%);
  }

  &:hover {
    > .welcome-box,
    > .welcome-box-shadow {
      animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    }
  }
}

.welcome-box,
.welcome-box-shadow {
  position: relative;
  background-color: $green;
  z-index: 2;
  line-height: 1.3;
  padding: 15px;
  font-weight: 900;

  &::before {
    content: '\A';
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 15px solid $green;
    position: absolute;
    right: -15px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.welcome-box-wrapper.mobil {
  width: 80px;

  .welcome-box,
  .welcome-box-shadow {
    box-sizing: border-box;
    width: 80px;
  }

  .welcome-box {
    display: flex;
    justify-content: center;
    padding: 20px 15px;

    span {
      width: 5px;
      height: 5px;
      background-color: #000;
      display: block;
      margin: 0 3px;
    }
  }
}

.welcome-box-shadow {
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.3);
  filter: blur(3px);
  position: absolute;
  width: 100%;
  height: 100%;
  box-sizing: border-box;

  &::before {
    border-left: 15px solid rgba(0, 0, 0, 0.3);
  }
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(0.5px, -0.5px, 0);
  }

  20%,
  80% {
    transform: translate3d(-0.5px, 0.5px, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(1px, -1px, 0);
  }

  40%,
  60% {
    transform: translate3d(-1px, 1px, 0);
  }
}

.chat-form-wrapper {
  position: fixed;
  right: 15px;
  bottom: 30px;
  background-color: #fff;
  z-index: 12;
  width: 280px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  padding: 30px 15px;
  transform: translateX(120%);
  transition: transform 250ms ease-in-out;

  &.visible {
    transform: translateX(0%);
  }

  .close {
    position: absolute;
    right: 20px;
    top: 20px;
    width: 20px;
    height: 20px;
    cursor: pointer;

    > div {
      transform: translateY(8px);
    }

    span {
      display: block;
      position: absolute;
      height: 2px;
      width: 20px;
      border-radius: 2px;
      opacity: 1;
      left: 0;
      background-color: #000;

      &:nth-child(1) {
        transform: rotate(45deg);
      }

      &:nth-child(2) {
        transform: rotate(-45deg);
      }
    }
  }

  .error {
    color: red;
  }

  .explanation {
    margin-top: 0;
    font-weight: 400;
    width: calc(100% - 30px);
  }

  textarea,
  input[type='email'] {
    border: none;
    font-weight: 400;
    font-family: Favorit, sans-serif;
    font-size: 16px;
    line-height: 1.4;
    background: transparent;

    &::placeholder {
      color: #999;
    }
  }

  textarea {
    resize: none;
    height: 100px;
    width: 100%;
    outline: none;
  }

  input[type='email'] {
    width: calc(100% - 40px);
    border-bottom: 2px solid #000;
    padding: 5px 0;
    outline: none;
    box-sizing: border-box;
    font-family: Favorit, sans-serif;
  }

  .submit-wrapper {
    position: absolute;
    right: 20px;
    bottom: 25px;
    height: 24px;
    width: 24px;

    input[type='submit'] {
      border: none;
      height: 24px;
      width: 24px;
      padding: none;
      cursor: pointer;
      background: transparent;
    }

    &::before {
      content: '';
      background-image: url('~assets/theme_icons/arrow-small-right.svg');
      height: 15px;
      width: 18px;
      background-size: contain;
      background-repeat: no-repeat;
      position: absolute;
      pointer-events: none;
      transition: 150ms all ease-in-out;
      right: 3px;
      top: 4px;
    }

    &:hover {
      &::before {
        transform: translateX(5px);
      }
    }
  }

  @media (max-width: 577px) {
    background: #000;
    box-shadow: none;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    box-sizing: border-box;
    transform: scaleX(1.2) scaleY(1.2);
    opacity: 0;
    pointer-events: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &.visible {
      transform: scaleX(1) scaleY(1);
      opacity: 1;
      pointer-events: auto;
    }

    form {
      position: relative;
      max-height: 90vh;
      overflow: hidden;
      overflow-y: auto;
    }

    .explanation,
    input,
    textarea,
    p {
      color: #fff;
      font-size: 20px;
    }

    input[type='email'] {
      border-color: #fff;
    }

    .submit-wrapper {
      &::before {
        background-image: url('~assets/theme_icons/arrow-small-right-weiss.svg');
      }
    }

    .close {
      position: absolute;
      right: 20px;
      top: 40px;
      width: 40px;
      height: 40px;

      > div {
        transform: translateY(8px);
      }

      span {
        height: 4px;
        width: 40px;
        border-radius: 4px;
        background-color: #fff;
      }
    }
  }
}
</style>
