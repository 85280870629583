<template>
  <div :class="getClasses()">
    <h2><slot /></h2>
  </div>
</template>

<script>
import classnames from 'classnames'

export default {
  name: 'ButtonCustom',
  props: {
    color: {
      type: String,
      default: '',
    },
  },
  data() {
    return {}
  },
  methods: {
    getClasses() {
      let colorClass = this.color !== '' ? `heading--${this.color}` : ''
      let blackIconsClass = this.color === 'green' ? 'black-icons' : ''
      return classnames('heading', colorClass, blackIconsClass)
    },
  },
  mounted() {},
}
</script>

<style lang="scss">
@import '~assets/scss/vars';

.heading {
  background-color: #000;
  color: $green;
  padding-top: 60px;
  padding-bottom: 60px;
  display: flex;
  justify-content: center;
  align-items: center;

  h2 {
    font-size: 60px;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    margin-top: 0;
    margin-bottom: 0;

    @media (max-width: 560px) {
      font-size: 35px;
    }
  }

  &--green {
    background-color: $green;
    color: #000;
  }

  &--transparent-green {
    background-color: transparent;
    color: $green;
  }
}
</style>
