<template>
  <div>
    <case-header :backgroundvid="headerBackgroundVideoUrl" :headerImgUrl="headerImgUrl">
      <h1 slot="h1-title" id="top-headline">Doppelleu Boxer</h1>
      <h3 slot="h3-title">Doppelleu Boxer</h3>
      <div slot="details">
        <p>
          <strong>?</strong>Wie unterstützt man als Agentur tatkräftig ein bereits stimmiges Marketingkonzept, ohne viel reinzureden? Die Doppelleu Boxer AG
          sind aus dem beschaulichen Winterthur 2012 gestartet, um mit einzigartigen Bierkonzepten die Gastronomie und den Detailhandel zu erobern.
        </p>
        <div class="tags">
          <p>B2C Campaign | Website | Packaging Design | Visual Imagery</p>
        </div>
      </div>
    </case-header>

    <div v-show="!caseIsLoading" id="case-content">
      <parallax-glider :imgurl="require('./../assets/case-studies/doppelleu/' + imgRes + 'bild-animation-boxer.jpg')"></parallax-glider>

      <grund-gedanke>
        <h3 slot="title">Doppelleu Boxer</h3>
        <p slot="text">
          <strong>!</strong>Durch die Fusion mit der Westschweizer Traditonsbiermarke «Boxer» im 2017 bekam das neue Sortiment einen neuen Markenauftritt.
          Entstanden ist eine Welt, welche die Natürlichkeit und Authentizität des Biers auf sympathische Art und Weise widerspiegeln soll.
        </p>
      </grund-gedanke>

      <parallaxer>
        <div slot="layers">
          <div
            class="layer"
            :style="{ 'background-image': 'url(' + require('./../assets/case-studies/doppelleu/parallaxer-boxer/' + imgRes + '01.jpg') + ')' }"
          ></div>
          <div
            class="layer"
            :style="{ 'background-image': 'url(' + require('./../assets/case-studies/doppelleu/parallaxer-boxer/' + imgRes + '02.jpg') + ')' }"
          ></div>
          <div
            class="layer"
            :style="{ 'background-image': 'url(' + require('./../assets/case-studies/doppelleu/parallaxer-boxer/' + imgRes + '03.jpg') + ')' }"
          ></div>
        </div>
      </parallaxer>

      <parallax-glider :imgurl="require('./../assets/case-studies/doppelleu/' + imgRes + 'bild-animation-chopfab.jpg')"></parallax-glider>

      <grund-gedanke>
        <h3 slot="title">Chopfab</h3>
        <p slot="text">
          <strong>!</strong>Wir haben den Chopfab-Bieren ein Gesicht gegeben! Ganz im Sinne von «Nicht saufen, sondern mit Freunden geniessen» haben wir eine
          Markenwelt entwickelt, die genau diese Philosophie wiederspiegelt. Packaging, B2B-Werbung, POS-Präsenz und auch die Webseite machen Lust auf ein
          herrlich kühles Bier unter Freunden.
        </p>
      </grund-gedanke>

      <parallax-glider :imgurl="require('./../assets/case-studies/doppelleu/' + imgRes + 'bild-animation-chopfab-2.jpg')"></parallax-glider>

      <section class="laptop-section laptop-section-a black-icons">
        <video v-if="!isU577" id="laptopvideo-doppelleu" class="video" autoplay muted loop>
          <source src="~assets/case-studies/doppelleu/laptop_chobfab.mp4" type="video/mp4" />
          <source src="~assets/case-studies/doppelleu/laptop_chobfab.webm" type="video/webm" />
        </video>
        <img v-if="isU577" class="laptop-mobile" src="~assets/case-studies/doppelleu/chopfab-laptop.jpg" />
      </section>

      <parallaxer>
        <div slot="layers">
          <div
            class="layer"
            :style="{ 'background-image': 'url(' + require('./../assets/case-studies/doppelleu/parallaxer-chopfab/' + imgRes + '01.jpg') + ')' }"
          ></div>
          <div
            class="layer"
            :style="{ 'background-image': 'url(' + require('./../assets/case-studies/doppelleu/parallaxer-chopfab/' + imgRes + '02.jpg') + ')' }"
          ></div>
          <div
            class="layer"
            :style="{ 'background-image': 'url(' + require('./../assets/case-studies/doppelleu/parallaxer-chopfab/' + imgRes + '03.jpg') + ')' }"
          ></div>
          <div
            class="layer"
            :style="{ 'background-image': 'url(' + require('./../assets/case-studies/doppelleu/parallaxer-chopfab/' + imgRes + '04.jpg') + ')' }"
          ></div>
        </div>
      </parallaxer>

      <section class="slider-section slider-section-1 black-icons">
        <carousel :perPage="1" :navigationEnabled="true" :loop="true">
          <slide>
            <img :src="require('./../assets/case-studies/doppelleu/slider-chopfab/' + imgRes + '01.jpg')" />
          </slide>
          <slide>
            <img :src="require('./../assets/case-studies/doppelleu/slider-chopfab/' + imgRes + '02.jpg')" />
          </slide>
          <slide>
            <img :src="require('./../assets/case-studies/doppelleu/slider-chopfab/' + imgRes + '03.jpg')" />
          </slide>
          <slide>
            <img :src="require('./../assets/case-studies/doppelleu/slider-chopfab/' + imgRes + '04.jpg')" />
          </slide>
          <slide>
            <img :src="require('./../assets/case-studies/doppelleu/slider-chopfab/' + imgRes + '05.jpg')" />
          </slide>
        </carousel>
      </section>

      <parallax-glider :imgurl="require('./../assets/case-studies/doppelleu/' + imgRes + 'bild-animation-doppelleu.jpg')"></parallax-glider>

      <grund-gedanke>
        <h3 slot="title">Doppelleu</h3>
        <p slot="text">
          <strong>!</strong>
          Das Doppelleu-Sortiment stellte uns vor eine neue Herausforderung! «Ein Bier kann auch wie ein guter Wein sein!» Wir mussten also treffend in Szene
          setzen, dass dieses Sortiment tatsächlich eine Alternative zu edlem Rebensaft sein kann. Weinwerbung für Bier, ein schönes Abendessen im Kerzenlicht,
          ein Tête-a-Tête, warum nicht?
          <br /><br />
          <strong>=</strong>Die Doppelleu Boxer AG, obwohl erst 2012 an den Start gegangen, sind heute Marktführer im Teilmarkt obergäriger Biere. Ihre «Guten
          Tropfen» sind aus Restaurants, Bars und den Coop-Filialen nicht mehr wegzudenken. Wir durften den Siegeszug unterstützen. Danke Philip und Jörg.
        </p>
      </grund-gedanke>

      <section class="laptop-section laptop-section-b black-icons">
        <video v-if="!isU577" id="laptopvideo-doppelleu" class="video" autoplay muted loop>
          <source src="~assets/case-studies/doppelleu/laptop_doppelleu.mp4" type="video/mp4" />
          <source src="~assets/case-studies/doppelleu/laptop_doppelleu.webm" type="video/webm" />
        </video>
        <img v-if="isU577" class="laptop-mobile" src="~assets/case-studies/doppelleu/doppelleu-laptop.jpg" />
      </section>

      <parallaxer>
        <div slot="layers">
          <div
            class="layer"
            :style="{ 'background-image': 'url(' + require('./../assets/case-studies/doppelleu/parallaxer-doppelleu/' + imgRes + '01.jpg') + ')' }"
          ></div>
          <div
            class="layer"
            :style="{ 'background-image': 'url(' + require('./../assets/case-studies/doppelleu/parallaxer-doppelleu/' + imgRes + '02.jpg') + ')' }"
          ></div>
          <div
            class="layer"
            :style="{ 'background-image': 'url(' + require('./../assets/case-studies/doppelleu/parallaxer-doppelleu/' + imgRes + '03.jpg') + ')' }"
          ></div>
          <div
            class="layer"
            :style="{ 'background-image': 'url(' + require('./../assets/case-studies/doppelleu/parallaxer-doppelleu/' + imgRes + '04.jpg') + ')' }"
          ></div>
        </div>
      </parallaxer>

      <section class="slider-section slider-section-1 black-icons">
        <carousel :perPage="1" :navigationEnabled="true" :loop="true">
          <slide>
            <img :src="require('./../assets/case-studies/doppelleu/slider-doppelleu/' + imgRes + '01.jpg')" />
          </slide>
          <slide>
            <img :src="require('./../assets/case-studies/doppelleu/slider-doppelleu/' + imgRes + '02.jpg')" />
          </slide>
          <slide>
            <img :src="require('./../assets/case-studies/doppelleu/slider-doppelleu/' + imgRes + '03.jpg')" />
          </slide>
          <slide>
            <img :src="require('./../assets/case-studies/doppelleu/slider-doppelleu/' + imgRes + '04.jpg')" />
          </slide>
          <slide>
            <img :src="require('./../assets/case-studies/doppelleu/slider-doppelleu/' + imgRes + '05.jpg')" />
          </slide>
        </carousel>
      </section>

      <counter-footer :counters="footerCounters"></counter-footer>

      <case-footer></case-footer>
    </div>
  </div>
</template>

<script>
import CaseHeader from '../components/Case-Header.vue'
import CaseFooter from '../components/Case-Footer.vue'
import Grundgedanke from '../components/Grundgedanke.vue'
import Parallaxer from '../components/Parallaxer.vue'
import CounterFooter from '../components/Counter-Footer.vue'
import CaseLoadStatusMixin from '../mixins/case-load-status'
import ParallaxGlider from '../components/Parallax-Glider.vue'

export default {
  name: 'doppelleu',
  components: {
    'case-header': CaseHeader,
    'case-footer': CaseFooter,
    'grund-gedanke': Grundgedanke,
    parallaxer: Parallaxer,
    'parallax-glider': ParallaxGlider,
    'counter-footer': CounterFooter,
  },
  mixins: [CaseLoadStatusMixin],
  data() {
    return {
      headerBackgroundVideoUrl: require('./../assets/case-studies/doppelleu/header.mp4'),
      headerImgUrl: require('./../assets/case-studies/doppelleu/low-bild-animation-chopfab.jpg'),
      footerCounters: [
        {
          score: 0,
          max: 28,
          title: '28 Shooting-Tage für die perfekte Bildwelt aufgewendet.',
        },
        {
          score: 0,
          max: -1,
          title: 'Adventskalender ProBier war ausverkauft, bevor er überhaupt produziert war.',
        },
        {
          score: 0,
          max: 1,
          title: '(Bereits) Schweizer Marktführer bei obergärigen Bieren.',
        },
      ],
    }
  },
  computed: {
    imgRes() {
      return window.innerWidth <= 577 ? 'low-' : ''
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.initilizeLoad(() => {})
    })
  },
  beforeDestroy() {
    this.$store.commit('removeScrollMagicScenes', 'doppelleu')
  },
}
</script>

<style lang="scss" scoped></style>
