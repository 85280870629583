import Vue from 'vue'
import Vuex from 'vuex'
import Routes from './routes'
import Cases from './cases'
import ScrollMagic from 'ScrollMagic'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isTouch: false,
    isU577: false,
    domain: 'http://localhost:8080/#/',
    routes: Routes,
    cases: Cases,
    scrollMagicController: new ScrollMagic.Controller({ refreshInterval: 100 }),
    scrollMagicScenes: [],
    transitionLayerVisible: false,
    toHomeIsVisible: false,
    menuInIsVisible: false,
    lastView: false,
    animation: {},
    nextPageTransition: false,
    overlayMenuvisible: false,
    overlayIsBlack: false,
    menuOutIsVisible: false,
    transitionLayerisOnTop: false,
    allowScrollManualySites: ['#/sidrosan'],
    toggleIsClose: false,
    toggleIsBlack: false,
    logoIsBlack: false,
    homeVideoIsPlayed: false,
    homeVideoIsVisible: true,
    toggleIsHovered: false,
    logoIsHovered: false,
    mobilNavHeaderIsVisible: false,
    isWhiteBackground: false,
    forcedIconColor: false,
    isContactOverlayVisible: false,
  },
  getters: {
    isU577: (state) => {
      return window.innerWidth <= 577
    },
    is578to900: (state) => {
      return window.innerWidth > 577 && window.innerWidth <= 900
    },
    isU900: (state) => {
      return window.innerWidth <= 900
    },
    is578to900: (state) => {
      return window.innerWidth > 577 && window.innerWidth <= 900
    },
    isTouch: (state) => {
      return 'ontouchstart' in document.documentElement
    },
    toggleIsBlack: (state) => {
      // Icon Hover = Schwarz
      if (state.toggleIsHovered || state.forcedIconColor == 'black') {
        return true
      } else {
        // Overlay ist offen = Weiß
        if (state.overlayMenuvisible) {
          // Während der Animation zur Landingapge
          if (state.nextPageTransition == 'toHome--fromLogo') {
            return true
            // Wenn einfach nur das Overlay offen ist.
          } else {
            return false
          }
        } else {
          // Mobil Nav ist da = Weiß
          if (state.mobilNavHeaderIsVisible) {
            return false
          } else {
            // Hintergrund ist Weiß
            if (state.isWhiteBackground) {
              return true
              // Hintergrund ist Schwarz
            } else {
              return false
            }
          }
        }
      }
    },
    logoIsBlack: (state) => {
      // Icon Hover = Schwarz
      if (state.logoIsHovered) {
        return true
      } else if (state.forcedIconColor == 'black') {
        return true
      } else {
        // Overlay ist offen = Weiß
        if (state.overlayMenuvisible) {
          // Während der Animation zur Landingapge
          if (state.nextPageTransition == 'toHome--fromLogo') {
            return true
            // Wenn einfach nur das Overlay offen ist.
          } else {
            return false
          }
        } else {
          // Mobil Nav ist da = Weiß
          if (state.mobilNavHeaderIsVisible) {
            return false
          } else {
            // Hintergrund ist Weiß
            if (state.isWhiteBackground) {
              return true
              // Hintergrund ist Schwarz
            } else {
              return false
            }
          }
        }
      }
    },
    isContactOverlayVisible: (state) => state.isContactOverlayVisible,
  },
  mutations: {
    addScrollMagicScene(state, scene) {
      // 1. Fall: Der Namespace ist speziell definiert, z.B. nötig in componenten
      if (scene.namespace) {
        var { namespace, scene } = scene

        // 2.  Fall Der Namespace wird von der URL abgeleitet. Für Views
      } else {
        var namespace = window.location.pathname
        if ((namespace = ' ')) {
          namespace = 'home'
        }
      }
      state.scrollMagicController.addScene(scene)
      state.scrollMagicScenes.push({ scene: scene, namespace: namespace })
    },
    removeScrollMagicScenes(state, namespace) {
      if (state.scrollMagicScenes.length > 0) {
        state.scrollMagicScenes.forEach((el, ind) => {
          if (el.namespace == namespace) {
            el.scene.destroy()
          }
        })
      }
    },
    addAnimation(state, data) {
      state.animation[data.name] = data.animation
    },
    setNextPageTransition(state, name) {
      state.nextPageTransition = name
    },
    hideToHome(state) {
      state.toHomeIsVisible = false
    },
    showToHome(state) {
      state.toHomeIsVisible = true
    },
    saveLastView(state, el) {
      state.lastView = el
    },
    changeToggleToClose(state) {
      state.toggleIsClose = true
      TweenMax.fromTo(
        '#overlay-menu-toggle > .toggle-img > span:nth-child(1)',
        1.6,
        { y: 6, rotation: 0 },
        { rotation: 135, ease: Elastic.easeOut.config(0.8, 0.4) }
      )
      TweenMax.fromTo(
        '#overlay-menu-toggle > .toggle-img > span:nth-child(2)',
        0.8,
        { y: -6, rotation: 0 },
        { rotation: 45, delay: 0.2, ease: Elastic.easeOut.config(0.8, 0.4) }
      )
    },
    changeCloseToToggle(state) {
      state.toggleIsClose = false
      TweenMax.to('#overlay-menu-toggle > .toggle-img > span:nth-child(2)', 0.1, { rotation: 0 })
      TweenMax.to('#overlay-menu-toggle > .toggle-img > span:nth-child(1)', 0.2, { rotation: 0, delay: 0.2 })
      TweenMax.to('#overlay-menu-toggle > .toggle-img > span:nth-child(1)', 0.2, { y: 0, delay: 0.8 })
      TweenMax.to('#overlay-menu-toggle > .toggle-img > span:nth-child(2)', 0.2, { y: 0, delay: 0.8 })
    },
    forceIconsBlack(state) {
      state.forcedIconColor = 'black'
    },
    dontForceIconsColor(state) {
      state.forcedIconColor = false
    },
    setMobilNavHeaderIsVisible(state) {
      state.mobilNavHeaderIsVisible = true
    },
    setMobilNavHeaderIsNotVisible(state) {
      state.mobilNavHeaderIsVisible = false
    },

    setTransitionLayerToTop(state) {
      state.transitionLayerisOnTop = true
    },
    setTransitionLayerToBackground(state) {
      state.transitionLayerisOnTop = false
    },
    setHomeVideoPlayed(state) {
      state.homeVideoIsPlayed = true
    },
    setHomeVideoToInvisible(state) {
      state.homeVideoIsVisible = false
    },
    setToggleIsHovered(state) {
      state.toggleIsHovered = true
    },
    setToggleIsNotHovered(state) {
      state.toggleIsHovered = false
    },
    setLogoIsHovered(state) {
      state.logoIsHovered = true
    },
    setLogoIsNotHovered(state) {
      state.logoIsHovered = false
    },
    setLastPath(state, path) {
      this.state.lastPath = path
    },
    setLastInstaScrollPos(state, pos) {
      this.state.lastInstaScrollPos = pos
    },
    setIsContactOverlayVisible(state, newState) {
      state.isContactOverlayVisible = newState
    },
  },
  actions: {
    showContactOverlay({ commit, dispatch, state }) {
      dispatch('forbidScroll')
      commit('setIsContactOverlayVisible', true)
    },
    hideContactOverlay({ commit, dispatch, state }) {
      dispatch('allowScroll')
      commit('setIsContactOverlayVisible', false)
    },
    showTransitionLayer({ commit, state }) {
      state.transitionLayerVisible = true
    },
    hideTransitionLayer({ commit, state }) {
      state.transitionLayerVisible = false
    },
    playAnimation({ commit, state }, name) {
      state.animation[name].play()
    },
    resetAnimation({ commit, state }, name) {
      state.animation[name].goToAndStop(0)
    },
    hideOverlayMenu({ commit, state, dispatch }) {
      commit('changeCloseToToggle')
      state.overlayMenuvisible = false
      state.overlayIsBlack = false
    },
    hideOverlayMenuWithAnimation({ commit, state, dispatch }) {
      commit('changeCloseToToggle')
      dispatch('handleScrollToDetectIconColor')
      state.transitionLayerVisible = true
      state.transitionLayerisOnTop = true
      state.menuOutIsVisible = true
      state.animation['menuOut'].play()
      setTimeout(() => {
        state.overlayMenuvisible = false
        dispatch('allowScroll')
        dispatch('handleScrollToDetectIconColor')
      }, 100)
      setTimeout(() => {
        state.transitionLayerVisible = false
        state.transitionLayerisOnTop = false
        state.animation['menuOut'].goToAndStop(0)
        state.menuOutIsVisible = false
        state.overlayIsBlack = false
      }, 1400)
    },
    showOverlayMenu({ commit, state, dispatch }) {
      state.menuInIsVisible = true
      state.transitionLayerisOnTop = false
      state.transitionLayerVisible = true
      state.menuInIsVisible = true
      document.querySelector('#overlay-menu > .content-container').scrollTo(0, 0)
      state.animation['menuIn'].play()
      dispatch('pushToggleStripesTogether')
      setTimeout(() => {
        commit('forceIconsBlack')
      }, 300)
      setTimeout(() => {
        commit('changeToggleToClose')
      }, 800)
      setTimeout(() => {
        commit('dontForceIconsColor')
        state.overlayMenuvisible = true
      }, 1300)
      setTimeout(() => {
        state.animation['menuIn'].goToAndStop(0)
        state.transitionLayerVisible = false
        state.overlayIsBlack = true
        state.menuInIsVisible = false
        dispatch('forbidScroll', true)
      }, 2000)
    },
    pushToggleStripesTogether() {
      TweenMax.to('#overlay-menu-toggle > .toggle-img > span:nth-child(1)', 0.2, { y: 6 })
      TweenMax.to('#overlay-menu-toggle > .toggle-img > span:nth-child(2)', 0.2, { y: -6 })
    },
    forbidScroll({ commit, state }, withFixed = false) {
      var position = withFixed ? 'fixed' : 'inherit'
      state.lastScrollPosition = window.pageYOffset
      TweenMax.set('html', { overflowY: 'hidden', height: '100vh', position: position })
    },
    allowScroll({ commit, state }) {
      TweenMax.set('html', { clearProps: 'all' })
      window.scrollTo(0, state.lastScrollPosition)
    },
    handleScrollToDetectIconColor({ commit, state }) {
      var blackIconsAreas = document.querySelectorAll('.active-view .black-icons')
      var isBlack = false
      if (blackIconsAreas.length) {
        for (var i = 0; i < blackIconsAreas.length; i++) {
          var el = blackIconsAreas[i]
          if (!isBlack) {
            var elDimen = el.getBoundingClientRect()
            if (elDimen.top < 80 && elDimen.bottom > 80) {
              state.isWhiteBackground = true
              isBlack = true
            } else {
              state.isWhiteBackground = false
            }
          }
        }
      } else {
        state.isWhiteBackground = false
      }
    },
    animateWorkArrowIn({ commit, state }) {
      TweenMax.set('.scroll-down-on-home-arrow .font span', { y: 0 })
      TweenMax.set('.scroll-down-on-home-arrow img', { y: -50, alpha: 0 })
      TweenMax.set('.scroll-down-on-home-arrow .arrow-extention', { alpha: 0, height: 30, y: 0 })
      TweenMax.to('.scroll-down-on-home-arrow img', 0.8, { y: 0, alpha: 1 })
      TweenMax.to('.scroll-down-on-home-arrow .font span', 0.5, { delay: 1.2, alpha: 1 })
      TweenMax.to('.scroll-down-on-home-arrow .arrow-extention', 0.1, { delay: 1.7, alpha: 1 })
    },
    resetHome({ commit, state }) {
      TweenMax.set('.scroll-down-on-home-arrow .font span', { clearProps: 'all' })
      TweenMax.set('.scroll-down-on-home-arrow img', { clearProps: 'all' })
      TweenMax.set('.scroll-down-on-home-arrow .arrow-extention', { clearProps: 'all' })
      TweenMax.set('.caseStudie h3', { clearProps: 'all' })
      var activeCases = document.querySelectorAll('.activeCaseInScroller, .fixedHover')
      if (activeCases) {
        for (var i = 0; i < activeCases.length; i++) {
          activeCases[i].classList.remove('activeCaseInScroller')
          activeCases[i].classList.remove('fixedHover')
        }
      }
    },
  },
})
