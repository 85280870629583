<template>
  <div :class="getClasses()">
    <router-link v-if="userRouterLink" class="button__link asd" :to="link" v-on:click.native="setPageTransitionToSlideFromBottom">
      <slot />
    </router-link>
    <a v-else class="button__link" :href="link" :download="download">
      <slot />
    </a>
  </div>
</template>

<script>
import classnames from 'classnames'

export default {
  name: 'ButtonCustom',
  props: {
    link: {
      type: [String, Object],
      default: function () {
        return { path: '/' }
      },
    },
    align: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: '',
    },
    download: {
      type: String,
      default: '',
    },
  },
  computed: {
    userRouterLink() {
      return typeof this.link == 'object'
    },
  },
  methods: {
    getClasses() {
      let alignClass = this.align !== '' ? `button--${this.align}` : ''
      let colorClass = this.color !== '' ? `button--${this.color}` : ''
      return classnames('button', alignClass, colorClass)
    },
    setPageTransitionToSlideFromBottom() {
      this.$store.commit('setNextPageTransition', 'slideFromBottom')
    },
  },
  mounted() {},
}
</script>

<style lang="scss">
@import '~assets/scss/vars';

.button {
  display: flex;

  &__link {
    padding: 20px 60px;
    font-weight: 500;
    font-size: 20px;
    background-color: #fff;
    color: #000;
    transition: background-color 100ms ease-in-out;

    &:hover {
      background-color: rgba(255, 255, 255, 0.7);
    }
  }

  &--black {
    .button__link {
      background-color: #000;
      color: $green;

      &:hover {
        background-color: rgba(0, 0, 0, 0.7);
      }
    }
  }

  &--center {
    .button__link {
      margin: 0 auto;
      display: inline-block;
    }
  }
}
</style>
