<template>
  <div>
    <case-header class="header-section--economiesuisse" :backgroundvid="headerBackgroundImgUrl" :headerImgUrl="headerImgUrl">
      <h1 slot="h1-title" id="top-headline">Economiesuisse</h1>
      <h3 slot="h3-title">Economiesuisse</h3>
      <div slot="details">
        <p>
          <strong>?</strong>Wer an Abstimmungsplakate denkt, stellt sich normalerweise nichts Emotionales vor. Zweidimensionale Piktogramme mit grosser Überschrift 
          und langweilige «Alles-schon-mal-gesehen»-Visuals waren lange Zeit die Norm. Als Kreativagentur gehen wir aber auch politische Kampagnen etwas anders an. 
          Wir sind stets bestrebt, auch trockeneren Themen Emotionalität zu entlocken und Gefühle zu wecken. Denn so stolz wie die Schweiz auf die direkte 
          Demokratie ist, so emotional diskutiert sie auch über jede Abstimmung. Wir durften in den vergangenen Jahren verschiedene Abstimmungen hautnah begleiten 
          und neue Wege für die Kommunikation von politischen Kampagnen gehen. Mit Erfolg. Unsere Kampagnen haben bewiesen, dass auch politische Kampagnen kreativ 
          gestaltet werden können und so eine noch stärkere Wirkung entfalten. Wir sind glücklich über unsere Pionierleistung und freuen uns, auch in Zukunft für 
          unsere direkte Demokratie unterstützen zu dürfen.
        </p>
      </div>
    </case-header>

    <div v-show="!caseIsLoading" id="case-content">
      <grund-gedanke>
        <h3 slot="title">Verrechnungs-<br />steuer</h3>
        <p slot="text">
          <strong>!</strong>Aufgrund der Verrechnungssteuer erwächst der Schweizer Wirtschaft im internationalen Vergleich ein Nachteil. Höchste Zeit für 
          eine Reform, fand das Schweizer Parlament und beschloss, die Verrechnungssteuer und die Umsatzabgabe für Schweizer Obligationen abzuschaffen. 
          Dagegen wurde das Referendum ergriffen und es kam am 25. September 2022 zur Abstimmung. Nach gewonnenem Pitch durften wir im Auftrag des 
          Wirtschaftsverbands economiesuisse die Befürworter-Kampagne gestalten. <br /> <br />
          <strong>=</strong>Ohne die Reform ist die Schweiz aufgrund von Mehrkosten für Anleger weniger attraktiv. Sie werden durch die Verrechnungssteuer 
          verscheucht. Anstelle eines grossen Stoppschildes, haben wir die Metapher in ein bildgewaltiges Abstimmungsplakat umgesetzt, das auffällt und in 
          Erinnerung bleibt. Die Botschaft war klar. Mit den Anlegern verliert die Schweiz auch Arbeitsplätze und Kapital. Ein Sturm droht aufzuziehen. 
          Die Headline sollte Sujet mit Abstimmungsempfehlung verbinden und Nachdruck verleihen. <br />
          Das Visual fand überall so viel Anklang, dass verschiedene Parteien das Kampagnensujet für ihre eigenen Kampagnen adaptierten. Die schweizweite 
          Abstimmungskampagne war nicht nur auf Plakaten zu sehen, sondern wurde crossmedial ausgestrahlt. Wir haben die Vogelscheuchen auch in Natura 
          realisiert und an Wahlveranstaltungen sowie Medienanlässen in der ganzen Schweiz eingesetzt. Am Ende war die Abstimmung sehr knapp, doch sie ging 
          leider verloren.
        </p>
      </grund-gedanke>

      <section class="slider-section slider-section-1">
        <carousel
          :paginationEnabled="false"
          navigationPrevLabel="<span class='arrow'><span>"
          navigationNextLabel="<span class='arrow'><span>"
          class="small-navigation"
          :perPage="1"
          :navigationEnabled="true"
          :loop="true"
        >
          <slide v-for="slideIndex in 3" :key="slideIndex">
            <img :src="require(`./../assets/case-studies/economiesuisse/plakate/Vogelscheuche_Case_2500x1406_${slideIndex}.jpg`)" />
          </slide>
        </carousel>
      </section>

      <section class="img-section-a">
        <img src="./../assets/case-studies/economiesuisse/Vogelscheuche_Case_2500x1406_4.jpg" />
        <img src="./../assets/case-studies/economiesuisse/Vogelscheuche_Case_2500x1406_5.jpg" />
      </section>

      <counter-footer :counters="footerCounters1"></counter-footer>

      <grund-gedanke>
        <h3 slot="title">Steuer-<br />initiative</h3>
        <p slot="text">
          <strong>!</strong>Die sogenannte 99-Prozent-Initiative (eidg. Volksinitiative «Löhne entlasten, Kapital gerecht besteuern») der Jungsozialisten 
          hatte es sich zum Ziel gesetzt, Kapitaleinkommen massiv höher zu besteuern. Ab einem Freibetrag, der nicht klar definiert wurde sogar eine 
          Besteuerung zu 150 Prozent vorgeschlagen. Betroffen waren aber nicht nur die obersten 1%, wie es die Initiative verkündete. Sondern auch KMU, 
          Familienunternehmen, Start-ups, Kleinanleger, Hauseigentümer und die Landwirtschaft wären davon betroffen gewesen. <br /> <br />
          <strong>=</strong>Der Wirtschaftsverband economiesuisse startete eine Gegenoffensive und wir bekamen einmal mehr den Zuschlag für die schweizweite 
          Kampagne. Aufgrund der Sachlage war schnell klar, dass die so oft in die Luft gehaltene «Faust der Gerechtigkeit» für einmal nicht ganz so gerecht, 
          war, wie dargestellt. Weit mehr als 1 % des Kapitals war betroffen und dies galt es, deutlich zu machen. Also visualisierten wir diese Unwahrheit in 
          Form eines faustförmigen Eisberges, dessen Grossteil ebenfalls unter der Oberfläche liegt. Genauso wie die Wahrheit hinter der Initiative. Mit Erfolg. 
          Die crossmediale Kampagne fand grossen Anklang, wurde mehrfach aufgegriffen und die Initiative wurde abgelehnt. Und Die Abstimmung war ein 
          voller Erfolg. Gewonnen!
        </p>
      </grund-gedanke>

      <parallax-glider :imgurl="require('./../assets/case-studies/economiesuisse/Economiesuisse_Keyvisual_Eisfaust_Feindaten.jpg')" :triggerHook="0.6" />

      <section class="slider-section slider-section-1">
        <carousel
          :paginationEnabled="false"
          navigationPrevLabel="<span class='arrow'><span>"
          navigationNextLabel="<span class='arrow'><span>"
          class="small-navigation"
          :perPage="1"
          :navigationEnabled="true"
          :loop="true"
        >
          <slide v-for="slideIndex in 2" :key="slideIndex">
            <img :src="require(`./../assets/case-studies/economiesuisse/plakate/ECO_1920x1080px_${slideIndex}.jpg`)" />
          </slide>
        </carousel>
      </section>
      
      <section class="img-section-a avoid-thin-line">
        <img src="./../assets/case-studies/economiesuisse/Flyer_ECO_1920x1080px.jpg" />
      </section>

      <section class="slider-section slider-section-1">
        <carousel
          :paginationEnabled="false"
          navigationPrevLabel="<span class='arrow'><span>"
          navigationNextLabel="<span class='arrow'><span>"
          class="small-navigation"
          :perPage="1"
          :navigationEnabled="true"
          :loop="true"
        >
          <slide v-for="slideIndex in 3" :key="slideIndex">
            <img :src="require(`./../assets/case-studies/economiesuisse/plakate/Plakate_ECO_1920x1080px_${slideIndex}.jpg`)" />
          </slide>
        </carousel>
      </section>

      <section class="section---Eisfaust-video avoid-thin-line">
        <video-player 
        :videourl="require('./../assets/case-studies/economiesuisse/videos/Eisfaust_Case_.mp4')" 
        :poster="require('./../assets/case-studies/economiesuisse/videos/Eisfaust_Case_.jpg')"/>
      </section>

      <counter-footer :counters="footerCounters2"></counter-footer>

      <grund-gedanke>
        <h3 slot="title">Indonesien-<br />abkommen</h3>
        <p slot="text">
          <strong>!</strong>Gemeinsam mit einer breiten Allianz aus Politik und Wirtschaft wollte der Wirtschaftsverband economiesuisse die Ja-Kampagne zum 
          Freihandelsabkommen mit Indonesien lancieren. In der Abstimmung ging es darum, ob das Freihandelsabkommen mit Indonesien ratifiziert oder abgelehnt 
          werden soll. Die erste derartige Abstimmung seit einem halben Jahrhundert. Wir nahmen teil am Pitch-Verfahren und bekamen mit unserem 
          unkonventionellen und emotionalen Ansatz den Zuschlag. <br /> <br />
          <strong>=</strong>Wenn es um den Handel mit Indonesien geht, denken viele an umweltschädigende Palmölplantagen. Aber dieses fortschrittliche 
          Abkommen kommt nicht nur dem Handel, sondern auch den Arbeitnehmenden und der Umwelt zugute. Gerade weil nur nachhaltig angebautes 
          Palmöl von Zollerleichterungen profitieren. Es ist ein Vertrag, von dem alle Seiten eindeutig profitieren. Er besiegelt eine Partnerschaft auf 
          Augenhöhe. Diese Partnerschaft haben wir in ein emotionales Visual umgemünzt und unsere Nationaltiere gross in Szene gesetzt. Schweizweit und 
          crossmedial. Die Abstimmung wurde klar gewonnen. 
        </p>
      </grund-gedanke>

      <parallax-glider :imgurl="require('./../assets/case-studies/economiesuisse/TigerBaer_cw_v4_300dpi.jpg')" :triggerHook="0.6"/>

      <section class="slider-section slider-section-1">
        <carousel
          :paginationEnabled="false"
          navigationPrevLabel="<span class='arrow'><span>"
          navigationNextLabel="<span class='arrow'><span>"
          class="small-navigation"
          :perPage="1"
          :navigationEnabled="true"
          :loop="true"
        >
          <slide v-for="slideIndex in 4" :key="slideIndex">
            <img :src="require(`./../assets/case-studies/economiesuisse/plakate/Indonesienabkommen_Case_2500x1406_${slideIndex}.jpg`)" />
          </slide>
        </carousel>
      </section>

      <section class="slider-section slider-section-1 avoid-thin-line">
        <carousel
          :paginationEnabled="false"
          navigationPrevLabel="<span class='arrow'><span>"
          navigationNextLabel="<span class='arrow'><span>"
          class="small-navigation"
          :perPage="1"
          :navigationEnabled="true"
          :loop="true"
        >
          <slide>
            <video-player
              :videourl="require('./../assets/case-studies/economiesuisse/videos/Indonesienabkommen_Case_2500x1406_tablet1.mp4')"
              :poster="require('./../assets/case-studies/economiesuisse/videos/Indonesienabkommen_Case_2500x1406_tablet1.jpg')"
            >
            </video-player>
          </slide>
          <slide>
            <video-player
              :videourl="require('./../assets/case-studies/economiesuisse/videos/Indonesienabkommen_Case_2500x1406_tablet2.mp4')"
              :poster="require('./../assets/case-studies/economiesuisse/videos/Indonesienabkommen_Case_2500x1406_tablet2.jpg')"
            >
            </video-player>
          </slide>
        </carousel>
      </section>

      <counter-footer :counters="footerCounters3"></counter-footer>

      <grund-gedanke>
        <h3 slot="title">AHV-<br />Steuervorlage</h3>
        <p slot="text">
          <strong>!</strong>Am 19. Mai 2019 kam es zur Abstimmung für die «AHV- und Steuervorlage». Eine der bis anhin wichtigsten Abstimmungen für die 
          Schweizer Wirtschaft. Für die crossmediale schweizweite Kampagne schrieb der Wirtschaftsverbands economiesuisse einen Pitch im mehrstufigen 
          Auswahlverfahren aus. Mit unserer Kreativität konnten wir uns in allen Etappen gegen die starke Konkurrenz durchsetzen und freuten uns 
          ausserordentlich, für economiesuisse kreativ zu sein. <br /> <br />
          <strong>=</strong>Die Vorlage hatte nach ausführlicher Analyse viele Vorteile für die Schweizer Wirtschaft vorzuweisen und vereinte vor allem 
          zwei grosse Pluspunkte. Erstens die AHV-Finanzierung und zweitens die Unternehmenssteuerreform. Also setzten wir die beiden Punkte ins Zentrum 
          der Kampagne und kreierten aus dem Schweizer Kreuz, das ja bereits ein Pluszeichen darstellt, ein Doppelkreuz aus zwei Pluszeichen.
          Unsere Botschaft und unser Sujet verbreiteten sich schweizweit offline, online und auf Social Media wie ein Lauffeuer über alle Werbemittel hinweg. 
          Zur Lancierung des Kampagnenstarts kooperierten wir mit dem bekannten Lichtkünstler Gerry Hofstetter, der eine Lichtprojektion im Berner Oberland 
          mit unserem Doppelkreuz inszenierte. Zudem haben Stände-, National- und Regierungsräte symbolträchtig mitgearbeitet, um den Willen zur 
          Zusammenarbeit und die Fähigkeit Kompromisse zu schliessen, darzustellen. Ueli Maurer trug zum Beispiel während der Kampagne einen Pin an seinem 
          Anzug mit dem Doppelkreuz. Wir sind stolz, die ganze Schweiz mit unserer Botschaft erreicht und zum Sieg bei der Abstimmung verholfen zu haben. 
          Und wir freuen uns über die positive Resonanz, die uns diese Kampagne bescherte – und auch heute noch beschert.
        </p>
      </grund-gedanke>

      <parallax-glider-three class="parallax-glider2" :duration="duration" :imgurl="require('./../assets/case-studies/economiesuisse/ahv_hochformat.jpg')" :triggerHook="triggerHook" />

      <section class="slider-section slider-section-1">
        <carousel
          :paginationEnabled="false"
          navigationPrevLabel="<span class='arrow'><span>"
          navigationNextLabel="<span class='arrow'><span>"
          class="small-navigation"
          :perPage="1"
          :navigationEnabled="true"
          :loop="true"
        >
          <slide v-for="slideIndex in 3" :key="slideIndex">
            <img :src="require(`./../assets/case-studies/economiesuisse/plakate/Case_Doppelkreuz_1920x1080_${slideIndex}.jpg`)" />
          </slide>
        </carousel>
      </section>

      <section>
        <video :src="require('./../assets/case-studies/economiesuisse/videos/Flag_Main_7s_HD_v001.mp4')" muted playsinline loop autoplay></video>
      </section>

      <section class="section---Steuervorlage-video avoid-thin-line">
        <video-player 
        :videourl="require('./../assets/case-studies/economiesuisse/videos/Trailer_Economiesuisse_3min_DE.mp4')"
        :poster="require('./../assets/case-studies/economiesuisse/Trailer_Economiesuisse_3min_DE.jpg')" />
      </section>

      <counter-footer :counters="footerCounters4"></counter-footer>

      <grund-gedanke>
        <h3 slot="title">Helvetia</h3>
        <p slot="text">
          <strong>!</strong>Seit der Bundesrat die Verhandlungen über ein Rahmenabkommen mit der EU im Mai 2021 abgebrochen hatte, herrschte Stillstand 
          in der Schweizer Europapolitik. Die Schweiz war durch widersprüchliche Partikularinteressen verschiedener Interessensgruppen unbeweglich geworden. 
          Die schweizweite Initiative «stark + vernetzt» war nicht länger bereit, diese Lähmung im Europapolitik-Dossier zu akzeptieren. Deswegen wurde eine 
          Event-Reihe ins Leben gerufen, die in vier Schweizer Städten Persönlichkeiten aus Politik, Wissenschaft, Wirtschaft und Zivilgesellschaft zum 
          Dialog einlud und eine mutige Europapolitik forderte. Und wir sind einmal mehr stolz, für die Umsetzung des dazugehörigen Kampagnensujets 
          beauftragt worden zu sein. <br /> <br />
          <strong>=</strong>Für die schweizweite Kommunikation der Initiative haben wir ein emotionales Kampagnensujet kreiert, das die beschriebene 
          Lage der Schweiz in einem metaphorischen festhält. Helvetia liegt am Boden und wird unbeweglich gemacht. Die Botschaft: Wir müssen ihr wieder auf 
          die Beine helfen. Den Akteuren haben wir dabei keine böse Absicht unterstellt, sondern lediglich in einer emotionalen Metapher verbildlicht, 
          wie die Schweiz von allen Seiten bewegungsunfähig gemacht wird. Schliesslich ging es nicht um Schuldzuweisungen, sondern darum, einen konstruktiven 
          Dialog zu finden. Schweizweit war unser Sujet auf Plakaten zu sehen sowie auch in digitaler Form als hochwertige Animation. Insbesondere die 
          animierte Version erzeugt dabei Emotionen, bringt zum Nachdenken und führt unweigerlich zum angestrebten Dialog. Unser Sujet wurde sehr gut 
          angenommen und sorgte für ordentlich Gesprächsstoff. Wir freuen uns, einen kreativen Beitrag zur Debatte geleistet und die Schweiz auf dem Weg 
          zu neuer Stärke unterstützt zu haben.
        </p>
      </grund-gedanke>

      <section class="slider-section slider-section-1">
        <carousel
          :paginationEnabled="false"
          navigationPrevLabel="<span class='arrow'><span>"
          navigationNextLabel="<span class='arrow'><span>"
          class="small-navigation"
          :perPage="1"
          :navigationEnabled="true"
          :loop="true"
        >
          <slide v-for="slideIndex in 3" :key="slideIndex">
            <img :src="require(`./../assets/case-studies/economiesuisse/plakate/Helvetia_Case_2500x1406_${slideIndex}.jpg`)" />
          </slide>
        </carousel>
      </section>

      <section class="slider-section slider-section-1">
        <carousel
          :paginationEnabled="false"
          navigationPrevLabel="<span class='arrow'><span>"
          navigationNextLabel="<span class='arrow'><span>"
          class="small-navigation"
          :perPage="1"
          :navigationEnabled="true"
          :loop="true"
        >
          <slide>
            <video-player
              :videourl="require('./../assets/case-studies/economiesuisse/videos/Helvetia_Case_2500x1406_Tablet.mp4')"
              :poster="require('./../assets/case-studies/economiesuisse/videos/Helvetia_Case_2500x1406_Tablet.jpg')"
            >
            </video-player>
          </slide>
          <slide>
            <video-player
              :videourl="require('./../assets/case-studies/economiesuisse/videos/Helvetia_Case_2500x1406_Website.mp4')"
              :poster="require('./../assets/case-studies/economiesuisse/videos/Helvetia_Case_2500x1406_Website.jpg')"
            >
            </video-player>
          </slide>
        </carousel>
      </section>

      <case-footer></case-footer>
    </div>
  </div>
</template>
  
<script>
import CaseHeader from '../components/Case-Header.vue'
import CaseFooter from '../components/Case-Footer.vue'
import Grundgedanke from '../components/Grundgedanke.vue'
import CounterFooter from '../components/Counter-Footer-One.vue'
import VideoPlayer from '../components/Video-Player.vue'
import CaseLoadStatusMixin from '../mixins/case-load-status'
import VideoOnScroll from '../components/VideoOnScroll.vue'
import ParallaxGlider from '../components/Parallax-Glider.vue'
import ParallaxGliderThree from '../components/Parallax-Glider-Three.vue'

export default {
  name: 'economiesuisse',
  components: {
    'case-header': CaseHeader,
    'case-footer': CaseFooter,
    'grund-gedanke': Grundgedanke,
    'video-player': VideoPlayer,
    'counter-footer': CounterFooter,
    'video-on-scroll': VideoOnScroll,
    'parallax-glider': ParallaxGlider,
    'parallax-glider-three': ParallaxGliderThree,
  },
  mixins: [CaseLoadStatusMixin],
  data() {
    return {
      headerBackgroundImgUrl: require('./../assets/case-studies/economiesuisse/header.jpg'),
      headerImgUrl: require('./../assets/case-studies/economiesuisse/main.jpg'),
      footerCounters1: [
        {
          score: 0,
          max: 48,
          title: "48 % Ja-Stimmen.\nSehr sehr knapp veloren.",
        },
      ],
      footerCounters2: [
        {
          score: 0,
          max: "64,9",
          title: '64.9 % Nein-Stimmen.\nGewonnen!',
        },
      ],
      footerCounters3: [
        {
          score: 0,
          max: "51,6",
          title: '51.6 % Ja-Stimmen.\nGewonnen!',
        },
      ],
      footerCounters4: [
        {
          score: 0,
          max: "66,4",
          title: '66.4 % Ja-Stimmen.\nGewonnen!',
        },
      ],
      windowWidth: window.innerWidth,
    }
  },
  computed: {
    imgRes() {
      return this.windowWidth <= 577 ? '-low' : ''
    },
    useMobileVideos() {
      return this.windowWidth <= 577
    },
    duration() {
    if (this.windowWidth < 1100) {
      return 850;
    } else {
      return 1500;
    }
    },
    triggerHook() {
    if (this.windowWidth < 1100) {
      return 0.8;
    } else {
      return 0.6;
    }  
    }
  },
  methods: {
    setWindowWidth() {
      this.windowWidth = window.innerWidth
    },
  },
  mounted() {
    window.addEventListener('resize', this.setWindowWidth.bind(this))

    this.$nextTick(() => {
      this.initilizeLoad(() => {
        this.$emit('initilizeLoaded')
      })
    })
  },
  beforeDestroy() {
    this.$store.commit('removeScrollMagicScenes', 'economiesuisse')
    window.removeEventListener('resize', this.setWindowWidth.bind(this))
  },
}
</script>

<style lang="scss">
@import '../assets/scss/vars';

@media (max-width: 577px) {
  .header-section--economiesuisse {
    #top-headline {
      font-size: 8vw;
    }
  }
}

.section--video-row {
  display: flex;

  > .video-section {
    flex: 1 1 0px;
  }

  &-smartphones-green {
    @media (min-width: 1025px) {
      background-color: #dcff55; // $green;
      padding: 0 15%;
    }
  }

  @media (max-width: 1024px) {
    flex-wrap: wrap;

    > .video-section {
      flex-basis: 50%;
      min-width: 50%;
    }
  }

  @media (max-width: 600px) {
    > .video-section {
      flex-basis: 100%;
      min-width: 100%;
    }
  }
}

.parallax-glider2 {
  height: 110vh !important;

  @media (max-aspect-ratio: 1 / 1) {
    height: 55vh !important;
  }
}

.avoid-thin-line {
  margin-top: -2px;
}
</style>