<template>
  <footer class="case-footer">
    <div class="navigation">
      <div class="content-container case-footer-nav-desktop">
        <div v-if="!prev.show" class="no-prev-link"></div>

        <router-link v-if="prev.show" class="footer-link link-prev" :to="prev.link" exact v-on:click.native="setPageTransitionToSlideFromBottom">{{
          prev.title
        }}</router-link>

        <p class="to-top-arrow" @click="scrollToTop">
          <img class="scroll-top" src="~assets/theme_icons/scroll-up-dark.svg" />
        </p>

        <div v-if="!next.show" class="no-next-link"></div>

        <router-link v-if="next.show" class="footer-link link-next" :to="next.link" exact v-on:click.native="setPageTransitionToSlideFromBottom">{{
          next.title
        }}</router-link>
      </div>

      <div class="content-container case-footer-nav-mobile">
        <div class="mobile-footer-nav-wrapper scrollTop-wrapper">
          <p class="to-top-arrow" @click="scrollToTop">
            <img class="scroll-top" src="~assets/theme_icons/scroll-up.svg" />
          </p>
        </div>

        <div v-if="prev.show" class="mobile-footer-nav-wrapper no-prev-wrapper">
          <router-link class="footer-link link-prev" :to="prev.link" exact v-on:click.native="setPageTransitionToSlideFromBottom">{{ prev.title }}</router-link>
        </div>

        <div v-if="next.show" class="mobile-footer-nav-wrapper no-next-wrapper">
          <router-link class="footer-link link-next" :to="next.link" exact v-on:click.native="setPageTransitionToSlideFromBottom">{{ next.title }}</router-link>
        </div>
      </div>
    </div>
    <appfooter></appfooter>
  </footer>
</template>

<script>
import Footer from './../components/Footer.vue'

export default {
  components: {
    appfooter: Footer,
  },
  data() {
    return {
      cases: this.$store.state.cases,
      prev: {
        show: false,
        title: '',
        link: '',
      },
      next: {
        show: false,
        title: '',
        link: '',
      },
    }
  },
  computed: {
    isU577() {
      return this.$store.getters.isU577
    },
    isU900() {
      return this.$store.getters.isU900
    },
  },
  methods: {
    scrollToTop() {
      TweenMax.to(window, 1, { scrollTo: { y: '#top', autoKill: false } })
    },
    createFooterLinks() {
      var path = this.$route.path
      var index = false
      this.cases.forEach((el, i) => {
        if (el.href == path || el.href == path.substring(0, path.length - 1)) {
          index = i
        }
      })

      if (index === false) {
        this.prev = { show: false, title: '', link: '' }
        this.next = { show: false, title: '', link: '' }
      } else if (index === 0) {
        this.next = {
          show: true,
          title: this.cases[index + 1].title,
          link: this.cases[index + 1].href,
        }
      } else if (index === this.cases.length - 1) {
        this.prev = {
          show: true,
          title: this.cases[index - 1].title,
          link: this.cases[index - 1].href,
        }
      } else {
        this.prev = {
          show: true,
          title: this.cases[index - 1].title,
          link: this.cases[index - 1].href,
        }
        this.next = {
          show: true,
          title: this.cases[index + 1].title,
          link: this.cases[index + 1].href,
        }
      }
    },
    setPageTransitionToSlideFromBottom() {
      this.$store.commit('setNextPageTransition', 'slideFromBottom')
    },
  },
  created() {
    this.createFooterLinks()
  },
}
</script>

<style lang="scss">
.case-footer {
  background: #fff;
  color: #000;

  .content-container {
    width: 900px;
    max-width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 13px;
    padding-bottom: 10px;

    a {
      color: #000;
    }

    @media (max-width: 900px) {
      padding: 0;
    }
  }

  .scroll-top {
    width: 20px;
    cursor: pointer;
  }

  .footer-link {
    position: relative;

    &::before {
      content: url('~assets/theme_icons/arrow-left.svg');
      position: absolute;
      height: 19px;
      width: 14px;
      left: -20px;
    }

    &.link-next {
      &::before {
        left: auto;
        right: -20px;
        transform: rotate(180deg);
      }
    }
  }

  .case-footer-nav-mobile {
    display: none;
  }

  @media (max-width: 900px) {
    .content-container {
      flex-direction: column;
      padding: 0;

      .mobile-footer-nav-wrapper {
        width: 100%;
        display: flex;
        border-bottom: 1px solid #18191a;
        min-height: 65px;
        justify-content: center;
        align-items: center;
      }
    }

    .case-footer-nav-desktop {
      display: none;
    }

    .case-footer-nav-mobile {
      display: flex;
    }
  }
}

.case-footer-nav-desktop {
  position: relative;
  min-height: 63px;

  .to-top-arrow {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    margin: 0;
  }
}
</style>
