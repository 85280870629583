<template>
  <div>
    <case-header class="header-section--stadtpolizei" :backgroundvid="headerBackgroundImgUrl" :headerImgUrl="headerImgUrl">
      <h1 slot="h1-title" id="top-headline">Stadtpolizei</h1>
      <h3 slot="h3-title">Stadtpolizei</h3>
      <div slot="details">
        <p>
          <strong>?</strong>Auch die Schweizer Polizeikorps leiden unter Fachkräftemangel. Der zweitgrösste der Schweiz – die Stadtpolizei Zürich – geht 
          aktiv dagegen vor und hat uns dazu auserwählt, eine Kampagne für die Gewinnung neuer Polizistinnen und Polizisten zu lancieren. Das Besondere 
          daran: Es sollten Menschen aus verschiedenen Berufszweigen angesprochen werden. Eine Kampagne für den Quereinstieg ins Polizeileben.
        </p>
      </div>
    </case-header>

    <div v-show="!caseIsLoading" id="case-content">
      <grund-gedanke>
        <h3 slot="title">Stadtpolizei</h3>
        <p slot="text">
          <strong>!</strong>Es entstand ein sehr eigenständiges Konzept mit überspitzten, aber echten Bildern. Die Drehtage und Shootings waren zwar sehr 
          aufwendig inszeniert, aber ohne dabei die Authentizität gänzlich zu verlieren. Denn ausser den Fotomodellen arbeiteten wir stets mit echten 
          Polizist:innen am Set und machten alle Aufnahmen in der Stadt Zürich Am Ende haben sich alle Mühen gelohnt. Die finalen Sujets sind eine 
          Gärtnerin, ein Buchhalter, ein Koch sowie eine Velokurierin. Wir zeigen sie alle im Einsatz bei der Polizei. Zwar noch in der alten Arbeitskleidung, 
          aber bereits mit ihren echten künftigen Kolleginnen und Kollegen. <br /><br />
          <strong>=</strong>Die grösste Leistung dieser crossmedialen Kampagne war es, dem Polizeiberuf eine neue Präsenz im Arbeitsmarkt verliehen zu haben. 
          Denn die meisten Menschen scheinen sich vor der Kampagne noch nie die Frage gestellt zu haben, ob eine Stelle bei der Polizei das Richtige für sie 
          wäre. Die zahlreichen Neubewerbungen bestätigen diese These und freuen uns über die positive Resonanz.
        </p>
      </grund-gedanke>

      <parallax-glider-four v-if="windowWidth < 1100"
        :img1="require('./../assets/case-studies/stadtpolizei/parallax-glider/HOCH_221018_Stapo_velokurier__0366_RGB.jpg')" 
        :img2="require('./../assets/case-studies/stadtpolizei/parallax-glider/02.svg')"
        :start_position="'50% 40%'"
        :final_position="'50% 80%'"
      ></parallax-glider-four>

      <parallax-glider-four v-else
        :img1="require('./../assets/case-studies/stadtpolizei/parallax-glider/HOCH_221018_Stapo_velokurier__0366_RGB.jpg')" 
        :img2="require('./../assets/case-studies/stadtpolizei/parallax-glider/02.svg')"
        :start_position="'50% 30%'"
        :final_position="'50% 90%'"
      ></parallax-glider-four>

      <section class="slider-section slider-section-1">
        <carousel
          :paginationEnabled="false"
          navigationPrevLabel="<span class='arrow'><span>"
          navigationNextLabel="<span class='arrow'><span>"
          class="small-navigation"
          :perPage="1"
          :navigationEnabled="true"
          :loop="true"
        >
          <slide v-for="slideIndex in 4" :key="slideIndex">
            <img :src="require(`./../assets/case-studies/stadtpolizei/plakate/Case_2023_STAPO_0${slideIndex}.jpg`)" />
          </slide>
        </carousel>
      </section>

      <section class="section---stapo-video avoid-thin-line">
        <video-player 
        :videourl="require('./../assets/case-studies/stadtpolizei/videos/_STAPO_CASE_ADVideos_1920x1080px_V01.mp4')" 
        :poster="require('./../assets/case-studies/stadtpolizei/videos/_STAPO_CASE_ADVideos_1920x1080px_V01.jpg')" />
      </section>

      <section class="img-section-a">
        <img src="./../assets/case-studies/stadtpolizei/Case_2023_STAPO_A3_01.jpg" />
        <img src="./../assets/case-studies/stadtpolizei/Case_2023_STAPO_Anzeige_A5_01.jpg" />
      </section>

      <grund-gedanke>
        <h3 slot="title">Member get<br />Member</h3>
        <p slot="text">
          Was man für den Job als Polizist:in alles mitbringen muss? Das wissen Polizist:innen selber am besten. Deshalb lancierten wir eine «Member 
          Get Member»-Kampagne und animierten bestehende Beamt:innen dazu, neue Mitarbeitende anzuwerben. Dabei adaptierten wir unsere Botschaft aus 
          der erfolgreichen Employer-Kampagne.
        </p>
      </grund-gedanke>

      <div class="image-row">
        <img v-for="image in images" :src="image.src" :key="image.id" class="image-item" />
      </div>

      <section class="img-section-b">
        <img src="./../assets/case-studies/stadtpolizei/Case_2023_STAPO_Heckscheibe_01.jpg" />
        <img src="./../assets/case-studies/stadtpolizei/01_STAPO_Toiletteruckwand_V1.jpg" />
      </section>

      <case-footer></case-footer>
    </div>
  </div>
</template>
  
<script>
import CaseHeader from '../components/Case-Header.vue'
import CaseFooter from '../components/Case-Footer.vue'
import Grundgedanke from '../components/Grundgedanke.vue'
import CounterFooter from '../components/Counter-Footer.vue'
import VideoPlayer from '../components/Video-Player.vue'
import CaseLoadStatusMixin from '../mixins/case-load-status'
import VideoOnScroll from '../components/VideoOnScroll.vue'
import ParallaxGlider from '../components/Parallax-Glider.vue'
import ParallaxGliderFour from '../components/Parallax-Glider-Four.vue'

export default {
  name: 'stadtpolizei',
  components: {
    'case-header': CaseHeader,
    'case-footer': CaseFooter,
    'grund-gedanke': Grundgedanke,
    'video-player': VideoPlayer,
    'counter-footer': CounterFooter,
    'video-on-scroll': VideoOnScroll,
    'parallax-glider': ParallaxGlider,
    'parallax-glider-four': ParallaxGliderFour,
  },
  mixins: [CaseLoadStatusMixin],
  data() {
    return {
      headerBackgroundImgUrl: require('./../assets/case-studies/stadtpolizei/header.jpg'),
      headerImgUrl: require('./../assets/case-studies/stadtpolizei/main.jpg'),
      images: [
        {id: 1, src: require('./../assets/case-studies/stadtpolizei/Stadtpolize_Plakat_MemberGetMember_RGB_A2_1.jpg')},
        {id: 2, src: require('./../assets/case-studies/stadtpolizei/Stadtpolize_Plakat_MemberGetMember_RGB_A2_2.jpg')},
        {id: 3, src: require('./../assets/case-studies/stadtpolizei/Stadtpolize_Plakat_MemberGetMember_RGB_A2_3.jpg')},
        {id: 4, src: require('./../assets/case-studies/stadtpolizei/Stadtpolize_Plakat_MemberGetMember_RGB_A2_4.jpg')},
        {id: 5, src: require('./../assets/case-studies/stadtpolizei/Stadtpolize_Plakat_MemberGetMember_RGB_A2_5.jpg')},
        {id: 6, src: require('./../assets/case-studies/stadtpolizei/Stadtpolize_Plakat_MemberGetMember_RGB_A2_6.jpg')},
      ],
      windowWidth: window.innerWidth,
    }
  },
  computed: {
    imgRes() {
      return this.windowWidth <= 577 ? '-low' : ''
    },
    useMobileVideos() {
      return this.windowWidth <= 577
    },
  },
  methods: {
    setWindowWidth() {
      this.windowWidth = window.innerWidth
    },
  },
  mounted() {
    window.addEventListener('resize', this.setWindowWidth.bind(this))

    this.$nextTick(() => {
      this.initilizeLoad(() => {
        this.$emit('initilizeLoaded')
      })
    })
  },
  beforeDestroy() {
    this.$store.commit('removeScrollMagicScenes', 'stadtpolizei')
    window.removeEventListener('resize', this.setWindowWidth.bind(this))
  },
}
</script>
  
<style lang="scss">
@import '../assets/scss/vars';

@media (max-width: 577px) {
  .header-section--stadtpolizei {
    #top-headline {
      font-size: 8vw;
    }
  }
}

.section--video-row {
  display: flex;

  > .video-section {
    flex: 1 1 0px;
  }

  &-smartphones-green {
    @media (min-width: 1025px) {
      background-color: #dcff55; // $green;
      padding: 0 15%;
    }
  }

  @media (max-width: 1024px) {
    flex-wrap: wrap;

    > .video-section {
      flex-basis: 50%;
      min-width: 50%;
    }
  }

  @media (max-width: 600px) {
    > .video-section {
      flex-basis: 100%;
      min-width: 100%;
    }
  }
}

.image-row {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
}

.image-item {
  width: 100%;
  height: auto;
}

.img-section-a,
.img-section-b {
  img {
    width: 100%;
  }
}

.avoid-thin-line {
  margin-top: -2px;
}
</style>
  