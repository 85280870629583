<template>
  <section class="grundgedanke-section">
    <div class="content-container">
      <div>
        <slot name="title"></slot>
      </div>
      <div>
        <slot name="text"></slot>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {}
  },
}
</script>

<style lang="scss" scoped>
@import '~assets/scss/vars';

.grundgedanke-section {
  background-color: #000;
  padding: 60px 0;

  .content-container {
    display: flex;

    > div {
      &:nth-child(1) {
        min-width: 300px;
      }
    }

    a {
      color: #fff;
      font-weight: bold;
      transition: all 250ms ease-in-out;

      &:hover {
        color: $green;
      }
    }

    @media (max-width: 900px) {
      padding: 0 30px;
      flex-direction: column;

      > div {
        &:nth-child(1) {
          min-width: 250px;
          padding-right: 15px;
          hyphens: auto;
        }
      }
    }

    h3 {
      color: #fff;
      font-size: 40px;
      margin: 10px 0 0;
      hyphens: none;
    }

    p {
      color: #fff;
      font-weight: 300;
      letter-spacing: 0.5px;
      line-height: 1.4;

      strong {
        position: relative;
        display: inline-block;
        color: $green;
        letter-spacing: 1px;
        margin-right: 10px;

        &.white {
          color: #fff;
        }
      }
    }

    // @media (max-width: 577px) {
    //   flex-direction: column;
    //   padding: 0 30px;
    // }
  }
}
</style>
