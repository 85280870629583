<template>
  <div id="about-page">
    <case-header :headerImgUrl="headerImgUrl">
      <img id="top-headline" slot="h1-title" src="~assets/logo/font_logo_white.svg" />

      <h3 slot="h3-title"></h3>

      <div slot="details" class="details-text">
        <div>
          <p class="display-text">
            Wir sind eine mehrfach ausgezeichnete Kreativagentur für Ideen, Relevanz, Content, maximalen Impact und Return on Investment. Mit Daten, Insights
            und Tools entwickeln wir – classic, real-life, digital und social – die richtigen Strategien und Massnahmen zum ganzheitlichen Markenerlebnis für
            alle Individuen, Zielgruppen und Segmente.
          </p>
        </div>
      </div>
    </case-header>

    <div id="case-content">
      <werte-carousel></werte-carousel>

      <accordion-services id="leistungen"></accordion-services>

      <section class="exp-section black-icons">
        <heading color="green">Erfahrungen</heading>

        <div class="exp-columns">
          <div class="exp-list" v-for="exp in erfahrungen" :key="exp.title">
            <h3>{{ exp.title }}</h3>

            <p class="exp-list__item" v-for="expItem in exp.childs" :key="expItem.title">
              {{ expItem.title }}
            </p>
          </div>
        </div>
      </section>

      <heading color="black">Team</heading>

      <team-carousel></team-carousel>
    </div>

    <case-footer></case-footer>
  </div>
</template>

<script>
import CaseHeader from '../components/Case-Header-About.vue'
import CaseLoadStatusMixin from '../mixins/case-load-status'
import CaseFooter from '../components/Case-Footer.vue'
import AccordionServices from '../components/Accordion-Services.vue'
import TeamCarousel from '../components/Carousel-Team.vue'
import WerteCarousel from '../components/Carousel-Werte.vue'
import { EventBus } from './../store/eventbus'
import Heading from '../components/Heading.vue'

export default {
  name: 'about',
  components: {
    'case-header': CaseHeader,
    'case-footer': CaseFooter,
    'accordion-services': AccordionServices,
    'team-carousel': TeamCarousel,
    'werte-carousel': WerteCarousel,
    heading: Heading,
  },
  mixins: [CaseLoadStatusMixin],
  computed: {
    headerImgUrl() {
      return window.innerWidth > 577 ? require('./../assets/about/Antwort_HG-2.jpg') : require('./../assets/about/Antwort_HG-2_low.jpg')
    },
  },
  data() {
    return {
      erfahrungen: [
        {
          title: 'Education',
          childs: [{ title: 'HSR' }, { title: 'ZHAW' }, { title: 'HIF Ftan' }, { title: 'BFH' }],
        },
        {
          title: 'Health',
          childs: [{ title: 'Helsana' }, { title: 'Lungenliga Schweiz' }, { title: 'Stadtspital Zürich' }, { title: 'See-Spital' }],
        },
        {
          title: 'Public Institution',
          childs: [{ title: 'Stapo Zürich' }, { title: 'Schutz & Rettung' }, { title: 'ERZ Zürich' }],
        },
        {
          title: 'NGO‘s',
          childs: [{ title: 'Jacobs Foundation' }, { title: 'Swiss Malaria Group' }, { title: 'Kibesuisse \nPro Juventute' }],
        },
        {
          title: 'Pharma',
          childs: [
            { title: 'Sandoz, Novartis' },
            { title: 'GSK, ViiV Healthcare' },
            { title: 'Ewopharma, Revalid' },
            { title: 'Pharmasuisse, Omida' },
            { title: 'Sidroga, Emser, \nWeleda' },
            { title: 'Roche, Abbvie' },
          ],
        },
        {
          title: 'Prevention',
          childs: [{ title: 'Kapo Zürich' }, { title: 'Lunge Zürich' }, { title: 'Stapo Winterthur' }],
        },
        {
          title: 'Finance',
          childs: [{ title: 'Neue Aargauer Bank' }, { title: 'Coutts Bank' }, { title: 'TWINT' }],
        },
        {
          title: 'Politics',
          childs: [{ title: 'economiesuisse' }, { title: 'sgv, FDP, \nCVP, KGV' }, { title: '' }],
        },
        {
          title: 'Food',
          childs: [
            { title: 'Bell' }, { title: 'Doppelleu' }, { title: 'Chopfab' }, { title: 'SteviaSweet' },
            { title: 'Nestlé Professional' }, { title: 'Maestrani' }, { title: 'Munz' }],
        },
      ],
    }
  },
  methods: {},
  mounted() {
    this.$nextTick(() => {
      EventBus.$emit('about-page-laoded')

      setTimeout(() => {
        // Wenn die Single Page Application hier das erste mal geladen wird.
        if (!this.$store.state.nextPageTransition) {
          this.$store.dispatch('allowScroll')
        }
      }, 500)
    })
  },
  beforeDestroy() {
    this.$store.commit('removeScrollMagicScenes', 'about')
  },
}
</script>

<style lang="scss">
@import '~assets/scss/vars';

#about-page {
  #top-headline {
    width: 90%;
    display: block;
    max-width: 450px;
    margin: 40px auto;
  }

  .details-text {
    max-height: calc(100vh - 220px);
    overflow-y: auto;
    overflow-x: hidden;

    @media (max-width: 900px) and (min-width: 578px) {
      padding-right: 80px;
      padding-left: 80px;
    }

    p {
      font-size: 18px;
      line-height: 1.3;
      font-weight: 500;

      @media (max-width: 900px) {
        font-size: 16px;
      }
    }

    .display-text {
      font-size: 36px;
      font-weight: 500;

      @media (max-width: 900px) {
        font-size: 24px;
      }
    }
  }

  .exp-section {
    background-color: $green;
    padding-bottom: 100px;
  }

  .exp-columns {
    margin: 0 auto;
    max-width: 1000px;
    padding: 0 30px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 30px;

    @media (max-width: 900px) {
      grid-template-columns: 1fr 1fr;
    }
  }

  .exp-list {
    margin-bottom: 30px;
    break-inside: avoid-column;
    display: inline-block;

    h3 {
      font-size: 35px;
      margin-top: 0;
      margin-bottom: 10px;

      @media (max-width: 560px) {
        font-size: 22px;
      }

      @media (max-width: 330px) {
        font-size: 20px;
      }
    }

    &__item {
      font-size: 20px;
      font-weight: 700;
      margin: 5px 0;

      @media (max-width: 560px) {
        font-size: 16px;
      }
    }
  }
}
</style>
