<template>
  <div id="page-header-wrapper">
    <div class="mobile-overlay-header" :class="{ active: showMobileHeaderBackground }"></div>
    <div id="page-header">
      <transition-layer></transition-layer>

      <footer-contact-overlay></footer-contact-overlay>

      <div id="overlay-menu" :class="{ visible: overlayIsVisible, isblack: overlayIsBlack }">
        <div class="content-container">
          <div class="about">
            <img class="logo-in-overlay" src="~assets/logo/font_logo_white.svg" />
            <p class="overlay-menu-text" v-html="overlayMenuText"></p>
            <div class="adress">
              <p>Bertastrasse 1 <span>|</span> 8003 Zürich</p>
              <p>
                <a target="_blank" href="mailto:frage@dieantwort.com">frage@dieantwort.com</a>
                <span>|</span> +41 43 305 00 99
              </p>
              <social-icons class="desktop-social-links" />
            </div>
            <div class="bottom-nav">
              <router-link to="/about" exact v-on:mouseenter.native="setRecentPath" v-on:click.native="handleMenuNavLeftClick">About</router-link>
              <a href="#" class="headline" @click="openContactOverlay">Contact</a>
              <a href="https://magazin.dieantwort.com/" target="_blank">Magazin</a>
            </div>
          </div>
          <nav>
            <ul>
              <li v-for="item in sortedCases" :key="item.id">
                <router-link :to="item.href" v-on:mouseenter.native="setRecentPath" exact v-on:click.native="handleMenuNavClick(item)">{{
                  item.title
                }}</router-link>
              </li>
            </ul>
          </nav>
          <div class="mobile-overlay-footer">
            <p v-html="overlayMenuText"></p>
            <div class="adress">
              <p>Bertastrasse 1 <span>|</span> 8003 Zürich</p>
              <p>
                <a href="mailto:frage@dieantwort.com">frage@dieantwort.com</a>
                <br />+41 43 305 00 99
              </p>

              <social-icons class="mobil-social-links" />
            </div>
          </div>
        </div>
      </div>
      <div
        id="home-logo"
        v-on:mousemove="handleLogoMouseMove"
        v-on:mouseenter="handleLogoMouseEnter"
        v-on:mouseleave="handleLogoMouseLeave"
        @click="handleLogoClick"
        :class="{ isWhiteImportant: showMobileHeaderBackground }"
      >
        <div v-if="!isU577" class="snap-circle" ref="logoSnapCircle"></div>
        <router-link to="/" exact v-on:click.native="setPageTransitionToHomeFromLogo">
          <div class="logo-img" :class="{ black: logoIsBlack, white: !logoIsBlack }"></div>
        </router-link>
      </div>
      <div
        id="overlay-menu-toggle"
        @click="toggleOverlay"
        v-on:mousemove="handleHamburgerMouseMove"
        v-on:mouseenter="handleHamburgerMouseEnter"
        v-on:mouseleave="handleHamburgerMouseLeave"
        :class="{ isWhiteImportant: showMobileHeaderBackground }"
      >
        <div v-if="!isU577" class="snap-circle toggleSnapCircle" ref="toggleSnapCircle"></div>
        <div
          class="toggle-img"
          :class="{
            black: toggleIsBlack,
            white: !toggleIsBlack,
            toggle: !toggleIsClose,
            close: toggleIsClose,
          }"
        >
          <span></span>
          <span></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from './../store/eventbus'
import TransitionsMixin from './../mixins/transitions'
import TransitionLayer from './../components/Transition-Layer.vue'
import FooterContactOverlay from './../components/Footer-Contact-Overlay.vue'
import SocialIcons from './SocialIcons'

export default {
  components: {
    'transition-layer': TransitionLayer,
    'footer-contact-overlay': FooterContactOverlay,
    'social-icons': SocialIcons,
  },
  data() {
    return {
      cases: this.$store.state.cases,
      circleAnimationBlocked: false,
      recentPath: this.$router.path,
      overlayMenuText: `
      	Hier erhalten Sie die Antwort von einer mehrfach ausgezeichneten Kreativagentur in Zürich. Wir vereinen die digitale, social, real-life und klassische Welt zum ganzheitlichen Markenerlebnis für den maximalen Return on Investment.`,
    }
  },
  mixins: [TransitionsMixin],
  computed: {
    isU577() {
      return this.$store.getters.isU577
    },
    isU900() {
      return this.$store.getters.isU900
    },
    isTouch() {
      return this.$store.getters.isTouch
    },
    showMobileHeaderBackground() {
      return this.$store.state.mobilNavHeaderIsVisible
    },
    toggleIsHovered() {
      return this.$store.state.toggleIsHovered
    },
    logoIsHovered() {
      return this.$store.state.logoIsHovered
    },
    homeLogoIsVisible() {
      if (this.$route.path == '/') {
        return !this.$store.state.homeVideoIsPlayed
      } else {
        return true
      }
    },
    overlayIsVisible() {
      return this.$store.state.overlayMenuvisible
    },
    overlayIsBlack() {
      return this.$store.state.overlayIsBlack
    },
    toggleIsClose() {
      return this.$store.state.toggleIsClose
    },
    toggleIsBlack() {
      return this.$store.getters.toggleIsBlack
    },
    logoIsBlack() {
      return this.$store.getters.logoIsBlack
    },
    sortedCases() {
      return this.cases.slice().sort((a, b) => {
        const titleA = a.title.toLowerCase();
        const titleB = b.title.toLowerCase();
        if (titleA < titleB) {
          return -1;
        }
        if (titleA > titleB) {
          return 1;
        }
        return 0;
      });
    },  
  },
  methods: {
    openContactOverlay(e) {
      e.preventDefault()
      this.$store.dispatch('showContactOverlay')
    },
    setRecentPath(e) {
      this.recentPath = this.$route.path
    },
    handleMenuNavClick(item) {
      if (item.href == this.recentPath) {
        this.$store.dispatch('hideOverlayMenuWithAnimation')
      } else {
        this.setPageTransitionToCaseFromMenu()
      }
    },
    handleMenuNavLeftClick(e) {
      var target = e.target.getAttribute('href')
      var recent = this.recentPath

      // Sonderfall: Produkte, Overlay auf der About Seite klicken
      if (e.target.getAttribute('href').substr(1) == '/about?scrollTo=produkte' && recent == '/about') {
        this.$store.dispatch('hideOverlayMenuWithAnimation')
      } else if (target == recent) {
        this.$store.dispatch('hideOverlayMenuWithAnimation')
      } else {
        this.setPageTransitionToSlideFromBottom()
      }
    },
    toggleOverlay() {
      if (this.overlayIsVisible) {
        this.$store.dispatch('hideOverlayMenuWithAnimation')
      } else {
        this.$store.dispatch('showOverlayMenu')
      }
    },
    setPageTransitionToHomeFromLogo() {
      this.$store.commit('setNextPageTransition', 'toHome--fromLogo')
    },
    setPageTransitionToCaseFromMenu() {
      this.$store.commit('setNextPageTransition', 'toCase--fromMenu')
    },
    setPageTransitionToSlideFromBottom() {
      this.$store.commit('setNextPageTransition', 'slideFromBottom')
    },

    handleLogoMouseMove(e) {
      if (!this.isTouch) {
        TweenMax.to(this.$refs.logoSnapCircle, 0.3, {
          x: e.clientX + 50,
          y: e.clientY + 50,
        })
        if (e.clientX > 135 || e.clientY > 142) {
          this.handleLogoMouseLeave(e)
        }
      }
    },
    handleLogoMouseEnter(e) {
      if (!this.isTouch) {
        TweenMax.to(this.$refs.logoSnapCircle, 0.3, {
          x: e.clientX + 50,
          y: e.clientY + 50,
          ease: Elastic.easeOut.config(1.75, 0.5),
        })
        this.$store.commit('setLogoIsHovered')
      }
    },
    handleLogoMouseLeave(e) {
      if (!this.isTouch) {
        TweenMax.to(this.$refs.logoSnapCircle, 0.3, { x: -100, y: -100 })
        this.$store.commit('setLogoIsNotHovered')
      }
    },

    handleHamburgerMouseMove(e) {
      if (!this.isTouch) {
        var x = (window.innerWidth - (e.clientX - 25)) * -1
        var y = e.clientY + 50
        TweenMax.to(this.$refs.toggleSnapCircle, 0.3, { x: x, y: y })
        if (x < -160 || y > 165) {
          this.handleHamburgerMouseLeave(e)
        }
        this.$store.commit('setToggleIsHovered')
      }
    },
    handleHamburgerMouseEnter(e) {
      if (!this.isTouch) {
        TweenMax.to(this.$refs.toggleSnapCircle, 0.3, {
          x: e.offsetX - 150,
          y: e.offsetY + 50,
        })
        this.$store.commit('setToggleIsHovered')
      }
    },
    handleHamburgerMouseLeave(e) {
      if (!this.isTouch) {
        TweenMax.to(this.$refs.toggleSnapCircle, 0.3, { x: 100, y: -100 })
        this.$store.commit('setToggleIsNotHovered')
      }
    },
    stopVideo() {
      if (!this.$store.state.homeVideoIsPlayed) {
        this.fullTransition__ToHome({}, () => {
          EventBus.$emit('stopHomeVideo')
          this.$store.commit('setHomeVideoPlayed')
          this.$store.commit('setHomeVideoToInvisible')
          this.$store.dispatch('animateWorkArrowIn')
          this.$store.commit('setNextPageTransition', false)
        })
      } else {
        this.$store.commit('setHomeVideoPlayed')
        this.$store.commit('setNextPageTransition', false)
      }
    },
    handleLogoClick() {
      if (this.$route.path == '/') {
        if (!this.$store.state.homeVideoIsPlayed) {
          this.stopVideo()
        } else {
          this.fullTransition__ToHome({}, () => {
            TweenMax.set('#start', { height: window.innerHeight })
            window.scrollTo(0, 0)
            this.$store.commit('setNextPageTransition', false)
          })
        }
      }
    },
    handleMobildHeaderBackgroundToggle() {
      if (window.pageYOffset !== 0 && window.innerWidth <= 577 && this.$route.path.indexOf('/news') == -1) {
        this.$store.commit('setMobilNavHeaderIsVisible')
      } else {
        this.$store.commit('setMobilNavHeaderIsNotVisible')
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('scroll', () => {
        this.$store.dispatch('handleScrollToDetectIconColor')
        this.handleMobildHeaderBackgroundToggle()
      })
      window.addEventListener('resize', () => {
        this.handleMobildHeaderBackgroundToggle()
      })
    })
  },
}
</script>

<style lang="scss">
@import '~assets/scss/vars';

#page-header-wrapper {
  position: absolute;
  z-index: 10;
}

#page-header {
  position: relative;
  z-index: 9;
}

#overlay-menu {
  position: fixed;
  width: calc(100vw + 30px);
  height: 100vh;
  background: transparent;
  color: #fff;
  display: flex;
  align-items: center;
  opacity: 0;
  pointer-events: none;
  z-index: 12;

  &.isblack {
    background-color: #000;

    nav {
      a {
        &:hover {
          color: $green;
          transform: translateX(-10px);
        }
      }
    }

    .about {
      a {
        &:hover {
          color: $green !important;
        }
      }
    }
  }

  .adress {
    p {
      line-height: 1.8 !important;
    }

    a,
    a:focus,
    a:visited,
    a:active {
      color: #fff;
      transition: all 250ms ease-in-out;
    }

    a:hover {
      color: $green;
    }

    @media (max-width: 900px) {
      display: none;
    }
  }

  .overlay-menu-text {
    @media (max-width: 900px) {
      display: none;
    }
  }

  > div {
    display: flex;
  }

  .about {
    width: 640px; // 395px;
    margin-right: 80px;

    // neu
    display: flex;
    flex-direction: column;
    justify-content: center;

    p {
      font-size: 16px; // 18px;
      line-height: 1.3;
      font-weight: 500;
    }

    .adress {
      p {
        margin: 0;

        span {
          margin-right: 5px;
          margin-left: 5px;
        }
      }
    }

    .bottom-nav {
      margin-top: 20px;
      padding-top: 10px;
      border-top: 1px solid rgba(255, 255, 255, 0.4);

      @media (min-width: 901px) {
        max-width: 300px;
      }

      a,
      a:hover,
      a:active,
      a:focus {
        font-size: 18px;
        font-weight: bold;
        color: #fff;
        display: inline-block;
        text-transform: uppercase;
        letter-spacing: 4px;
        line-height: 1.8;
        padding-right: 15px;
        width: 100%;

        &.router-link-active {
          opacity: 0.5;
        }
      }
    }
  }

  &.visible {
    opacity: 1;
    pointer-events: auto;
  }

  nav {
    width: calc(100% - 380px);

    @media (min-width: 578px) {
      padding-left: 15px;
    }

    @media (min-width: 901px) {
      overflow: visible;
      overflow-y: scroll;
      max-height: 100vh;
    }

    ul {
      list-style: none;
      padding: 0;

      li {
        a {
          color: #fff;
          display: inline-block;
          font-size: 28px;
          font-weight: 500;
          transition: transform 150ms ease-in-out, opacity 2000ms ease-in-out;

          &.router-link-active {
            opacity: 0.5;
          }
        }
      }
    }
  }

  @media (max-width: 577px) {
    width: 100%;
    display: block;
    overflow: hidden;

    .content-container {
      margin: 0;
      padding: 0 90px 0 80px;
      height: 100%;
      overflow: scroll;
      display: block;
      width: calc(100% - 150px);

      > div,
      > nav {
        width: 100%;
      }

      nav {
        ul {
          li {
            a {
              font-size: 7vw;
            }
          }
        }
      }

      .about {
        margin: 0;
      }

      .logo-in-overlay {
        margin-top: 30vh;
        margin-bottom: 7vh;
        width: 100%;
      }

      .bottom-nav {
        margin-top: 0;
        margin-bottom: 40px;
        border-top: none;
        border-bottom: 1px solid rgba(255, 255, 255, 0.4);
        width: 100%;
        padding-bottom: 20px;

        a {
          display: block;
        }
      }

      .mobile-overlay-footer {
        margin-right: -30px;
        margin-left: -30px;
        width: calc(100% + 60px);
        padding-top: 15px;
        border-top: 1px solid rgba(255, 255, 255, 0.4);
        margin-top: 30px;
      }

      a {
        &:hover {
          transform: none !important;
          color: #fff !important;
        }
      }
    }
  }

  .desktop-social-links {
    display: none !important;
    margin-top: 10px;

    @media (min-width: 900px) {
      display: flex !important;
    }
  }

  .mobile-overlay-footer {
    display: none;
  }

  @media (max-width: 900px) {
    .mobile-overlay-footer {
      display: block;
      padding-bottom: 150px;

      .adress {
        display: block;
      }
    }
  }

  @media (max-width: 900px) and (min-width: 578px) {
    width: 100%;
    display: block;
    overflow: hidden;

    .content-container {
      display: block;
      overflow: scroll;
      width: calc(100% - 300px);
      height: 100%;
      margin: 0;
      padding: 113px 90px 0 230px;

      .about {
        margin: 0;
        width: 180px;
        position: absolute;
        left: 15px;
        top: 120px;
        text-align: right;
        display: block;

        .logo-in-overlay {
          width: 150px;
        }

        .bottom-nav {
          margin-top: 0;
          border: none;
          padding: 0 15px 0 0;
          margin-bottom: 60px;
        }
      }

      nav {
        width: 100%;

        li {
          a {
            &:hover {
              transform: none;
              color: #fff;
            }
          }
        }
      }
    }
  }
}

#overlay-menu-toggle {
  width: 40px;
  position: fixed;
  top: 0;
  left: calc(100vw - 140px);
  z-index: 14;
  padding: 50px 70px 30px 30px;
  cursor: pointer;

  @media (max-width: 900px) {
    padding: 30px 25px 25px;
    height: 30px;
    left: auto;
    right: 0;
  }

  .toggle-img {
    width: 40px;
    height: 40px;
    position: relative;
    transform: rotate(0deg);
    transition: 0.5s ease-in-out;
    cursor: pointer;

    span {
      display: block;
      position: absolute;
      height: 5px;
      width: 100%;
      border-radius: 2px;
      opacity: 1;
      left: 0;
      transform: rotate(0deg);

      &:nth-child(1) {
        top: 6px;
      }

      &:nth-child(2) {
        top: 18px;
      }
    }

    &.black {
      span {
        background-color: #000;
      }
    }

    &.white {
      span {
        background-color: #fff;
      }
    }
  }
}

#home-logo {
  position: fixed;
  z-index: 14;
  top: 0;
  left: 0;
  padding: 45px 30px 30px 50px;

  @media (max-width: 900px) {
    padding: 25px;
  }

  .logo-img {
    background-position: center;
    background-repeat: no-repeat;
    width: 43px;
    height: 43px;
    position: relative;
    z-index: 14;

    &.black {
      background-image: url('~assets/logo/a_logo_black.svg');
    }

    &.white {
      background-image: url('~assets/logo/a_logo_white.svg');
    }
  }
}

.snap-circle {
  background-color: $green;
  height: 100px;
  width: 100px;
  border-radius: 50%;
  position: absolute;
  opacity: 0.9;
  top: -100px;
  left: -100px;
}

.toggleSnapCircle {
  left: 100px;
}

.logo-in-overlay {
  width: 200px;
  margin-bottom: 30px;
  margin-top: 20px;
}

.disabled {
  pointer-events: none;
}

.mobil-social-links {
  margin-top: 30px;
  display: flex;
  justify-content: space-around;

  img {
    width: 40px;
  }
}

.mobile-overlay-header {
  display: none;
  background: #000;
  position: fixed;
  top: 0;
  left: 0;
  height: 90px;
  width: 100%;
  opacity: 0;
  pointer-events: none;
  transition: all 250ms ease-in-out;

  @media (max-width: 577px) {
    display: block;
    z-index: 8;
  }

  &.active {
    opacity: 1;
    pointer-events: auto;
  }
}
</style>
