<template>
  <div class="info-page">
    <section class="header">
      <h1>Datenschutzer&shy;klärung</h1>
    </section>

    <section class="text">
      <div class="content-container">
        <h3>Datenschutz</h3>
        <p>
          Gestützt auf Artikel 13 der Schweizerischen Bundesverfassung und die datenschutzrechtlichen Bestimmungen des Bundes (Datenschutzgesetz, DSG) hat jede
          Person Anspruch auf Schutz ihrer Privatsphäre sowie auf Schutz vor Missbrauch ihrer persönlichen Daten. Wir halten diese Bestimmungen ein. Persönliche
          Daten werden streng vertraulich behandelt und weder an Dritte verkauft noch weitergegeben.
        </p>
        <p>
          In enger Zusammenarbeit mit unseren Hosting-Providern bemühen wir uns, die Datenbanken so gut wie möglich vor fremden Zugriffen, Verlusten, Missbrauch
          oder vor Fälschung zu schützen.
        </p>
        <p>
          Beim Zugriff auf unsere Webseiten werden folgende Daten in Logfiles automatisch gespeichert: IP-Adresse, Datum, Uhrzeit, Browser-Anfrage und allg.
          übertragene Informationen zum Betriebssystem resp. Browser. Diese Nutzungsdaten können die Basis für statistische, anonyme Auswertungen dienen, so
          dass Trends erkennbar wären, anhand derer wir unsere Angebote entsprechend verbessern könnten. 
        </p>

        <h3>Verwendung von Facebook, Twitter und Instagram Plugins</h3>
        <p>
          Auf unserer Website werden sogenannte Social Plugins („Plugins“) der sozialen Netzwerke Facebook, Twitter und Instagram verwendet. Diese Dienste
          werden von den Unternehmen Facebook Inc., Twitter Inc. und Instagram LLC. angeboten („Anbieter“). Facebook wird betrieben von der Facebook Inc., 1601
          S. California Ave, Palo Alto, CA 94304, USA (“Facebook”). Eine Übersicht über die Plugins von Facebook und deren Aussehen finden Sie hier: <a
            href="https://developers.facebook.com/docs/plugins"
            target="_blank"
            >https://developers.facebook.com/docs/plugins</a
          >
          Twitter wird betrieben von der Twitter Inc., 1355 Market St, Suite 900, San Francisco, CA 94103, USA („Twitter“). Eine Übersicht über die
          Twitter-Buttons und deren Aussehen finden Sie hier: <a href="https://twitter.com/about/resources/buttons" target="_blank"
            >https://twitter.com/about/resources/buttons</a
          >
          Instagram wird betrieben von der Instagram LLC., 1601 Willow Road, Menlo Park, CA 94025, USA („Instagram“). Eine Übersicht über die Instagram-Buttons
          und deren Aussehen finden Sie hier: <a href="http://blog.instagram.com/post/36222022872/introducing-instagram-badges" target="_blank"
            >http://blog.instagram.com/post/36222022872/introducing-instagram-badges</a
          >
          Wenn Sie eine Seite unseres Webauftritts aufrufen, die ein solches Plugin enthält, stellt Ihr Browser eine direkte Verbindung zu den Servern von
          Facebook, Twitter oder Instagram her. Der Inhalt des Plugins wird vom jeweiligen Anbieter direkt an Ihren Browser übermittelt und in die Seite
          eingebunden. Durch die Einbindung der Plugins erhalten die Anbieter die Information, dass Ihr Browser die entsprechende Seite unseres Webauftritts
          aufgerufen hat, auch wenn Sie kein Profil besitzen oder gerade nicht eingeloggt sind. Diese Information (einschließlich Ihrer IP-Adresse) wird von
          Ihrem Browser direkt an einen Server des jeweiligen Anbieters in die USA übermittelt und dort gespeichert. Sind Sie bei einem der Dienste eingeloggt,
          können die Anbieter den Besuch unserer Website Ihrem Profil auf Facebook, Twitter bzw. Instagram unmittelbar zuordnen. Wenn Sie mit den Plugins
          interagieren, zum Beispiel den „Gefällt mir“-, den „Twittern“- bzw. den „Instagram“-Button betätigen, wird die entsprechende Information ebenfalls
          direkt an einen Server der Anbieter übermittelt und dort gespeichert. Die Informationen werden außerdem in dem sozialen Netzwerk, auf Ihrem Twitter-
          bzw. Instagram-Account veröffentlicht und dort Ihren Kontakten angezeigt. Zweck und Umfang der Datenerhebung und die weitere Verarbeitung und Nutzung
          der Daten durch die Anbieter sowie Ihre diesbezüglichen Rechte und Einstellungsmöglichkeiten zum Schutz Ihrer Privatsphäre entnehmen Sie bitte den
          Datenschutzhinweisen der Anbieter. Datenschutzhinweise von Facebook: <a href="http://www.facebook.com/policy.php " target="_blank"
            >http://www.facebook.com/policy.php </a
          >
          Datenschutzhinweise von Twitter: <a href="https://twitter.com/privacy " target="_blank">https://twitter.com/privacy </a> Datenschutzhinweise von
          Instagram: <a href="https://help.instagram.com/155833707900388/" target="_blank">https://help.instagram.com/155833707900388/</a> Wenn Sie nicht
          möchten, dass Facebook, Twitter oder Instagram die über unseren Webauftritt gesammelten Daten unmittelbar Ihrem Profil in dem jeweiligen Dienst
          zuordnen, müssen Sie sich vor Ihrem Besuch unserer Website bei dem entsprechenden Dienst ausloggen. Sie können das Laden der Plugins auch mit Add-Ons
          für Ihren Browser komplett verhindern, z. B. mit dem Skript-Blocker „NoScript“ (<a href="http://noscript.net/" target="_blank">http://noscript.net/</a
          >).
        </p>

        <h3>Google Analytics</h3>
        <p>
          Diese Website benutzt Google Analytics, einen Webanalysedienst der Google Inc. ("Google"). Google Analytics verwendet sog. "Cookies", Textdateien, die
          auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung der Website durch Sie ermöglichen. Die durch den Cookie erzeugten
          Informationen über Ihre Benutzung dieser Website werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert. Im Falle
          der Aktivierung der IP-Anonymisierung auf dieser Webseite wird Ihre IP-Adresse von Google jedoch innerhalb von Mitgliedstaaten der Europäischen Union
          oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum zuvor gekürzt.  Nur in Ausnahmefällen wird die volle IP-Adresse an
          einen Server von Google in den USA übertragen und dort gekürzt. Google wird diese Informationen benutzen, um Ihre Nutzung der Website auszuwerten, um
          Reports über die Websiteaktivitäten für die Websitebetreiber zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung
          verbundene Dienstleistungen zu erbringen. Auch wird Google diese Informationen gegebenenfalls an Dritte übertragen, sofern dies gesetzlich
          vorgeschrieben oder soweit Dritte diese Daten im Auftrag von Google verarbeiten. Die im Rahmen von Google Analytics von Ihrem Browser übermittelte
          IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt.  Sie können die Installation der Cookies durch eine entsprechende Einstellung
          Ihrer Browser Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website
          voll umfänglich nutzen können. Durch die Nutzung dieser Website erklären Sie sich mit der Bearbeitung der über Sie erhobenen Daten durch Google in der
          zuvor beschriebenen Art und Weise und zu dem zuvor benannten Zweck einverstanden.
        </p>
        <br />
        <p>
          Diese Website speichert Cookies auf Ihrem Computer. Diese Cookies werden verwendet, um Informationen darüber zu sammeln, wie Sie mit unserer Website
          interagieren. Wir verwenden diese Informationen, um Ihre Browser-Erfahrung zu verbessern und anzupassen, sowie für Analysen und Messungen zu unseren
          Besuchern auf dieser Website und anderen Medien. Weitere Informationen zu den von uns verwendeten Cookies finden Sie in unseren
          Datenschutzbestimmungen.
        </p>
        <br />

        <br />
        <p>Zürich, im Mai 2018.</p>
      </div>
    </section>

    <section class="scroll-top-bar">
      <div @click="scrollToTop">
        <img src="~assets/theme_icons/scroll-up-dark.svg" />
      </div>
    </section>

    <appfooter></appfooter>
  </div>
</template>

<script>
import Footer from './../components/Footer.vue'

export default {
  name: 'imprint',
  components: {
    appfooter: Footer,
  },
  data() {
    return {}
  },
  methods: {
    scrollToTop() {
      TweenMax.to(window, 1, { scrollTo: { y: 0, autoKill: false } })
    },
  },
  mounted() {
    this.$store.dispatch('allowScroll')
  },
  beforeDestroy() {
    //this.$store.commit('removeScrollMagicScenes', 'imprint');
  },
}
</script>

<style lang="scss" scoped>
@import '~assets/scss/vars';

.info-page {
  .header {
    background: #000;
    padding: 180px 0 60px;

    h1 {
      color: #fff;
      font-size: 65px;
      letter-spacing: 6px;
      text-transform: uppercase;
      text-align: center;
      margin: 0;

      @media (max-width: 577px) {
        padding: 0 30px;
        hyphens: auto;
        font-size: 10vw;
        max-width: 100vw;
        letter-spacing: 2px;
        word-break: break-all;
      }
    }
  }

  a {
    color: $green;
    hyphens: auto;
  }

  .text {
    overflow: hidden;
    background-color: #000;
    padding-bottom: 120px;

    .content-container {
      p,
      h3 {
        color: #fff;
      }

      h3 {
        text-transform: uppercase;
        margin-top: 60px;
      }
    }

    @media (max-width: 900px) {
      width: calc(100% - 60px);
      margin: 0 30px;
    }
  }
}
</style>
