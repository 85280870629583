<template>
  <div>
    <case-header :backgroundvid="headerBackgroundVideoUrl" :headerImgUrl="headerImgUrl">
      <h1 slot="h1-title" id="top-headline">CookCouture</h1>
      <h3 slot="h3-title">CookCouture</h3>
      <div slot="details">
        <p>
          <strong>?</strong>Meta Hiltebrand hatte schon länger den Wunsch nach einer Lebensveränderung. Und mutig, wie sie ist, entschloss sie sich, trotz
          erfolgreichem Geschäftsgang neue Wege zu beschreiten und ein neues Abenteuer zu wagen. Und zwar mit einem neuen Kochstudio im Aussersihler Viadukt.
          Für ihr neues berufliches Zuhause galt es, einen modernen Gesamtauftritt zu kreieren mit allem, was die digitale Welt zu bieten hat. Frech, bunt,
          offen und ganz auf ihre Persönlichkeit abgestimmt.
        </p>
      </div>
    </case-header>

    <div v-show="!caseIsLoading" id="case-content">
      <video-player
        :videourl="require('./../assets/case-studies/cookcouture/videos/case.mp4')"
        :poster="require('./../assets/case-studies/cookcouture/videos/case-poster.jpg')"
      >
      </video-player>

      <grund-gedanke>
        <h3 slot="title">
          Neue<br />
          Horizonte für<br />
          Meta<br />
          Hiltebrand
        </h3>
        <p slot="text">
          <strong>!</strong>Meta Hiltebrand hat einiges zu bieten. Und das in höchster Qualität. So entwickelten wir eine neue Welt, in der Meta sich von allen
          Seiten zeigen kann und gaben dem Ganzen einen Namen. CookCouture war geboren. Der Name verbindet das Kochen mit dem tonangebenden Kunsthandwerk und
          ist angelehnt an die Schneiderkunst «Haute Couture». Das Logo haben wir passend für diese Welt kreiert und vermitteln einen verspielten und
          glanzvollen Auftritt. Auf der komplett neuen Webseite wird all ihr Können in Form von Dienstleistungen angeboten. Neben illustren Kulinarik-Abenden,
          Tavolatas, Kochkursen und Workshops kann man die Spitzenköchin für Date-Abende buchen und sogar eigene Ideen für eine individuelle Abendgestaltung mit
          Meta Hiltebrand anbringen. Die Seite sollte nicht nur informativ und intuitiv aufgebaut sein, sondern auch Metas bunten Look widerspiegeln. Bunt
          illustrierte Küchenutensilien und Zutaten bewegen sich über die Seite und erwecken sie zum Leben, während die übersichtliche Struktur für einfache
          Handhabe sorgt. Die Inhalte wirken gleichermassen einladend für Kochanfänger, Profis sowie auch Unternehmen, die ihren Anlässen die gewisse Würze
          verleihen möchten. <br /><br />
          <strong>=</strong>Die Eröffnung des CookCouture Kochstudios war ein voller Erfolg und die Webseite erfreut sich bereits nach kurzer Zeit erfreut an
          zahlreichern Besuchern. Meta Hiltebrand konnte sich einmal mehr neu erfinden und geht nun ihren neuen Weg. Wir freuen uns, ihren Wandel unterstützt zu
          haben und wünschen ihr alles Gute auf ihrem weiteren Weg.
        </p>
      </grund-gedanke>

      <section class="einzelbild">
        <picture>
          <source media="(min-width: 800px)" srcset="~assets/case-studies/cookcouture/Case_CookCouture_1920x1080_Logos.png" />
          <source srcset="~assets/case-studies/cookcouture/Case_CookCouture_1920x1080_Logos-low.png" />
          <img src="~assets/case-studies/cookcouture/Case_CookCouture_1920x1080_Logos.png" />
        </picture>
      </section>

      <section class="section--autoplay-video">
        <video-on-scroll :loop="true" :videoSrc="require('./../assets/case-studies/cookcouture/videos/Case_CookCouture_1920x1080_Website.mp4')" />
      </section>

      <section class="einzelbild">
        <picture>
          <source media="(min-width: 800px)" srcset="~assets/case-studies/cookcouture/Case_CookCouture_1920x1080_Schuerze.png" />
          <source srcset="~assets/case-studies/cookcouture/Case_CookCouture_1920x1080_Schuerze-low.jpg" />
          <img src="~assets/case-studies/cookcouture/Case_CookCouture_1920x1080_Schuerze.png" />
        </picture>
      </section>

      <section class="section--autoplay-video black-icons">
        <video-on-scroll :loop="true" :videoSrc="require('./../assets/case-studies/cookcouture/videos/Case_CookCouture_1920x1080_Verkaufskampagne.mp4')" />
      </section>

      <section class="einzelbild black-icons">
        <picture>
          <source media="(min-width: 800px)" srcset="~assets/case-studies/cookcouture/Case_CookCouture_1920x1080_Visitenkarten.png" />
          <source srcset="~assets/case-studies/cookcouture/Case_CookCouture_1920x1080_Visitenkarten-low.jpg" />
          <img src="~assets/case-studies/cookcouture/Case_CookCouture_1920x1080_Visitenkarten.png" />
        </picture>
      </section>

      <counter-footer :counters="footerCounters"></counter-footer>

      <case-footer></case-footer>
    </div>
  </div>
</template>

<script>
import CaseHeader from '../components/Case-Header.vue'
import CaseFooter from '../components/Case-Footer.vue'
import Grundgedanke from '../components/Grundgedanke.vue'
import CounterFooter from '../components/Counter-Footer.vue'
import VideoPlayer from '../components/Video-Player.vue'
import CaseLoadStatusMixin from '../mixins/case-load-status'
import VideoOnScroll from '../components/VideoOnScroll.vue'

export default {
  name: 'cookcouture',
  components: {
    'case-header': CaseHeader,
    'case-footer': CaseFooter,
    'grund-gedanke': Grundgedanke,
    'video-player': VideoPlayer,
    'counter-footer': CounterFooter,
    'video-on-scroll': VideoOnScroll,
  },
  mixins: [CaseLoadStatusMixin],
  data() {
    return {
      headerBackgroundVideoUrl: require('./../assets/case-studies/cookcouture/header.mp4'),
      headerImgUrl: require('./../assets/case-studies/cookcouture/main.jpg'),
      footerCounters: [
        {
          score: 0,
          max: 85,
          title: 'Shop-Bestellungen in den ersten 5 Tagen.',
        },
        {
          score: 0,
          max: 6,
          title: 'Innert 6 Wochen haben wir die Webseite konzipiert, realisiert und programmiert.',
        },
        {
          score: 0,
          max: 1,
          title: 'Meta ist und bleibt der Schweizer Koch-Exportschlager Nr. 1 nach Deutschland. Auch ohne uns.',
        },
      ],
    }
  },
  methods: {},
  mounted() {
    this.$nextTick(() => {
      this.initilizeLoad(() => {
        this.$emit('initilizeLoaded')
      })
    })
  },
  beforeDestroy() {
    this.$store.commit('removeScrollMagicScenes', 'cookcouture')
  },
}
</script>

<style lang="scss">
.einzelbild {
  * {
    width: 100%;
  }
}
</style>
