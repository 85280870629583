<template>
  <div class="case-stevia-sweet">
    <case-header :backgroundvid="headerBackgroundVideoUrl" :headerImgUrl="headerImgUrl">
      <h1 slot="h1-title" id="top-headline">Stevia Sweet</h1>
      <h3 slot="h3-title">Stevia Sweet</h3>
      <div slot="details">
        <p>
          <strong>?</strong>Die Ureinwohner Paraguays und Brasiliens wissen es schon lange: die Blätter der Stevia-Pflanze eignen sich ideal, um Getränke und
          Speisen zu süssen. Seit hunderten von Jahren benutzen sie dazu die ihnen als «Honigkraut» bekannte Pflanze. Wir in der Schweiz nutzen dieses Kraut
          erst seit wenigen Jahren. Entsprechend ist noch viel Aufklärungsarbeit notwendig. SteviaSweet ist das natürliche Zuckerersatz-Produkt von Hermes
          Süssstoff AG mit Sitz in Zürich.
        </p>
        <div class="tags">
          <p>B2C Campaign | Social Media | POS | Events</p>
        </div>
      </div>
    </case-header>

    <div v-show="!caseIsLoading" id="case-content">
      <section id="kuchen-section">
        <img class="donut1" src="~assets/case-studies/steviasweet/kuchen/donut_1.png" />
        <img class="donut2" src="~assets/case-studies/steviasweet/kuchen/donut_2.png" />
        <img class="donut3" src="~assets/case-studies/steviasweet/kuchen/donut_3.png" />
        <img class="donut4" src="~assets/case-studies/steviasweet/kuchen/donut_4.png" />
        <img class="toertchen" src="~assets/case-studies/steviasweet/kuchen/toertchen.png" />
        <img class="torte1" src="~assets/case-studies/steviasweet/kuchen/torte_1.png" />
        <img class="torte2" src="~assets/case-studies/steviasweet/kuchen/torte_2.png" />
        <img class="torte3" src="~assets/case-studies/steviasweet/kuchen/torte_3.png" />
      </section>

      <grund-gedanke>
        <h3 slot="title">Stevia Sweet</h3>
        <p slot="text">
          <strong>!</strong>Assugrin (aus dem selben Haus) hat in der Schweiz einen gefühlten Bekanntheitsgrad von 80 Prozent und wird heute schweizweit als
          Gattungsbezeichnung für Zuckersatz-Mittel im allgemeinen verwendet. Was soll da das natürliche SteviaSweet? <br /><br />Mit der Natürlichkeit des
          Produkts zu arbeiten, ist aus rechtlichen Gründen verboten. Auch aufgrund der starken Zucker-Lobby in der Schweiz. Was nun? Wir haben mit dem Starkoch
          Meta Hiltebrand als Markenbotschafterin gearbeitet, die in der gesamten DACH-Region für ihre ausgefallene Küche bekannt ist. Für die Lancierung des
          SteviaSweet Honigs haben wir einen Bären antanzen lassen und für eine internationale Social-Kampagne haben wir mit Tierchen und Babys gearbeitet, die
          zwar unglaublich süss aussahen, aber nicht so süss sind wie das SteviaSweet Sortiment. Süss nicht? <br /><br />
          <strong>=</strong>Der steigende Bekanntheitsgrad von SteviaSweet und die Absatzzahlen beweisen, dass wir auf dem richtigen Weg sind.
        </p>
      </grund-gedanke>

      <section class="animation-section-b black-icons">
        <img class="poster-1" src="~assets/case-studies/steviasweet/poster-1.jpg" />
        <img class="poster-2" src="~assets/case-studies/steviasweet/poster-2.jpg" />
      </section>

      <video-player
        :videourl="require('./../assets/case-studies/steviasweet/challenge.mp4')"
        :poster="require('./../assets/case-studies/steviasweet/challenge-poster.jpg')"
      >
      </video-player>

      <parallaxer class="black-icons">
        <div slot="layers">
          <div
            class="layer"
            :style="{ 'background-image': 'url(' + require('./../assets/case-studies/steviasweet/parallaxer/' + imgRes + '01.jpg') + ')' }"
          ></div>
          <div
            class="layer"
            :style="{ 'background-image': 'url(' + require('./../assets/case-studies/steviasweet/parallaxer/' + imgRes + '02.jpg') + ')' }"
          ></div>
          <div
            class="layer"
            :style="{ 'background-image': 'url(' + require('./../assets/case-studies/steviasweet/parallaxer/' + imgRes + '03.jpg') + ')' }"
          ></div>
          <div
            class="layer"
            :style="{ 'background-image': 'url(' + require('./../assets/case-studies/steviasweet/parallaxer/' + imgRes + '04.jpg') + ')' }"
          ></div>
        </div>
      </parallaxer>

      <section id="video-autoplay-section">
        <video v-if="!isU577" id="video1" class="video" ref="video1" muted playsinline>
          <source src="~assets/case-studies/steviasweet/handys.mp4" type="video/mp4" />
        </video>
        <img v-if="isU577" src="~assets/case-studies/steviasweet/handys.png" />
      </section>

      <counter-footer :counters="footerCounters"></counter-footer>

      <case-footer></case-footer>
    </div>
  </div>
</template>

<script>
import CaseHeader from '../components/Case-Header.vue'
import CaseFooter from '../components/Case-Footer.vue'
import Grundgedanke from '../components/Grundgedanke.vue'
import Parallaxer from '../components/Parallaxer.vue'
import VideoPlayer from '../components/Video-Player.vue'
import CounterFooter from '../components/Counter-Footer.vue'
import CaseLoadStatusMixin from '../mixins/case-load-status'

export default {
  name: 'steviasweet',
  components: {
    'case-header': CaseHeader,
    'case-footer': CaseFooter,
    'grund-gedanke': Grundgedanke,
    'counter-footer': CounterFooter,
    parallaxer: Parallaxer,
    'video-player': VideoPlayer,
  },
  mixins: [CaseLoadStatusMixin],
  data() {
    return {
      headerBackgroundVideoUrl: require('./../assets/case-studies/steviasweet/header.mp4'),
      headerImgUrl: require('./../assets/case-studies/steviasweet/main.jpg'),
      smartphoneAnimationIsPlayed: false,
      totalSteps: 12, //24,
      buehneAnim: false,
      buehneAnimisPlayed: false,
      footerCounters: [
        {
          score: 0,
          max: 39,
          title: 'Eingesetzte Influencer',
        },
        {
          score: 0,
          max: 145,
          title: 'Werbemittel/Zielgruppen-Kombinationen',
        },
        {
          score: 0,
          max: 2868297,
          title: 'Gezielt erreichte Personen in DACH in 5 Monaten',
        },
      ],
    }
  },
  computed: {
    isU577() {
      return this.$store.getters.isU577
    },
    isTouch() {
      return this.$store.getters.isTouch
    },
    imgRes() {
      return window.innerWidth <= 577 ? 'low-' : ''
    },
  },
  methods: {
    statSmartphoneVideo() {
      var vid = document.getElementById('video1')
      var scene = new this.ScrollMagic.Scene({
        triggerElement: '#video-autoplay-section',
        triggerHook: 1,
      }).on('enter', () => {
        if (!this.smartphoneAnimationIsPlayed) {
          this.smartphoneAnimationIsPlayed = true
          vid.play()
        }
      })
      this.$store.commit('addScrollMagicScene', scene)
    },
    initPosterAnimation() {
      let w20 = window.innerWidth * 0.2 * -1
      var tl = new this.TimelineLite().to('.animation-section-b>*', 1, { scaleX: 1.2, scaleY: 1.2 }, 0)
      var scene = new this.ScrollMagic.Scene({
        triggerElement: '.animation-section-b',
        triggerHook: 0.65,
        duration: window.innerHeight * 0.9,
      }).setTween(tl)
      this.$store.commit('addScrollMagicScene', scene)
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.initilizeLoad(() => {
        if (!this.isU577) {
          this.initPosterAnimation()
          this.statSmartphoneVideo()
        }
      })
    })
  },
  beforeDestroy() {
    this.$store.commit('removeScrollMagicScenes', 'steviasweet')
  },
}
</script>

<style lang="scss" scoped>
.animation-section-b {
  position: relative;
  height: 60vw;
  overflow: hidden;
  text-align: center;

  .poster-1 {
    position: absolute;
    height: 35vw;
    bottom: 14vw;
    left: 14vw;
  }

  .poster-2 {
    position: absolute;
    height: 35vw;
    bottom: 9vw;
    right: 20vw;
  }

  @media (max-width: 577px) {
    height: auto;

    .poster-1,
    .poster-2 {
      position: static;
      width: 100%;
      height: auto;
      transform: none !important;
    }
  }
}

.parallaxer-section .layer {
  background-size: 110vh;

  @media (max-aspect-ratio: 1 / 1) {
    background-size: 80vw 56vw;
  }
}

#kuchen-section {
  position: relative;
  overflow: hidden;
  height: 71.197916666666667vw;
  background-image: url('~assets/case-studies/steviasweet/kuchen/background.jpg');
  background-size: cover;

  > img {
    position: absolute;
    background-repeat: no-repeat;
    background-size: contain;
    animation: rotating 1s linear infinite;
    width: auto;
  }

  .donut1 {
    height: 4.3vw;
    left: 35.4vw;
    top: 8.3vw;
  }

  .donut2 {
    height: 4vw;
    left: 34vw;
    top: 5vw;
  }

  .donut3 {
    height: 4.3vw;
    left: 35.8vw;
    top: 1.5vw;
  }

  .donut4 {
    height: 4.3vw;
    left: 37.6vw;
    top: -2.2vw;
  }

  .torte1 {
    height: 10.3vw;
    left: 34.6vw;
    top: 11.6vw;
  }

  .torte2 {
    height: 13.3vw;
    left: 62.6vw;
    top: 17.6vw;
  }

  .torte3 {
    height: 12.2vw;
    left: 64vw;
    top: 3vw;
  }

  .toertchen {
    height: 4.2vw;
    left: 69vw;
    top: 14.4vw;
  }
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
</style>
