<template>
  <div class="socialicons">
    <a class="socialicons__icon" target="_blank" href="https://www.facebook.com/dieantwort.ag/">
      <IconFacebook />
    </a>
    <a class="socialicons__icon" target="_blank" href="https://www.instagram.com/dieantwort_ag/">
      <IconInstagram />
    </a>
    <a class="socialicons__icon" target="_blank" href="https://www.linkedin.com/company/die-antwort-ag/">
      <IconLinkedIn />
    </a>
    <a class="socialicons__icon" target="_blank" href="https://www.tiktok.com/@dieantwortag">
      <IconTiktok />
    </a>
  </div>
</template>

<script>
import IconLinkedIn from './../assets/theme_icons/logo-linkedin.svg?inline'
import IconInstagram from './../assets/theme_icons/logo-instagram.svg?inline'
import IconFacebook from './../assets/theme_icons/logo-facebook.svg?inline'
import IconTiktok from './../assets/theme_icons/logo-tiktok.svg?inline'

export default {
  name: 'SocialIcons',

  components: {
    IconFacebook,
    IconInstagram,
    IconLinkedIn,
    IconTiktok,
  },
}
</script>

<style lang="scss">
@import '~assets/scss/vars';

.socialicons {
  &__icon {
    svg {
      width: 30px;
      margin: 15px;
    }

    &:hover {
      svg {
        .lime-fill {
          fill: $green;
        }

        .lime-stroke {
          stroke: $green;
        }
      }
    }
  }
}
</style>
