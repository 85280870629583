<template>
  <div>
    <case-header class="header-section--havelaar" :backgroundvid="headerBackgroundImgUrl" :headerImgUrl="headerImgUrl">
      <h1 slot="h1-title" id="top-headline">Fairtrade Max Havelaar</h1>
      <h3 slot="h3-title">Fairtrade Max Havelaar</h3>
      <div slot="details">
        <p>
          <strong>?</strong>Bei all den Herausforderungen, die sich der Menschheit weltweit stellen, dürfen wir diejenigen nicht vergessen, die sich tagtäglich
          für eine bessere Welt einsetzen. Unsere Arbeit für Fairtrade Max Havelaar war deshalb nicht nur darauf ausgelegt, Aufmerksamkeit zu erzeugen. Sondern
          von Anfang an Taten sprechen zu lassen und zu weiteren Taten zu animieren. Nebenbei erkundet Fairtrade Max Havelaar digitales Neuland mit uns – und
          wir dürfen den Weg in ein neues Zeitalter des fairen Handels für sie ebnen.
        </p>
        <div class="tags">
          <p>
            Creative Campaigning | Plakate | Anzeigen | Influencer Campaigning | TikTok | Website | Landingpage | Plakate | Out of Home | Display-Ads |
            Social-Ads | Event-Marketing
          </p>
        </div>
      </div>
    </case-header>

    <div v-show="!caseIsLoading" id="case-content">
      <grund-gedanke>
        <h3 slot="title">Max Havelaar<br />FAIRbruary</h3>
        <p slot="text">
          <strong>!</strong>Heutzutage leben viele Menschen bewusster und setzen sich ein für eine bessere Welt. Schulstreiks, Protestmärsche und
          aufsehenerregende Aktionen sind wichtige Massnahmen, die Gehör verschaffen und Zeichen setzen. Jedoch kann diese Art der Weltverbesserung auf Dauer
          zum Kraftakt werden. Für den Alltag muss eine andere Lösung her. Doch wie verbessert man die Welt im Alltag? Für Fairtrade Max Havelaar haben wir uns
          genau dieser Frage angenommen und eine Kampagne lanciert, die zum Weltverbessern im Alltag aufruft. Und zwar ganz easy. <br /><br />
          Fair zu leben ist einfacher als man denkt. Allein in der Schweiz existieren über 3’000 Fairtrade-zertifizierte Produkte. Um unser Anliegen in die
          breite Öffentlichkeit zu bringen, machten wir den Monat Februar 2022 offiziell zum #FAIRbruary und riefen dazu auf, einen Monat lang fair zu leben und
          fair produzierte Produkte zu bevorzugen. Unter fairbruary.ch erhielt die Aktion einen eigenen Auftritt inklusive Guide mit Tipps und Inspirationen zum
          Mitmachen, Produktekatalog und Wettbewerb mit prominenter Unterstützung.
          <br /><br />
          Wir setzten vier prominente Botschafterinnen und Botschafter aus verschiedenen Bereichen ein, die mit gutem Vorbild voran gingen und im Februar so
          fair wie möglich lebten. Darunter Singer-Songwriter Anna Rossinelli, Fussballexperte Beni Huggel, Star- Köchin Meta Hiltebrand und Unternehmerin DJ
          Tanja La Croix. Sie riefen alle auf authentische Weise zum Mitmachen auf, berichteten unter dem Hashtag #fairbruary regelmässig von ihren persönlichen
          Erfahrungen und verlosten exklusive Preise. Den Startschuss der gesamtschweizerischen Digitalkampagne gaben wir mit Digital Ads und Paid Posts auf 20
          Minuten und markierten damit gleichzeitig den Beginn unserer umfangreichen Social-Media-Kampagne. Unsere Botschaft: Schon mit kleinen Taten, wie etwa
          dem bewussten Entscheid für ein faires Produkt, kann man die Welt ein Stück besser machen. Ganz simpel und einfach. Ohne laute Protestaktion, Frust
          oder Krawall. Die Digitalkampagne haben wir in drei Ebenen ausgespielt und verfolgten damit auf jeder Ebene unterschiedliche Ziele (Emotion, Need &
          Action). Das Targeting und Retargeting basierten auf hochkomplexen Algorithmen und gewährleisteten vom Digital-Banner bis zum Newsletter maximale
          Effizienz bei der Ausspielung aller Werbemittel. Parallellaufende Performance Reviews und Testings deckten laufend Optimierungspotenziale auf und
          erlaubten es uns, fortwährende Anpassungen zu machen. <br /><br />
          <strong>=</strong>Der #FAIRbruary war ein voller Erfolg. Mit über 6 Millionen erreichten Menschen, unzähligen likes, shares und Teilnahmen sind wir
          sehr glücklich, mitgeholfen zu haben, die Welt ein Stück verbessert und gleichzeitig Fairtrade Max Havelaar endgültig ins digitale Zeitalter geführt
          zu haben.
        </p>
      </grund-gedanke>

      <video-player
        :videourl="require('./../assets/case-studies/havelaar/videos/case.mp4')"
        :poster="require('./../assets/case-studies/havelaar/videos/case-poster.jpg')"
      />

      <section class="slider-section slider-section-1">
        <carousel
          :paginationEnabled="false"
          navigationPrevLabel="<span class='arrow'><span>"
          navigationNextLabel="<span class='arrow'><span>"
          class="small-navigation"
          :perPageCustom="[
            [0, 1],
            [800, 2],
            [1100, 3],
          ]"
          :navigationEnabled="true"
          :loop="true"
        >
          <slide v-for="slideIndex in 4" :key="slideIndex">
            <img :src="require(`./../assets/case-studies/havelaar/plakate/Case_Havelaar_1000x1430_${slideIndex}.png`)" />
          </slide>
        </carousel>
      </section>

      <parallax-glider :imgurl="require('./../assets/case-studies/havelaar/Case_Havelaar_Meta' + imgRes + '.jpg')" :triggerHook="0.6" />

      <section class="slider-section slider-section-2">
        <carousel :perPage="1" :navigationEnabled="true" :loop="true">
          <slide v-for="slideIndex in 4" :key="slideIndex">
            <picture>
              <source media="(min-width: 800px)" :srcset="require(`./../assets/case-studies/havelaar/plakate/Case_Havelaar_1920x1080_${slideIndex}.png`)" />
              <source :srcset="require(`./../assets/case-studies/havelaar/plakate/Case_Havelaar_1920x1080_${slideIndex}-low.jpg`)" />
              <img :src="require(`./../assets/case-studies/havelaar/plakate/Case_Havelaar_1920x1080_${slideIndex}.png`)" />
            </picture>
          </slide>
        </carousel>
      </section>

      <section class="section---shoppingcart-video">
        <video-player
          v-if="!useMobileVideos"
          :videourl="require('./../assets/case-studies/havelaar/videos/Case_Havelaar_1920x1080_Fairrace.mp4')"
          :poster="require('./../assets/case-studies/havelaar/videos/Case_Havelaar_1920x1080_Fairrace-poster.jpg')"
        />
        <video-player
          v-if="useMobileVideos"
          :videourl="require('./../assets/case-studies/havelaar/videos/Case_Havelaar_1080x1920_Mobile_Fairrace.mp4')"
          :poster="require('./../assets/case-studies/havelaar/videos/Case_Havelaar_1080x1920_Mobile_Fairrace-poster.jpg')"
        />
      </section>

      <section class="einzelbild black-icons">
        <picture>
          <source media="(min-width: 800px)" srcset="~assets/case-studies/havelaar/plakate/Case_Havelaar_1920x1080_8.png" />
          <source srcset="~assets/case-studies/havelaar/plakate/Case_Havelaar_1920x1080_8-low.jpg" />
          <img src="~assets/case-studies/havelaar/plakate/Case_Havelaar_1920x1080_8.png" />
        </picture>
      </section>

      <section class="section--video-row section--video-row-smartphones-green black-icons">
        <video-player
          v-if="!useMobileVideos"
          v-for="videoIndex in 2"
          :key="videoIndex"
          :videourl="require(`./../assets/case-studies/havelaar/videos/Case_Havelaar_960x1080_Blumenmann_0${videoIndex}.mp4`)"
          :poster="require(`./../assets/case-studies/havelaar/videos/Case_Havelaar_960x1080_Blumenmann_0${videoIndex}-poster.jpg`)"
        />
        <video-player
          v-if="useMobileVideos"
          v-for="videoIndex in 2"
          :key="videoIndex"
          :videourl="require(`./../assets/case-studies/havelaar/videos/Case_Havelaar_1080x1920_Mobile_Blumenmann_0${videoIndex}.mp4`)"
          :poster="require(`./../assets/case-studies/havelaar/videos/Case_Havelaar_1080x1920_Mobile_Blumenmann_0${videoIndex}-poster.jpg`)"
        />
      </section>

      <section class="slider-section slider-section-3">
        <carousel :perPage="1" :navigationEnabled="true" :loop="true">
          <slide v-for="slideIndex in 3" :key="slideIndex">
            <picture>
              <source media="(min-width: 800px)" :srcset="require(`./../assets/case-studies/havelaar/plakate/Case_Havelaar_1920x1080_${slideIndex + 4}.png`)" />
              <source :srcset="require(`./../assets/case-studies/havelaar/plakate/Case_Havelaar_1920x1080_${slideIndex + 4}-low.jpg`)" />
              <img :src="require(`./../assets/case-studies/havelaar/plakate/Case_Havelaar_1920x1080_${slideIndex + 4}.png`)" />
            </picture>
          </slide>
        </carousel>
      </section>

      <counter-footer :counters="footerCounters"></counter-footer>

      <grund-gedanke>
        <h3 slot="title">Max Havelaar<br />Banana Day</h3>
        <p slot="text">
          Zum 25. Jubiläum des «World Banana Day» am 20. April 2022 durften wir uns etwas Besonderes ausdenken. Und da dieser Tag wird mit viel Humor und Witz
          gefeiert wird, haben wir uns von der berühmten «Annoying Orange» inspirieren lassen und die Banane zum Leben erweckt. Und das auf Mundart. In kurzen,
          witzigen Dialogen feierten wir mit der Online-Community den Welt-Bananen-Tag und machten gleichzeitig aufmerksam auf die Wichtigkeit von Fairtrade.
          Mit Erfolg. Denn die Videos wurden total über 600’000 mal angeschaut mit unzähligen Likes und Shares.
        </p>
      </grund-gedanke>

      <section class="section--video-row">
        <video-player
          v-if="!useMobileVideos"
          v-for="videoIndex in 4"
          :key="videoIndex"
          :videourl="require(`./../assets/case-studies/havelaar/videos/Case_Havelaar_480x1080_BananaDay_0${videoIndex}.mp4`)"
          :poster="require(`./../assets/case-studies/havelaar/videos/Case_Havelaar_480x1080_BananaDay_0${videoIndex}-poster.jpg`)"
        />
        <video-player
          v-if="useMobileVideos"
          v-for="videoIndex in 4"
          :key="videoIndex"
          :videourl="require(`./../assets/case-studies/havelaar/videos/Case_Havelaar_1080x1920_Mobile_BananaDay_0${videoIndex}.mp4`)"
          :poster="require(`./../assets/case-studies/havelaar/videos/Case_Havelaar_1080x1920_Mobile_BananaDay_0${videoIndex}-poster.jpg`)"
        />
      </section>

      <counter-footer :counters="footerCounters2"></counter-footer>

      <case-footer></case-footer>
    </div>
  </div>
</template>

<script>
import CaseHeader from '../components/Case-Header.vue'
import CaseFooter from '../components/Case-Footer.vue'
import Grundgedanke from '../components/Grundgedanke.vue'
import CounterFooter from '../components/Counter-Footer.vue'
import VideoPlayer from '../components/Video-Player.vue'
import CaseLoadStatusMixin from '../mixins/case-load-status'
import VideoOnScroll from '../components/VideoOnScroll.vue'
import ParallaxGlider from '../components/Parallax-Glider.vue'

export default {
  name: 'havelaar',
  components: {
    'case-header': CaseHeader,
    'case-footer': CaseFooter,
    'grund-gedanke': Grundgedanke,
    'video-player': VideoPlayer,
    'counter-footer': CounterFooter,
    'video-on-scroll': VideoOnScroll,
    'parallax-glider': ParallaxGlider,
  },
  mixins: [CaseLoadStatusMixin],
  data() {
    return {
      headerBackgroundImgUrl: require('./../assets/case-studies/havelaar/header.jpg'),
      headerImgUrl: require('./../assets/case-studies/havelaar/main.jpg'),
      footerCounters: [
        {
          score: 0,
          max: 6000000,
          title: 'Reach',
        },
        {
          score: 0,
          max: 10000,
          title: 'Likes & Shares',
        },
        {
          score: 0,
          max: 2816,
          title: 'Wettbewerbsteilnahmen',
        },
      ],
      footerCounters2: [
        {
          score: 0,
          max: 600000,
          title: 'Views',
        },
        {
          score: 0,
          max: 8532,
          title: 'Likes',
        },
        {
          score: 0,
          max: 147,
          title: 'Comments & Shares',
        },
      ],
      windowWidth: window.innerWidth,
    }
  },
  computed: {
    imgRes() {
      return this.windowWidth <= 577 ? '-low' : ''
    },
    useMobileVideos() {
      return this.windowWidth <= 577
    },
  },
  methods: {
    setWindowWidth() {
      this.windowWidth = window.innerWidth
    },
  },
  mounted() {
    window.addEventListener('resize', this.setWindowWidth.bind(this))

    this.$nextTick(() => {
      this.initilizeLoad(() => {
        this.$emit('initilizeLoaded')
      })
    })
  },
  beforeDestroy() {
    this.$store.commit('removeScrollMagicScenes', 'havelaar')
    window.removeEventListener('resize', this.setWindowWidth.bind(this))
  },
}
</script>

<style lang="scss">
@import '../assets/scss/vars';

@media (max-width: 577px) {
  .header-section--havelaar {
    #top-headline {
      font-size: 8vw;
    }
  }
}

.section--video-row {
  display: flex;

  > .video-section {
    flex: 1 1 0px;
  }

  &-smartphones-green {
    @media (min-width: 1025px) {
      background-color: #dcff55; // $green;
      padding: 0 15%;
    }
  }

  @media (max-width: 1024px) {
    flex-wrap: wrap;

    > .video-section {
      flex-basis: 50%;
      min-width: 50%;
    }
  }

  @media (max-width: 600px) {
    > .video-section {
      flex-basis: 100%;
      min-width: 100%;
    }
  }
}
</style>
