<template>
  <section id="top" class="header-section header-section-teeexpress">
    <div class="background-180" :class="{ isTouch: isTouch }"></div>
    <div class="overlay"></div>
    <div class="content">
      <div class="first-container header-container">
        <slot name="h1-title"></slot>
        <div class="details-button-container">
          <p class="details-button" :class="{ active: !isLoading }" @click="showDetails">
            {{ detailsButtonText }}
          </p>
          <div class="details-button-after"></div>
        </div>
      </div>
      <div class="second-container header-container">
        <div class="content-container">
          <div>
            <slot name="h3-title"></slot>
          </div>
          <slot name="details"></slot>
        </div>
      </div>
    </div>
    <div class="scroll-down-on-case-arrow" @click="scrollToCaseContent" @mouseenter="animateWorksArrowOut">
      <div class="arrow-extention"></div>
      <img class="details-arrow-down" src="~assets/theme_icons/arrow-down-white.svg" />
    </div>
  </section>
</template>

<script>
import CaseHeaderMixin from '../mixins/case-header'

export default {
  mixins: [CaseHeaderMixin],
  data() {
    return {
      headerEventListener: false,
    }
  },
  computed: {
    isU577() {
      return this.$store.getters.isU577
    },
    isU900() {
      return this.$store.getters.isU900
    },
    isTouch() {
      return this.$store.getters.isTouch
    },
  },
  methods: {
    init180Animation() {
      document.getElementById('app').addEventListener('mousemove', this.backgroundFollowsMouse, false)
    },
    backgroundFollowsMouse(e) {
      if (e.pageY <= window.innerHeight) {
        var mPosPerc = (100 / window.innerWidth) * e.pageX
        TweenMax.to('.background-180', 0.2, {
          backgroundPosition: mPosPerc + '% 0',
        })
      }
    },
  },
  mounted() {
    !this.isTouch && this.init180Animation()
  },
  beforeDestroy() {
    !this.isTouch && document.getElementById('app').removeEventListener('mousemove', this.backgroundFollowsMouse, false)
  },
}
</script>

<style lang="scss">
@import '~assets/scss/vars';
@import '~assets/scss/case-header';

.background-180 {
  width: 100vw;
  height: 100vh;
  background-image: url('~assets/case-studies/teexpress/header.jpg');
  background-size: cover;
  background-position: 50% 0;

  @media (max-width: 577px) {
    background-image: url('~assets/case-studies/teexpress/main.jpg');
    height: 100%;
    width: 100%;
  }

  &.isTouch {
    background-image: url('~assets/case-studies/teexpress/main.jpg');
    height: 100%;
    width: 100%;
  }
}
</style>
