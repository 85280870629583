<template>
  <div class="video-on-scroll" ref="videoWrapper">
    <video v-if="showVideo" class="video" ref="video" muted :loop="loop" playsinline>
      <source :src="videoSrc" type="video/mp4" />
    </video>
    <img v-if="showImg" :src="imgSrc" />
  </div>
</template>

<script>
export default {
  name: 'VideoOnScroll',
  props: {
    videoSrc: {
      type: String,
      default: '',
    },
    imgSrc: {
      type: String,
      default: '',
    },
    loop: {
      type: Boolean,
      default: false,
    },
    /**
     * 1 = oberkante des Videos kommt ins Bild
     * 0 = oberkante des Videos verlässt das Bild
     */
    triggerOffset: {
      type: Number,
      default: 0.8,
    },
  },
  data() {
    return {
      videoIsStarted: false,
    }
  },
  computed: {
    showVideo() {
      // 1. es gibt ein Video aber kein Mobil Bild ==> Immer Video anzeigen
      if (this.videoSrc !== '' && this.imgSrc == '') {
        return true
      }

      let isDesktopWindowSize = window.innerWidth > 577
      // 2. es gibt ein Video und ein Mobilbild und es es ist Desktop Size ==> Video für Desktop anzeigen
      if (this.videoSrc !== '' && isDesktopWindowSize) {
        return true
      }
      return false
    },
    showImg() {
      let isMobileWindowSize = window.innerWidth < 578
      if (this.imgSrc !== '' && isMobileWindowSize) {
        return true
      }
      return false
    },
  },
  methods: {
    startVideoScene() {
      var vid = this.$refs.video
      var scene = new this.ScrollMagic.Scene({
        triggerElement: this.$refs.videoWrapper,
        triggerHook: this.triggerOffset,
      }).on('enter', () => {
        if (!this.videoIsStarted && this.showVideo) {
          this.videoIsStarted = true
          vid.play()
        }
      })
      this.$store.commit('addScrollMagicScene', {
        scene,
        namespace: 'videoonscroll',
      })
    },
  },
  mounted() {
    this.$parent.$on('caseIsLoaded', () => {
      this.startVideoScene()
    })
  },
  beforeDestroy() {
    this.$store.commit('removeScrollMagicScenes', 'videoonscroll')
  },
}
</script>
