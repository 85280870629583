<template>
  <section :id="sectionID" class="parallax-glider" :style="{ backgroundImage: 'url(' + imgurl + ')' }"></section>
</template>

<script>
export default {
  props: {
    imgurl: {
      type: String,
      required: true,
    },
    triggerHook: {
      type: Number,
      default: 0.9,
    },
    duration: {
      type: Number,
      default: window.innerHeight * 2,
    },
  },
  data() {
    return {
      sectionID: 'parallax-glider-' + Math.round(Math.random() * 100000),
    }
  },
  methods: {
    changeDurationOnRezise() {
      var section = document.getElementById(this.sectionID)
      var newHeight = section.offsetHeight
      section.style.height = newHeight * 0.6
    },
    initParallaxAnimation() {
      var tl = new this.TimelineLite().fromTo('#' + this.sectionID, 1, { backgroundPosition: '50% 0' }, { backgroundPosition: '50% 100%' }, 0)
      var scene = new this.ScrollMagic.Scene({
        triggerElement: '#' + this.sectionID,
        triggerHook: this.triggerHook,
        duration: this.duration,
      }).setTween(tl)
      this.$store.commit('addScrollMagicScene', scene)
      window.addEventListener('resize', () => {
        scene.duration(this.duration)
      })
    },
  },
  mounted() {
    this.$parent.$on('caseIsLoaded', () => {
      this.initParallaxAnimation()
    })
  },
}
</script>

<style lang="scss" scoped>
.parallax-glider {
  overflow: hidden;
  height: 100vh;
  background-size: 100% auto;

  @media (max-aspect-ratio: 1 / 1) {
    height: 70vw;
  }
}
</style>
