<template>
  <section id="top" class="header-section">
    <video
      v-if="!showDesktopImage && backgroundvid !== ''"
      id="top-video"
      class="video"
      preload
      width="1280"
      height="720"
      autoplay
      muted
      plays-inline
      loop
      ref="headerVideo"
    >
      <source :src="backgroundvid" type="video/mp4" />
    </video>
    <img class="desktop-only-img" v-if="showDesktopImage" :src="backgroundvid" />
    <img v-if="isU577" class="header-img-mobile" :src="headerImgUrl" />
    <div class="overlay"></div>
    <div class="content">
      <div class="first-container header-container">
        <slot name="h1-title"></slot>
        <div class="details-button-container">
          <p class="details-button" :class="{ active: !isLoading }" @click="showDetails">
            {{ detailsButtonText }}
          </p>
          <div class="details-button-after"></div>
        </div>
      </div>
      <div class="second-container header-container">
        <div class="content-container">
          <div>
            <slot name="h3-title"></slot>
          </div>
          <slot name="details"></slot>
        </div>
      </div>
    </div>
    <div class="scroll-down-on-case-arrow" @click="scrollToCaseContent" @mouseenter="animateWorksArrowOut">
      <div class="arrow-extention"></div>
      <img class="details-arrow-down" src="~assets/theme_icons/arrow-down-white.svg" />
    </div>
  </section>
</template>

<script>
import CaseHeaderMixin from '../mixins/case-header'

export default {
  mixins: [CaseHeaderMixin],
  computed: {
    isU577() {
      return this.$store.getters.isU577
    },
    showDesktopImage() {
      var fileExt = this.backgroundvid.split(/[#?]/)[0].split('.').pop().trim()
      var isNoVideoFile = !['mp4'].includes(fileExt)
      return this.showVideo && isNoVideoFile
    },
  },
  props: {
    backgroundvid: {
      type: String,
      default: '',
    },
    backgroundimg: {
      type: String,
      default: '',
    },
    // mobile
    headerImgUrl: {
      type: String,
      default: '',
    },
    autoplay: {
      default: true,
      type: Boolean,
    },
  },
}
</script>

<style lang="scss">
@import '~assets/scss/vars';
@import '~assets/scss/case-header';

.desktop-only-img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
</style>
