<template>
  <div class="info-page">
    <section class="header">
      <h1>Imprint</h1>
    </section>

    <section class="text">
      <div class="content-container">
        <div class="first-info">
          <div class="row">
            <div class="col">Firma:</div>
            <div class="col">DIE ANTWORT AG</div>
          </div>
          <div class="row">
            <div class="col">Gesellschaftsform:</div>
            <div class="col">Aktiengesellschaft</div>
          </div>
          <div class="row">
            <div class="col">Gründungsjahr:</div>
            <div class="col">2005</div>
          </div>
          <div class="row">
            <div class="col">Umbenennung:</div>
            <div class="col">2018 (v. Werbeanstalt Schweiz AG in DIE ANTWORT AG)</div>
          </div>
          <div class="row">
            <div class="col">Domizil:</div>
            <div class="col">Bertastrasse 1, 8003</div>
          </div>
          <div class="row">
            <div class="col">Inhaber:</div>
            <div class="col">Sarah Hiltebrand, Reto Dürrenberger</div>
          </div>
          <div class="row">
            <div class="col">Führung:</div>
            <div class="col">Sarah Hiltebrand, Reto Dürrenberger</div>
          </div>
          <div class="row">
            <div class="col">Mitgliedschaften:</div>
            <div class="col">GFM, Schweizer Werbung, iab Switzerland, Swissmarketing</div>
          </div>
        </div>

        <p>DIE ANTWORT AG</p>
        <p>Bertastrasse 1</p>
        <p>8003 Zürich</p>
        <br />
        <p>Telefon: +41 43 305 00 99</p>
        <p>Email: <a href="mailto:frage@dieantwort.com">frage@dieantwort.com</a></p>
        <br />
        <p>Geschäftsführer: Sarah Hiltebrand, Reto Dürrenberger</p>
        <p>UID-Nummer: CHE-110.181.139</p>
        <p>Mehrwertsteuernummer: CHE-110.181.139 MwSt.</p>
        <br />
        <p>Verantwortlich für den Inhalt dieser Webseite: Sarah Hiltebrand, Reto Dürrenberger, DIE ANTWORT AG, Bertastrasse 1, 8003 Zürich.</p>
        <h3>Haftungsausschluss</h3>
        <p>
          Die Agentur übernimmt keinerlei Gewähr hinsichtlich der inhaltlichen Richtigkeit, Genauigkeit, Aktualität, Zuverlässigkeit und Vollständigkeit der
          Informationen.
        </p>
        <p>
          Haftungsansprüche gegen die Agentur wegen Schäden materieller oder immaterieller Art, welche aus dem Zugriff oder der Nutzung bzw. Nichtnutzung der
          veröffentlichten Informationen, durch Missbrauch der Verbindung oder durch technische Störungen entstanden sind, werden ausgeschlossen.
        </p>
        <p>
          Alle Angebote sind unverbindlich. Die Agentur behält es sich ausdrücklich vor, Teile der Seiten oder das gesamte Angebot ohne gesonderte Ankündigung
          zu verändern, zu ergänzen, zu löschen oder die Veröffentlichung zeitweise oder endgültig einzustellen.
        </p>
        <h3>Haftung für Links</h3>
        <p>
          Verweise und Links auf Webseiten Dritter liegen ausserhalb unseres Verantwortungsbereichs Es wird jegliche Verantwortung für solche Webseiten
          abgelehnt. Der Zugriff und die Nutzung solcher Webseiten erfolgen auf eigene Gefahr des Nutzers oder der Nutzerin. 
        </p>
        <h3>Urheber- und Nutzungsrechte</h3>
        <p>
          Die Urheber-/Nutzungs- und alle anderen Rechte an Inhalten, Bildern, Texte, Fotos oder anderen Dateien auf der Website gehören ausschliesslich der
          Firma DIE ANTWORT AG. Für die Reproduktion jeglicher Elemente ist die schriftliche Zustimmung der Urheberrechtsträger im Voraus einzuholen.
        </p>
      </div>
    </section>

    <section class="scroll-top-bar">
      <div @click="scrollToTop">
        <img src="~assets/theme_icons/scroll-up-dark.svg" />
      </div>
    </section>

    <appfooter></appfooter>
  </div>
</template>

<script>
import Footer from './../components/Footer.vue'

export default {
  name: 'imprint',
  components: {
    appfooter: Footer,
  },
  data() {
    return {}
  },
  methods: {
    scrollToTop() {
      TweenMax.to(window, 1, { scrollTo: { y: 0, autoKill: false } })
    },
  },
  mounted() {
    this.$store.dispatch('allowScroll')
  },
}
</script>

<style lang="scss" scoped>
@import '~assets/scss/vars';

.info-page {
  .header {
    background: #000;
    padding: 180px 0 60px;

    h1 {
      color: #fff;
      font-size: 65px;
      letter-spacing: 6px;
      text-transform: uppercase;
      text-align: center;
      margin: 0;

      @media (max-width: 577px) {
        padding: 0 30px;
        hyphens: auto;
        font-size: 10vw;
      }
    }
  }

  .text {
    background-color: #000;
    padding-bottom: 120px;

    .content-container {
      p,
      h3 {
        color: #fff;
      }

      h3 {
        text-transform: uppercase;
        margin-top: 60px;
      }

      a,
      a:focus,
      a:visited,
      a:active {
        color: #fff;
        transition: all 250ms ease-in-out;
      }

      a:hover {
        color: $green;
      }
    }

    @media (max-width: 900px) {
      width: calc(100% - 60px);
      margin: 0 30px;
    }
  }

  .first-info {
    line-height: 1.3;
    margin-bottom: 160px;
    color: #fff;
    font-size: 18px;

    .row {
      display: flex;

      @media (max-width: 577px) {
        display: flex;
        flex-direction: column;
        margin-bottom: 15px;

        .col {
          &:nth-child(1) {
            min-width: 0;
          }
        }
      }

      .col {
        &:nth-child(1) {
          min-width: 250px;
        }
      }
    }
  }
}
</style>
