<template>
  <div id="page-footer">
    <div class="content-container">
      <div class="headline-container">
        <router-link to="/" exact v-on:click.native="setPageTransitionToSlideFromBottom">
          <img src="~assets/logo/font_logo_white.svg" />
        </router-link>
      </div>
      <div class="row">
        <!-- Menü -->
        <div class="col" @mousemove="setRecentPath">
          <router-link class="headline" to="/" exact v-on:click.native="handleHomeLink">Home</router-link>
          <router-link class="headline" :to="{ path: '/', query: { scrollTo: 'works' } }" exact v-on:click.native="handleWorksLink">Work</router-link>
          <!--<router-link class="headline" :to="{ path: '/about', query: { scrollTo: 'produkte' }}" exact v-on:mouseenter.native="setRecentPath" v-on:click.native="handleProductsLink">Products</router-link>-->
          <router-link class="headline" to="/about" exact v-on:click.native="setPageTransitionToSlideFromBottom">About</router-link>
          <a class="headline" href="https://magazin.dieantwort.com/" target="_blank">Magazin</a>
          <a href="#" class="headline" @click="openContactOverlay">Contact</a>

          <SocialIcons class="desktop-social-links" />
        </div>
        <!-- Zürich -->
        <div class="col">
          <a class="adresse-link" href="https://goo.gl/maps/DPZM8dCDsyp5YdEK8" target="_blank">
            <h4>Zürich</h4>
            <p>Bertastrasse 1</p>
            <p>8003 Zürich</p>
          </a>
          <div class="spacer"></div>

          <a class="" target="_blank" href="mailto:frage@dieantwort.com">frage@dieantwort.com</a>
          <a class="" target="_blank" href="tel:+41433050099">+41 43 305 00 99</a>
        </div>
        <!-- Südafrika -->
        <div class="col">
          <a class="adresse-link" href="https://temp.villaseaview.co.za/" target="_blank">
            <h4>Südafrika</h4>
            <p>40 Coney Glen Road –</p>
            <p>The Heads, Knysna 6570 –</p>
            <p>South Africa</p>
          </a>
        </div>
      </div>
      <SocialIcons class="mobil-social-links" />
      <div class="bottom-container">
        <p>
          <span class="copyright">&copy;</span> Die Antwort
          {{ new Date().getFullYear() }}
        </p>
        <router-link to="/imprint" exact v-on:click.native="setPageTransitionToSlideFromBottom">Imprint</router-link>
        <router-link to="/datenschutz" exact v-on:click.native="setPageTransitionToSlideFromBottom">Datenschutz</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import SocialIcons from './SocialIcons'

export default {
  components: {
    SocialIcons,
  },
  computed: {
    isU577() {
      return this.$store.getters.isU577
    },
    isU900() {
      return this.$store.getters.isU900
    },
    isTouch() {
      return this.$store.getters.isTouch
    },
  },
  methods: {
    setRecentPath(e) {
      this.recentPath = this.$route.path
    },
    handleHomeLink(item) {
      if (this.recentPath == '/') {
        TweenMax.to(window, 1, { scrollTo: { y: 0, autoKill: false } })
      } else {
        this.setPageTransitionToSlideFromBottom()
      }
    },
    handleWorksLink() {
      if (this.recentPath == '/') {
        TweenMax.to(window, 1, {
          scrollTo: { y: '#caseStudies', autoKill: false },
        })
      } else {
        this.setPageTransitionToSlideFromBottom()
      }
    },
    // handleProductsLink() {
    // 	if(this.recentPath == '/about') {
    //          	TweenMax.to(window, 1, {scrollTo: {y:'#products', autoKill: false}});
    // 	} else {
    // 		this.setPageTransitionToSlideFromBottom();
    // 	}
    // },
    setPageTransitionToSlideFromBottom() {
      this.$store.commit('setNextPageTransition', 'slideFromBottom')
    },
    openContactOverlay(e) {
      e.preventDefault()
      this.$store.dispatch('showContactOverlay')
    },
  },
}
</script>

<style lang="scss">
@import '~assets/scss/vars';

#page-footer {
  color: #fff;
  text-align: center;
  background-color: $grey;
  padding: 30px 0;
  font-size: 18px;

  > .content-container {
    flex-direction: column;
    display: flex;
  }

  a {
    color: #fff;

    &:active,
    &:focus,
    &:visited {
      color: #fff;
    }

    &:hover {
      color: $green;
    }

    img {
      width: 300px;
      margin: 45px 0 60px;
    }
  }

  .row {
    width: 100%;
    display: flex;
    justify-content: start;
    text-align: left;

    .col {
      width: 33.33%;

      a,
      p {
        margin: 0;
        display: block;
        font-size: 18px;
        line-height: 1.6;
        font-weight: 900;
      }

      a {
        transition: all 250ms ease-in-out;

        &:hover {
          color: $green;
        }
      }
    }

    // desktop only
    @media (min-width: 901px) {
      .col {
        &:nth-child(3) {
          order: 2;
        }

        &:nth-child(4) {
          order: 1;
        }
      }
    }

    @media (max-width: 577px) {
      flex-direction: column;
      justify-content: center;
      text-align: center;

      .col {
        width: 100%;

        &:nth-child(1) {
          a {
            font-size: 6vw !important;
          }
        }

        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4) {
          margin-top: 60px;

          p,
          a {
            font-size: 6vw;
            padding: 0 30px;
          }
        }
      }
    }

    // tablet only
    @media (max-width: 900px) and (min-width: 578px) {
      flex-direction: column;
      max-width: calc(100% - 120px);
      margin: 0 auto;
      padding: 0 60px;
      display: grid;
      grid-template-columns: 1fr 1fr;

      .col {
        width: 100%;

        &:nth-child(1) {
          grid-column: 1 / 3;
          width: 100%;
          display: flex;
          justify-content: space-around;
          margin-bottom: 60px;

          a {
            font-size: 2.5vw !important;
          }
        }

        &:nth-child(2) {
          p,
          a {
            font-size: 2.5vw;
            text-align: left;
          }
        }
      }
    }
  }

  a.disabled {
    pointer-events: none;
  }

  .spacer {
    display: block;
    height: 30px;
  }

  .headline {
    text-transform: uppercase;
    letter-spacing: 3px;
    display: block;
    margin-bottom: 8px;
    margin-top: 0;
    font-size: 18px !important;
    line-height: 1.8 !important;
  }

  h4 {
    color: $green !important;
    text-transform: uppercase;
    letter-spacing: 3px;
    display: block;
    margin-bottom: 0;
    margin-top: 0;
    font-size: 18px !important;
  }

  .bottom-container {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 90px;

    p {
      margin-top: 0;
    }

    > * {
      margin: 0 20px;
    }

    @media (max-width: 900px) {
      flex-direction: column;
    }
  }

  .mobil-social-links {
    display: flex;
    justify-content: center;
    margin-top: 40px;

    a {
      svg {
        width: 40px;
        margin: 20px;
      }
    }

    @media (min-width: 900px) {
      display: none;
    }
  }

  .desktop-social-links {
    display: none !important;
    margin-top: 40px;

    a {
      &:nth-child(1) {
        svg {
          margin-left: 0;
        }
      }
    }

    @media (min-width: 900px) {
      display: flex !important;
    }
  }
}
</style>
