<template>
  <section :class="getClasses()">
    <div
      ref="video-columns__full-layer"
      :class="{
        'video-columns__full-layer': true,
        spaltenView: !isTouch && !isU900,
      }"
      @click="handleFullVideoClick"
    >
      <video
        playsinline
        v-for="(v, i) in videos"
        preload="metadata"
        class="video"
        :ref="'videofull' + i"
        :id="`${uid}-video-full-${i}`"
        :controls="isTouch"
        :poster="v.fullPoster"
      >
        <source :src="v.full" type="video/mp4" />
      </video>
    </div>
    <div
      v-if="!isTouch"
      ref="video-columns__prev-layer"
      :class="[`video-columns__prev-layer`, `videocarousel`, `videocarousel--${uid}`, { 'owl-carousel': useOwlCarousel, 'no-carousel': !useOwlCarousel }]"
    >
      <video
        playsinline
        v-for="(v, i) in videos"
        class="video"
        plays-inline
        :data-videoId="i"
        loop
        muted
        :poster="v.prevPoster"
        @mouseenter="playPrevVideo"
        @mouseleave="pausePrevVideo"
        @click="handlePrevVideoClick"
      >
        <source :src="v.prev" type="video/mp4" />
      </video>
    </div>
  </section>
</template>

<script>
import classnames from 'classnames'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel'

export default {
  name: 'VideoColumns',

  props: {
    videos: {
      type: Array,
      default: function () {
        return []
      },
    },
    columns: {
      type: Number,
      default: 5,
    },
    uid: {
      type: String,
      required: true,
    },
  },

  computed: {
    isU577() {
      return this.$store.getters.isU577
    },
    isU900() {
      return this.$store.getters.isU900
    },
    isTouch() {
      return this.$store.getters.isTouch
    },
    useOwlCarousel() {
      return this.videos.length > this.columns
    },
  },

  data() {
    return {
      carouselIsInit: false,
    }
  },

  methods: {
    getClasses() {
      let columns = `has-${this.columns}-columns`
      return classnames(`video-columns--${this.uid}`, 'video-columns', columns)
    },

    playPrevVideo(e) {
      var video = e.target
      video.play()
    },

    pausePrevVideo(e) {
      var video = e.target
      if (!!(video.currentTime > 0 && !video.paused && !video.ended && video.readyState > 2)) {
        video.pause()
      }
    },

    handlePrevVideoClick(e) {
      var video = e.target
      video.pause()
      this.videoIsPlaying = video.dataset.videoid
      var fullVideo = document.getElementById(`${this.uid}-video-full-${video.dataset.videoid}`)
      TweenMax.to(this.$refs['video-columns__prev-layer'], 0.5, {
        pointerEvents: 'none',
        alpha: 0,
      })
      TweenMax.to(fullVideo, 0.5, { pointerEvents: 'auto', alpha: 1 })
      fullVideo.play()
    },

    handleFullVideoClick(e) {
      var fullVideo = document.getElementById(`${this.uid}-video-full-${this.videoIsPlaying}`)
      this.videoIsPlaying = false
      TweenMax.to(fullVideo, 0.5, { pointerEvents: 'none', alpha: 0 })
      TweenMax.to(this.$refs['video-columns__prev-layer'], 0.5, {
        pointerEvents: 'auto',
        alpha: 1,
      })
      fullVideo.pause()
      fullVideo.currentTime = 0
    },

    initCarousel() {
      $(`.videocarousel--${this.uid}`).owlCarousel({
        items: this.columns,
        loop: true,
        mouseDrag: false,
        touchDrag: false,
        nav: true,
        navText: ['<span class="arrow"></span>', '<span class="arrow"></span>'],
        dots: false,
        // onChange: (e) => {
        //   this.setEventListeners();
        // },
      })
    },

    // setEventListeners() {
    //   $(`.videocarousel--${this.uid} .video`).each((i,el) => {
    //     el.removeEventListener('mouseenter', this.playPrevVideo.bind(this));
    //     el.removeEventListener('mouseleave', this.pausePrevVideo.bind(this));
    //     el.removeEventListener('click', this.handlePrevVideoClick.bind(this));

    //     el.addEventListener('mouseenter', this.playPrevVideo.bind(this));
    //     el.addEventListener('mouseleave', this.pausePrevVideo.bind(this));
    //     el.addEventListener('click', this.handlePrevVideoClick.bind(this));
    //   });
    // },
  },

  mounted() {
    this.$nextTick(() => {
      // nur wenn es mehr Spalten gibt als nebeneinander passen, soll das Karousel genutzt werden.
      if (this.useOwlCarousel) {
        if (!(this.isTouch && this.isU577 && !this.carouselIsInit)) {
          this.carouselIsInit = true
          this.initCarousel()
        }
      }

      this.$parent.$on('initilizeLoaded', () => {
        // this.setEventListeners();
      })
    })
  },
}
</script>

<style lang="scss">
@import '~assets/scss/vars';

.slider-section {
  video {
    height: 100%;
    object-fit: cover;
  }
}

.video-columns {
  background: #000;
  position: relative;

  &__prev-layer {
    height: 56.25vw;

    video {
      height: 56.25vw;
      cursor: url('~assets/theme_icons/play.png') 20 20, auto;
    }

    .owl-prev,
    .owl-next {
      position: absolute;
      top: 0;
      left: 0;
      width: 50px;
      height: 100%;
      background: $green !important;
      opacity: 0.3;
      transition: all 250ms ease-in-out;
      outline: none !important;

      &:hover {
        opacity: 1;
      }

      > .arrow {
        &::before {
          content: url('~assets/theme_icons/arrow-left.svg');
          width: 27px;
          display: inline-block;
        }
      }
    }

    .owl-next {
      left: auto;
      right: 0;
      width: 62px;

      > .arrow {
        &::before {
          transform: rotate(180deg);
          margin-right: 12px;
        }
      }
    }
  }

  &.has-4-columns {
    .video-columns__prev-layer {
      video {
        width: 25vw !important;
      }
    }
  }

  &.has-5-columns {
    .video-columns__prev-layer {
      video {
        width: 20vw !important;
      }
    }
  }

  .video-columns__full-layer {
    &.spaltenView {
      position: absolute;
      overflow: hidden;
      height: 56.25vw;
      width: 100%;
      pointer-events: none;

      video {
        cursor: url('~assets/theme_icons/close.png') 20 20, auto;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
      }
    }
  }
}
</style>
